import React from 'react'
import noimage from '../../assets/no-image.png'
// import chaticon from '../../assets/chatIcon.png'
// import reviewicon from '../../assets/reviewIcon.png'
import degreeIcon from '../../assets/degreeIcon.png'
import StarRatings from 'react-star-ratings'
import { useEffect } from 'react'
import apiservice from '../../apiservice/ApiService'
import { useState } from 'react'
import { HiArrowLongRight } from 'react-icons/hi2'
import Loader from '../../components/common-components/Loader'

function LearnersDatailsView(props) {
    const [learnerData, setLearnerData] = useState([])
    const [learnerEduData, setLearnerEduData] = useState([])
    const [learnerReviewData, setLearnerReviewData] = useState([])
    const [viewShowCount, setViewShowCount] = useState(3)
    const [isLoading, setIsLoading] = useState(false)
    const onClickBack = () => {
        props.history.push('/home/totallearners')
    }
    useEffect(() => {
        getLeanerData()
    }, [])
    const getLeanerData = async () => {
        setIsLoading(true)
        let id = props.match.params.id
        let udata = await apiservice.getLocalStorage('userdata')
        let dataObejct = {
            learner_id: id
        }
        console.log(dataObejct);
        await apiservice.apiCall(`/view_learner`, udata.Token, dataObejct, 'POST').then(res => {
            console.log(res);
            if (res.unAuthenticated) {
                props.history.push('/')
                localStorage.removeItem('userdata')
            } else if (res.data) {
                setIsLoading(false)
                let data = res.data
                setLearnerData(data)
                let eduData = data.educations
                setLearnerEduData(eduData)
                let reviewData = data.learnerReviews
                setLearnerReviewData(reviewData)
            } else {
                setIsLoading(false)
                console.log(res);
            }
        }).catch(err => {
            setIsLoading(false)
            console.log(err);
        })
    }

    const listOfReviews = () => {
        let list = []
        let dataArr = learnerReviewData
        if (dataArr.length > 0) {
            for (let index = 0; index < dataArr.length; index++) {
                const data = dataArr[index];
                let name = data.content_expert.name
                let profileImage = data.content_expert.ProfileImage === null ? data.content_expert.SocialImage === null ? data.content_expert.profile_photo_url : data.content_expert.SocialImage : `${apiservice.apiImageShow()}/${data.content_expert.ProfileImage}`
                if (viewShowCount >= index + 1) {
                    list.push(
                        <div className='mt-3 p-3 border rounded-3'>
                            <div className='d-flex'>
                                <div className=' '>
                                    <div className='rounded-circle overflow-hidden' style={{ width: '70px', height: '70px' }}>
                                        <img src={profileImage} width={'100%'} height={'100%'} alt='' />
                                    </div>
                                </div>
                                <div className='mt-3 ms-3'>
                                    <div className='fw-bold'>{name}</div>
                                    <div>
                                        <StarRatings
                                            rating={+data.Ratting}
                                            starRatedColor="#FDB022"
                                            numberOfStars={5}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='mt-3'>
                                {data.comment}
                            </div>
                        </div>
                    )
                }
            }

        } else {
            list.push(
                <div className='fw-bold mt-3'>no review found yet!</div>
            )
        }

        return list
    }

    const onClickShowMore = () => {
        let count = viewShowCount
        count = count + 3
        // alert(count)
        setViewShowCount(count)

    }
    return (
        <>
            {isLoading ? <Loader /> :
                <div className='p-3'>
                    <div className='d-flex justify-content-between'>
                        <div className='fs-3 fw-bold'>Learner Profile</div>
                        <div>
                            <button className='btn btn-orange px-5' onClick={onClickBack}>Back</button>
                        </div>
                    </div>
                    <div className='mt-3'>
                        <div className='p-3 border rounded-2'>
                            <div className='row m-0'>
                                <div className='col-lg-3 col-12'>
                                    <div className='p-3'>
                                        <div className='rounded-circle overflow-hidden' style={{ width: '100px', height: '100px' }}>
                                            <img src={learnerData.ProfileImage === null ? learnerData.SocialImage === null ? learnerData.profile_photo_url : learnerData.SocialImage : `${apiservice.apiImageShow()}/${learnerData.ProfileImage}`} className='w-100' alt='' />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-9 col-12 mt-3'>
                                    <div className='d-flex justify-content-between'>
                                        <div className='fw-bold mt-2'>{learnerData.name} {learnerData.lname}</div>
                                        {/* <div className='text-end text-lg-none'>
                                        <button className='btn btn-light mb-2 mb-lg-0'><img src={chaticon} alt='' className='me-2' />Chat Now!</button>
                                        <button className='btn btn-light ms-3' onClick={() => props.history.push('/home/writereview')}><img src={reviewicon} alt='' className='me-2' />Give a Review</button>
                                    </div> */}
                                    </div>
                                    <div className='fw-bold mt-2'>Education</div>
                                    <div className='mt-2'>{learnerEduData && learnerEduData.Name}</div>
                                    <div className='d-flex'>
                                        <div className='mt-2'>
                                            <img src={degreeIcon} alt='' className='me-2' />
                                        </div>
                                        <div className='mt-2'>
                                            {learnerEduData && learnerEduData.UniversityName}
                                        </div>
                                    </div>

                                    <div className='fw-bold mt-3'>About me</div>
                                    <div className=' mt-2'>
                                        <div dangerouslySetInnerHTML={{ __html: learnerData.AboutSelf }}></div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className='mt-5'>
                            <div className=''>
                                <div className='fs-3 fw-bold'>Reviews</div>
                            </div>

                            {listOfReviews()}

                            {viewShowCount >= learnerReviewData.length ? '' :
                                <div className="w-100 text-center py-3 my-2">
                                    <button
                                        type="button"
                                        className="btn btn-primary border-0 text-center"
                                        style={{ backgroundColor: "#E57A4D" }}
                                        onClick={onClickShowMore}
                                    >
                                        Show More <HiArrowLongRight />
                                    </button>
                                </div>
                            }
                        </div>
                    </div>
                </div>}
        </>
    )
}

export default LearnersDatailsView
