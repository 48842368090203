import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

function Loader() {
    return (
        <div className=''>
            <div className=' text-center' style={{margin:'200px 0px', color: '#E57A4D' }}>
                <FontAwesomeIcon className='fa-2x' icon={faSpinner} spin /> <span className='fs-3 ms-4 fw-bold'>Loading...</span>
            </div>
        </div>
    )
}

export default Loader
