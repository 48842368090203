import React from 'react'
import './HomePage.scss'
import { Switch, Route } from 'react-router-dom/cjs/react-router-dom'
import SidebarPage from '../../components/common-components/SidebarPage/SidebarPage'
import Error404Page from '../../components/common-components/Error404Page/Error404Page'
import DashboardPage from '../DashboardPage/DashboardPage'
import TestimonialPage from '../TestimonialPage/TestimonialPage'
import CreateTestimonial from '../TestimonialPage/CreateTestimonial'
import ContentExpertRequestPage from '../ContentExpertRequest/ContentExpertRequestPage'
import NotificationPage from '../NotificationPage/NotificationPage'
import ContentExpertDetailsPage from '../ContentExpertRequest/ContentExpertDetailsPage'
import SettingsPage from '../Settings/SettingsPage'
import Header from '../../components/common-components/Header/Header'
import { useEffect } from 'react'
import routeProtect from '../../components/common-components/routeProtect'
import Reports from '../ReportsPage/Reports'
import PreviewPage from '../PreviewPage/PreviewPage'
import Reviews from '../Reviews/Reviews'
import TotalServices from '../TotalServices/TotalServices'
import TotalLearners from '../TotalLearners/TotalLearners'
import LearnersDatailsView from '../TotalLearners/LearnersDatailsView'
import TotalBooking from '../TotalBooking/TotalBooking'
import PaymentRequest from '../PaymentRequest/PaymentRequest'
import ReviewsForLearner from '../ReviewsForLearner/ReviewsForLearner'
function HomePage(props) {
    useEffect(() => {
        let isLogin = routeProtect()
        console.log(isLogin);
    }, [])
    return (
        <>
            {
                !routeProtect() ? props.history.push('/') :
                    <div className='container-fluid p-0'>
                        <div className='row m-0'>
                            <div className='col-2 d-lg-block d-none p-0'>
                                <div className='side-bar'>
                                    <SidebarPage history={props.history} />
                                </div>
                            </div>
                            <div className='col-lg-10 col-12  d-lg-none d-block p-0'>
                                <Header history={props.history} />
                            </div>
                            <div className='col-lg-10 col-12 p-0'>
                                <div className='page-container py-3 px-lg-5 px-2'>
                                    <Switch>
                                        <Route exact path={'/home/paymentrequest'} component={PaymentRequest} />
                                        <Route exact path={'/home/totalbooking'} component={TotalBooking} />
                                        <Route exact path={'/home/learnersdetails/:id'} component={LearnersDatailsView} />
                                        <Route exact path={'/home/totallearners'} component={TotalLearners} />
                                        <Route exact path={'/home/totalservices'} component={TotalServices} />
                                        <Route exact path={'/home/learnersreviews'} component={ReviewsForLearner} />
                                        <Route exact path={'/home/reviews'} component={Reviews} />
                                        <Route exact path={'/home/preview/:id'} component={PreviewPage} />
                                        <Route exact path={'/home/reports'} component={Reports} />
                                        <Route exact path={'/home/setting'} component={SettingsPage} />
                                        <Route exact path={'/home/contentexpertdetails/:id'} component={ContentExpertDetailsPage} />
                                        <Route exact path={'/home/contentexpertrequest'} component={ContentExpertRequestPage} />
                                        <Route exact path={'/home/createtestimonial'} component={CreateTestimonial} />
                                        <Route exact path={'/home/notification'} component={NotificationPage} />
                                        <Route exact path={'/home/testimonial'} component={TestimonialPage} />
                                        <Route exact path={['/', '/home']} component={DashboardPage} />
                                        <Route component={Error404Page} />
                                    </Switch>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </>
    )
}

export default HomePage
