import { faBars } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import logo from '../../../assets/logo.png'
import dashboard from '../../../assets/dashboard.png'
import contentexpert from '../../../assets/content_expert.png'
import notification from '../../../assets/notification.png'
import testimonial from '../../../assets/testimonial.png'
import setting from '../../../assets/setting.png'
import logout from '../../../assets/logout.png'
import reporticon from '../../../assets/report-icon.png'

import handmoney from '../../../assets/Hand Money.png'
import { useState } from 'react'
import { useEffect } from 'react'
import './Header.scss'
import LogoutModal from '../LogoutModal'
import apiservice from '../../../apiservice/ApiService'
function Header(props) {
    const [activeMenu, setActiveMenu] = useState('/home')
    const [dashboardCount, setDashboardCount] = useState('')
    useEffect(() => {
        let curUrl = window.location.pathname
        setActiveMenu(curUrl)
        
        getNotificationCount()
    }, [])
    const onClickMenu = (e) => {
        
        getNotificationCount()
        if (e === '/') {
            // alert('data')
            localStorage.setItem('isDashboardSearch', false)
        }
        props.history.push(e)
        setActiveMenu(e)
    }

    const getNotificationCount = async () => {
        let userData = await apiservice.getLocalStorage('userdata')
        await apiservice.apiCall('/AdminDashboardCount', userData.Token)
            .then(res => {
                if (res.unAuthenticated) {
                    props.history.push('/')
                    localStorage.removeItem('userdata')
                } else if (res.data) {
                    console.log(res.data);
                    setDashboardCount(res.data)
                } else {
                    console.log(res);
                }
            })
            .catch(err => {
                console.log(err);
            })
    }
    const [isLogout, setIsLogout] = useState(false)
    const onClickLogout = () => {
        setIsLogout(true)
    }
    const cancelLogout = () => {
        setIsLogout(false)
    }
    return (
        <>
            <LogoutModal show={isLogout} history={props.history} cancel={cancelLogout} />
            <nav className="navbar navbar-expand-lg  bg-transparent">
                <div className="container-fluid">

                    <div className="navbar-brand" >
                        <img src={logo} alt='...'  onClick={() => onClickMenu('/')} />
                    </div>
                    <div className='me-auyo'>
                        <div className='p-3' data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
                            <FontAwesomeIcon className='fs-3' icon={faBars} />
                        </div>
                    </div>


                    <div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
                        <div className="offcanvas-header">

                            <img src={logo} alt='...'  data-bs-dismiss="offcanvas" aria-label="Close"  onClick={() => onClickMenu('/')}/>
                            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                        <div className="offcanvas-body">
                            <div className='mt-3'>
                                <div className={activeMenu === '/home' ? 'd-flex px-4 py-2 mb-4 active-menu' : 'd-flex px-4 py-2 mb-4'} data-bs-dismiss="offcanvas" aria-label="Close" onClick={() => onClickMenu('/')}>
                                    <div className='me-2'>
                                        <img src={dashboard} alt='...' />
                                    </div>
                                    <div className=''>Dashboard </div>
                                </div>
                                <div className={activeMenu === '/home/contentexpertrequest' ? 'd-flex px-4 py-2 mb-4 active-menu' : 'd-flex px-4 py-2 mb-4'} data-bs-dismiss="offcanvas" aria-label="Close" onClick={() => onClickMenu('/home/contentexpertrequest')}>
                                    <div className='me-2'>
                                        <img src={contentexpert} alt='...' />
                                    </div>
                                    <div className=''>Content Expert Request </div>
                                </div>
                                <div className={activeMenu === '/home/reports' ? 'd-flex px-4 py-2 mb-4 active-menu' : 'd-flex px-4 py-2 mb-4'} data-bs-dismiss="offcanvas" aria-label="Close" onClick={() => onClickMenu('/home/reports')}>
                                    <div className='me-2'>
                                        <img src={contentexpert} alt='...' />
                                    </div>
                                    <div className=''>Report</div>
                                </div>

                                <div className={activeMenu === '/home/notification' ? 'd-flex px-4 py-2 mb-4 active-menu pointer justify-content-between' : ' pointer d-flex  justify-content-between px-4 py-2 mb-4'} data-bs-dismiss="offcanvas" aria-label="Close" onClick={() => onClickMenu('/home/notification')}>
                                    <div className='me-2 d-flex'>
                                        <div>
                                            <img src={notification} alt='...' />
                                        </div>
                                        <div className='ms-2'>Notification  </div>
                                    </div>
                                    {dashboardCount.AdminNotifications !== 0 ?
                                        <div className=''><div className='badge bg-secondary ms-3'>{dashboardCount.AdminNotifications}</div>  </div> : ''}

                                </div>
                                <div className={activeMenu === '/home/testimonial' ? 'd-flex px-4 py-2 mb-4 active-menu' : 'd-flex px-4 py-2 mb-4'} data-bs-dismiss="offcanvas" aria-label="Close" onClick={() => onClickMenu('/home/testimonial')}>
                                    <div className='me-2'>
                                        <img src={testimonial} alt='...' />
                                    </div>
                                    <div className=''>Testimonial </div>
                                </div>
                                <div className={activeMenu === '/home/paymentrequest' ? 'd-flex px-4 py-2 mb-4 active-menu' : 'd-flex px-4 py-2 mb-4'} data-bs-dismiss="offcanvas" aria-label="Close" onClick={() => onClickMenu('/home/paymentrequest')}>
                                    <div className='me-2'>
                                        <img src={handmoney} alt='...' />
                                    </div>
                                    <div className=''>Payment Request </div>
                                </div>
                                <div className={activeMenu === '/home/setting' ? 'd-flex px-4 py-2 mb-4 active-menu' : 'd-flex px-4 py-2 mb-4'} data-bs-dismiss="offcanvas" aria-label="Close" onClick={() => onClickMenu('/home/setting')}>
                                    <div className='me-2'>
                                        <img src={setting} alt='...' />
                                    </div>
                                    <div className=''>Setting </div>
                                </div>
                                <div className={activeMenu === '/' ? 'd-flex px-4 py-2 mb-4 active-menu' : 'd-flex px-4 py-2 mb-4'} data-bs-dismiss="offcanvas" aria-label="Close" onClick={onClickLogout}>
                                    <div className='me-2'>
                                        <img src={logout} alt='...' />
                                    </div>
                                    <div className='' >Log out </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </nav>
        </>
    )
}

export default Header
