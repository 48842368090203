import React from 'react'
import { Pagination } from 'react-bootstrap';
import { useState } from 'react';
import left from '../../assets/ArrowLeftShape.png'
import right from '../../assets/ArrowRightShape.png'
import leftLight from '../../assets/ArrowLightLeftShape.png'
import rightLight from '../../assets/ArrowLightRightShape.png'
import noimage from '../../assets/no-image.png'
import { getContentExpertSingleData } from '../../redux/contentExpertReducer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faRefresh } from '@fortawesome/free-solid-svg-icons';
import noiamge from '../../assets/no-image.png'
import apiservice from '../../apiservice/ApiService';
function RequestPendingPage(props) {
    console.log(props.data.length);
    let dataArr = props.data.length > 0 ? props.data.filter((data) => data.is_admin_verified === 0) : [];
    console.log(dataArr);

    const itemsPerPage = 5;
    const [currentPage, setCurrentPage] = useState(1);

    const totalPages = Math.ceil(dataArr.length / itemsPerPage);

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const itemsToDisplay = dataArr.slice(startIndex, endIndex);

    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const pageRangeStart = Math.max(1, currentPage - 2);
    const pageRangeEnd = Math.min(totalPages, pageRangeStart + 2);

    const onClickViewProfile = async (data) => {
        props.history.push(`/home/contentexpertdetails/${data.content_expert_id}`)


    }

    const contentExpertPendingList = () => {
        let array = itemsToDisplay
        let list = []


        for (let index = 0; index < array.length; index++) {
            let data = array[index]


            list.push(
                <div key={index} className='p-3 border rounded-3 mb-3'>
                    <div className='row'>
                        <div className='col-lg-10 col-12' onClick={() => onClickViewProfile(data)}>
                            <div className='d-flex'>
                                <div className=''>
                                    <div className='rounded-circle overflow-hidden userIcon' >
                                        <img src={data.ProfileImage === null ? data.SocialImage === null ? data.profile_photo_url : data.SocialImage : `${apiservice.apiImageShow()}/${data.ProfileImage}`} alt='...' className='w-100 h-100' />
                                    </div>
                                </div>

                                <div className='ms-3  pt-0'>
                                    <div className='fw-bold'>{data.name} {data.lname}</div>
                                    <div className='text-grey'>{data.lastDesignation === null ? 'Designation not avilable' : data.lastDesignation}</div>
                                    <div className='d-lg-flex'><div>{data.services === null ? 0 : data.services} Services, </div>  <div className='ms-2'> Total Experience - {data.overallExp === null ? 0 : data.overallExp} Years</div></div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-2 col-12'>
                           <div className=' col-12  text-lg-end mt-lg-0 mt-3'>
                                <button className='btn w-100 btn-orange ' onClick={() => onClickViewProfile(data)}>View Profile <FontAwesomeIcon className='ms-3' icon={faArrowRight} /></button>
                            </div>
                        </div>
                    </div>
                </div>
            )

        }

        return list
    }
    return (
        <>
            <div className='mt-3'>
                {dataArr.length > 0 ?
                    <div>
                        <div>{contentExpertPendingList()}</div>
                        <div className="pagination-container mt-3" style={{ display: 'flex', justifyContent: 'center' }}>
                            <Pagination>
                                <Pagination.Prev
                                    className={currentPage === 1 ? "disbaled_Btn" : "prevBtn"}
                                    onClick={handlePreviousPage}
                                    disabled={currentPage === 1 ? true : false}
                                >
                                    <img src={currentPage === 1 ? leftLight : left} alt='...' />
                                </Pagination.Prev>
                                {[...Array(pageRangeEnd - pageRangeStart + 1).keys()].map((offset) => (
                                    <Pagination.Item
                                        key={pageRangeStart + offset}
                                        active={pageRangeStart + offset === currentPage}
                                        onClick={() => handlePageChange(pageRangeStart + offset)}
                                    >
                                        {pageRangeStart + offset}
                                    </Pagination.Item>
                                ))}
                                {pageRangeEnd < totalPages && (
                                    <Pagination.Ellipsis disabled />
                                )}
                                <Pagination.Next
                                    className={currentPage === totalPages ? "disbaled_Btn" : "nextBtn"}
                                    onClick={handleNextPage}
                                    disabled={currentPage === totalPages ? true : false}
                                >
                                    <img src={currentPage === totalPages ? rightLight : right} alt='...' />
                                </Pagination.Next>
                            </Pagination>
                        </div>
                    </div>
                    : <div>
                        <div className='text-center mt-5 text-danger'>
                            There are no pending content expert profiles to approve or reject.
                        </div>
                        <div className='mt-3 text-center'>
                            <button className='btn btn-light' onClick={props.refreshdata}>Refresh <FontAwesomeIcon className='ms-2' icon={faRefresh} /></button>
                        </div>
                    </div>}
            </div>
        </>
    )
}

export default RequestPendingPage
