import React, { useEffect } from 'react'
import './LoginPage.scss'
import logo from '../../assets/logo.png'
import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import validation from '../../components/common-components/validation'
import apiservice from '../../apiservice/ApiService'
import routeProtect from '../../components/common-components/routeProtect'
import Loader from '../../components/common-components/Loader'
function LoginPage(props) {
  const initialdata = {
    email: '',
    password: '',
    showPassword: true
  }
  const [data, setData] = useState(initialdata)
  const [errorMsg, setErrorMsg] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    !routeProtect() ? props.history.push('/') : props.history.push('/home')
  }, [])
  const onChangeHandle = (e) => {
    const { name, value } = e.target
    setData({ ...data, [name]: value })
    setErrorMsg('')
  }

  const onClickShowPassword = () => {
    setData({ ...data, 'showPassword': data.showPassword = !data.showPassword })
  }

  const onClickSignIn = async () => {
    if (data.email === '') {
      setErrorMsg('Email Id is required')
    } else if (!data.email.match(validation().emailVal)) {
      setErrorMsg('Email Id is not valid')
    } else if (data.password === '') {
      setErrorMsg('Please enter password ')
    } else if (!data.password.match(validation().passwordVal)) {
      setErrorMsg('Please enter valid password (Abc@123)')
    }
    else {
      setIsLoading(true)
      let dataObject = {
        email: data.email,
        password: data.password
      }
      await apiservice.apiCall('/signin', '', dataObject, 'POST').then(res => {

        console.log(res.data);
        if (res.data) {
          apiservice.setLocalStorage('userdata', res.data)
          if (res.data.user_type === 0) {
            setIsLoading(false)
            props.history.push('/home')
          } else {
            setIsLoading(false)
            setErrorMsg('Invalid. Record Not Found!')
          }
        } else {
          setIsLoading(false)
          setErrorMsg(res.error)
        }
      })

    }
  }

  return (
    <>
    {isLoading?<Loader />:
      <div className='login-main-container'>
        <div className='p-5'>
          <img src={logo} alt='...' />
        </div>
        <div className='container'>
          <div className='col-lg-6 col-12 offset-lg-3 offset-0 my-5 border border-light border-4 rounded-4 p-5 sub-container'>
            <div className='fs-3 fw-bold'>Sign in</div>
            <div className='mt-3'>Welcome! Please enter your details.</div>

            <div className='mt-3'>
              <div className='text-center'>
                {errorMsg !== '' ?
                  <small className='text-danger'>{errorMsg}</small>
                  : ''}
              </div>
              <div className='mb-3'>
                <div className="form-group">
                  <label htmlFor="">Email</label>
                  <input type="email"
                    className="form-control" name="email" value={data.email} onChange={onChangeHandle} placeholder="Email" />
                </div>
              </div>
              <div className='mb-3'>
                <label htmlFor="">Password</label>
                <div className="input-group ">
                  <input type={data.showPassword ? "password" : "text"}
                    className="form-control border-end-0" name="password" value={data.password} onChange={onChangeHandle} placeholder="Password" />
                  <span className="input-group-text bg-white " id="basic-addon2">
                    {data.showPassword ?
                      <FontAwesomeIcon icon={faEyeSlash} onClick={onClickShowPassword} /> :
                      <FontAwesomeIcon icon={faEye} onClick={onClickShowPassword} />}
                  </span>
                </div>
              </div>
              <div className='mb-3'>
                <span className=' fw-bold forgotPasswordText' onClick={() => props.history.push('/forgotpassword')} >Forgot Password?</span>
              </div>
              <div className='mb-3'>
                <button className='btn border border-dark px-4 py-2' type='submit' onClick={onClickSignIn} >Sign in <FontAwesomeIcon icon={faArrowRight} className='ms-2' /></button>
              </div>
            </div>
          </div>
        </div>
      </div>}
    </>
  )
}

export default LoginPage
