import React from 'react'
import { useState } from 'react';
import StarRatings from 'react-star-ratings';
import apiservice from '../../apiservice/ApiService';
import { useEffect } from 'react';
import noiamge from '../../assets/no-image.png'
import Loader from '../../components/common-components/Loader';
function CreateTestimonial(props) {
    const initialDataState = {
        name: '',
        selectRating: 4,
        description: '',
        profileImage: noiamge,
        isEdit: 'false',
        viewSelectedImage: '',

    };


    const [data, setData] = useState(initialDataState);
    const [errorMsg, setErrorMsg] = useState('')
    const [isEditData, setIsEditData] = useState('')
    const inputRef = React.useRef();
    let editData = localStorage.getItem('isEdit')
    const [isLoading, setIsLoading] = useState(false)
    useEffect(() => {
        if (editData === 'true') {

            getDataFormLocal()
        }
        setData({ ...data, 'isEdit': editData })

    }, [])

    const getDataFormLocal = async () => {
        setIsLoading(true)
        let localDatas = await apiservice.getLocalStorage('isEditTestimonialData')
        let userdata = await apiservice.getLocalStorage('userdata')
        // console.log(localDatas.test_id);
        console.log(userdata.Token);
        await apiservice.apiCall(`/getTestimonal/${localDatas.test_id}`, userdata.Token).then(res => {
            if (res.unAuthenticated) {
                props.history.push('/')
                localStorage.removeItem('userdata')
            } else if (res.data) {
                setIsLoading(false)
                let datas = res.data
                setIsEditData(datas)
                if (editData === 'true') {
                    console.log(datas);
                    setData({
                        ...data,
                        name: datas.Name === null ? '' : datas.Name,
                        description: datas.Description === null ? '' : datas.Description,
                        selectRating: datas.Ratting === null ? '' : datas.Ratting,
                        profileImage: datas.ProfileImage === null ? noiamge : `${apiservice.apiImageShow()}/${datas.ProfileImage}`
                    })
                }
            } else {
                setIsLoading(false)
                console.log(res);
            }
        }).catch(err => {
            setIsLoading(false)
            console.log(err);
        })

    }

    const handleInputChange = event => {
        const { name, value } = event.target;
        setData({ ...data, [name]: value });
        setErrorMsg('')
    };
    const onSelectProfileImage = (e) => {
        console.log(e.target.files[0]);
        let file = e.target.files[0]
        if (file !== undefined) {
            if (file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/jpg') {
                let imageURL = URL.createObjectURL(file)
                setData({ ...data, 'profileImage': file, 'viewSelectedImage': imageURL })
                setErrorMsg('')
            } else {
                setErrorMsg('Please select valid image file')
            }
        }

    }

    const onSelectStarRating = (e) => {
        console.log(e);
        setData({ ...data, 'selectRating': e })
    }
    const onClickCreate = async () => {
        if (data.name === '') {
            setErrorMsg('Name is required!')
        }
        else if (data.description === '') {
            setErrorMsg('Description is required!')
        }
        else {
            let userdata = await apiservice.getLocalStorage('userdata')
            let formData = new FormData()
            formData.append('Name', data.name)
            formData.append('Description', data.description)
            formData.append('Ratting', data.selectRating)
            formData.append('ProfileImage', data.profileImage)
            let dataObject = {
                auth: userdata.Token,
                data: formData
            }
            console.log(dataObject);

            await apiservice.apiCallformData('/Testimonalstore', userdata.Token, formData, 'POST').then(res => {
                if (res.unAuthenticated) {
                    props.history.push('/')
                    localStorage.removeItem('userdata')
                } else if (res.data) {
                    console.log(res.data);
                    props.history.push('/home/testimonial')
                } else {
                    console.log(res);
                }
            }).catch(err => {
                console.log(err);
            })

        }

    }
    const onClickUpdate = async () => {
        if (data.name === '') {
            setErrorMsg('Name is required!')
        }
        else if (data.description === '') {
            setErrorMsg('Description is required!')
        }
        else {
            let userdata = await apiservice.getLocalStorage('userdata')
            let formData = new FormData()
            console.log(data.profileImage);
            formData.append('Name', data.name)
            formData.append('Description', data.description)
            formData.append('Ratting', data.selectRating)
            formData.append('ProfileImage', data.profileImage)
            await apiservice.apiCallformData(`/Testimonalupdate/${isEditData.test_id}`, userdata.Token, formData, 'POST').then(res => {
                if (res.unAuthenticated) {
                    props.history.push('/')
                    localStorage.removeItem('userdata')
                } else if (res.data) {
                    console.log(res.data);
                    props.history.push('/home/testimonial')
                } else {
                    console.log(res);
                }
            }).catch(err => {
                console.log(err);
            })


        }
    }

    const onClickCancel = () =>{
        props.history.push('/home/testimonial')
    }
    return (
        <>{isLoading ? <Loader /> :
            <div className='p-3'>
                <div className='d-flex justify-content-between'>
                    <div className='fs-3 fw-bold'>Testimonial</div>
                </div>
                <hr />
                {editData === 'true' ?
                    <div className='fw-bold'>Edit Testimonial</div> :
                    <div className='fw-bold'>Create Testimonial</div>}

                <div className='mt-3 col-lg-8 col-12'>
                    {errorMsg === '' ? '' :
                        <div className='my-2 text-center'>
                            <div className='text-danger'>{errorMsg}</div>
                        </div>
                    }
                    <div className='d-flex justify-content-between'>
                        <div className='d-flex'>
                            <div className='rounded-circle overflow-hidden' style={{ width: '100px', height: '100px' }}>
                                <img src={data.viewSelectedImage === '' ? data.profileImage : data.viewSelectedImage} alt='' width={'100%'} height={'100%'} />
                            </div>
                            {/* <div className='mt-2'>
                                <div className='ms-3 fw-bold pt-4 fs-5'>Profile Photo <span style={{ color: '#ff0000' }}>*</span></div>
                            </div> */}
                        </div>
                        <div className='pt-4'>
                            <input type="file"
                                accept=".jpg,.png,.jpeg"
                                style={{ display: 'none' }}
                                onChange={onSelectProfileImage}
                                ref={(instance) => {
                                    inputRef.current = instance; // We also need it to manipulate the elemnent
                                }} />
                            <div className='mt-2 border-bottom border-dark pointer' onClick={() => inputRef.current?.click()} >Change Photo</div>
                        </div>
                    </div>

                    <div className='mt-5'>
                        <div className='row'>
                            <div className='col-lg-6 col-12'>
                                <div className="form-group">
                                    <label htmlFor="">Name</label>
                                    <input type="text"
                                        className="form-control bg-transparent" name="name" value={data.name} onChange={handleInputChange} placeholder="Enter Your Name" />
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className='mt-3'>
                        <div>Select rating</div>
                        <div className='row'>
                            <div className='col-lg-3 col-12'>
                                <div className={data.selectRating === 4 ? 'd-flex bg-light rounded-2 border border-dark p-3 justify-content-center' : 'd-flex bg-light rounded-2 p-3 justify-content-center'} onClick={() => onSelectStarRating(4)}>
                                    <StarRatings
                                        rating={4}
                                        starRatedColor="#FDB022"
                                        numberOfStars={5}
                                    /> <div className='ms-3'>4.0</div>
                                </div>
                            </div>

                            <div className='col-lg-3 col-12 mt-lg-0 mt-3'>
                                <div className={data.selectRating === 5 ? 'd-flex bg-light rounded-2 border border-dark p-3 justify-content-center' : 'd-flex bg-light rounded-2 p-3 justify-content-center'} onClick={() => onSelectStarRating(5)}>
                                    <StarRatings
                                        rating={5}
                                        starRatedColor="#FDB022"
                                        numberOfStars={5}
                                    /> <div className='ms-3'>5.0</div>
                                </div>
                            </div>

                        </div>

                    </div>
                    <div className='mt-3'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className="form-group">
                                    <label htmlFor="">Description</label>
                                    <textarea type="text"
                                        className="form-control bg-transparent" name="description" maxLength={150} value={data.description} rows={'5'} onChange={handleInputChange} ></textarea>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='text-center mt-5'>
                        
                        {editData === 'true' ?
                            <button className='btn btn-orange px-5' onClick={onClickUpdate}>Update</button> :
                            <button className='btn btn-orange px-5' onClick={onClickCreate}>Create</button>}

                            <button className='btn btn-secondary px-5 ms-3' onClick={onClickCancel}>Cancel</button>
                    </div>
                </div>
            </div>
        }
        </>
    )
}

export default CreateTestimonial
