import React, { useEffect, useState } from 'react'
import './SettingsPage.scss'
// import { Modal, ModalBody } from 'react-bootstrap';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faArrowRight, faTimes } from '@fortawesome/free-solid-svg-icons';
// import { countries } from 'country-list-json';
import validation from '../../components/common-components/validation';
import apiservice from '../../apiservice/ApiService';
import noiamge from '../../assets/no-image.png'
import Loader from '../../components/common-components/Loader';
function SettingsPage(props) {
    const initialDataState = {
        profileImage: '',
        email: '',
        firstName: '',
        lastName: '',
        profileurl: '',
        // countyCode: '+91',
        // mobile: '',
        verifyOtp: '',
        viewSelectedImage: '',

        currentPassword: '',
        newPassword: '',
        reEnterPassword: '',

        authtoken: '',
        successMsg: '',
        successMsgShow: false
    };
    // const [showCommentPopup, setShowCommentPopup] = useState(false)
    const [data, setData] = useState(initialDataState);

    const [errorMsg, setErrorMsg] = useState('')
    const [menuKey, setMenuKey] = useState('Personal');
    const inputRef = React.useRef();
    const [isLoading, setIsLoading] = useState(false)

    const handleInputChange = event => {
        const { name, value } = event.target;
        setData({ ...data, [name]: value });
        setErrorMsg('')
    };
    const onSelectProfileImage = (e) => {
        console.log(e.target.files);
        let file = e.target.files[0]
        console.log(file);
        if (file !== undefined) {

            if (file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/jpg') {
                let imageURL = URL.createObjectURL(file)
                setData({ ...data, 'profileImage': file, 'viewSelectedImage': imageURL })
                setErrorMsg('')
            } else {
                setErrorMsg('please select valid image file')
            }
        }
    }
    useEffect(() => {
        getUserdata();

    }, [])

    const getUserdata = async () => {

        let userdata = null
        userdata = await apiservice.getLocalStorage('userdata')
        console.log(userdata);
        updateUserData(userdata)
    }

    const onClickTopMenu = (e) => {
        setMenuKey(e)
        setErrorMsg('')
    }

    const setUserData = async (datas) => {
        let newdata = await apiservice.getLocalStorage('userdata')
        console.log(newdata);
        setData({
            ...data,
            profileurl: newdata.ProfileImage === null ? newdata.SocialImage === null ? newdata.profile_photo_url : newdata.SocialImage : `${apiservice.apiImageShow()}/${newdata.ProfileImage}`,
            // profileurl: newdata.ProfileImage,
            firstName: newdata.name !== null ? newdata.name : '',
            email: newdata.email !== null ? newdata.email : '',
            lastName: newdata.lname !== null ? newdata.lname : '',
            authtoken: newdata.Token !== null ? newdata.Token : ''
        })
    }


    const updateUserData = async (data) => {
        setIsLoading(true)
        let token = data.Token
        await apiservice.apiCall('/Admin_Profile', token).then(async res => {
            if (res.unAuthenticated) {
                props.history.push('/')
                localStorage.removeItem('userdata')
            } else if (res.data) {

                await apiservice.setLocalStorage('userdata', res.data[0])
                setUserData(res.data[0])
                setIsLoading(false)
            } else {
                console.log(res);
                setIsLoading(false)
            }
        }).catch(err => {
            setIsLoading(false)
            console.log(err);
        })

    }

    const onClickSaveChange = async () => {
        if (menuKey === 'Personal') {
            if (data.firstName === '') {
                setErrorMsg('Please enter first name!')
            } else if (data.lastName === '') {
                setErrorMsg('Please enter last name!')

            }
            else {
                let formData = new FormData()
                formData.append('name', data.firstName)
                formData.append('lname', data.lastName)
                formData.append('ProfileImage', data.profileImage)

                await apiservice.apiCallformData('/Admin_ProfileDeatilsStore', data.authtoken, formData, "POST").then(res => {
                    if (res.unAuthenticated) {
                        props.history.push('/')
                        localStorage.removeItem('userdata')
                    } else if (res.data) {
                        setData({ ...data, 'successMsg': 'Successfully updated Profile', successMsgShow: true })
                        setTimeout(() => {
                            setData({ ...data, 'successMsg': '', successMsgShow: false })
                            getUserdata();
                        }, 3000);
                    } else {
                        console.log(res);
                        setErrorMsg(res.error)
                    }
                }).catch(err => {
                    console.log(err);
                })
            }
        }
        if (menuKey === 'Change Password') {
            if (data.currentPassword === '') {
                setErrorMsg('Please enter current password')
            } else if (data.newPassword === '') {
                setErrorMsg('Please enter new password')
            } else if (data.currentPassword === data.newPassword) {
                setErrorMsg('Current password and new password should not be same!')
            } else if (!data.newPassword.match(validation().passwordVal)) {
                setErrorMsg('Your password must be at least 8 characters long and include min 1 lowercase, 1 uppercase, 1 digit, and 1 special character.')
            } else if (data.reEnterPassword === '') {
                setErrorMsg('Please re-enter new password')
            }
            else if (data.reEnterPassword !== data.newPassword) {
                setErrorMsg('password is mismatch!')
            } else {
                setIsLoading(true)
                let dataObject = {
                    password: data.currentPassword,
                    newpass: data.newPassword,
                    confirmpass: data.reEnterPassword,
                }
                await apiservice.apiCall('/Admin_changepassword', data.authtoken, dataObject, 'POST').then(res => {
                    if (res.unAuthenticated) {
                        props.history.push('/')
                        localStorage.removeItem('userdata')
                    } else if (res.data) {
                        setIsLoading(false)
                        setData({ ...data, 'successMsg': `${res.data} you will be auto logout in 3 second!`, successMsgShow: true })
                        setTimeout(() => {
                            setData({ ...data, 'successMsg': '', successMsgShow: false, currentPassword: '', newPassword: '', reEnterPassword: '' })
                            localStorage.removeItem('userdata')
                            props.history.push('/')
                        }, 3000);
                    } else {
                        setIsLoading(false)
                        console.log(res);
                        setErrorMsg(res.error)
                    }
                }).catch(err => {
                    setIsLoading(false)
                    console.log(err);
                })
            }
        }
    }

    return (
        <>
            {isLoading ?
                <Loader />
                :
                <div className='p-3'>
                    <div className='container-fluid'>
                        <div className='fs-3 fw-bold'>Profile</div>
                    </div>
                    <div className='mt-3'>
                        <div className='row'>

                            <div className='col-lg-3 col-12'>
                                <div className='p-2'>
                                    <button className={menuKey === 'Personal' ? 'btn  w-100 menu active_menu' : 'btn btn-light w-100 menu'} onClick={() => onClickTopMenu('Personal')}>Personal Details</button>
                                </div>
                            </div>
                            <div className='col-lg-3 col-12'>
                                <div className='p-2'>
                                    <button className={menuKey === 'Change Password' ? 'btn  w-100 menu active_menu' : 'btn btn-light w-100 menu'} onClick={() => onClickTopMenu('Change Password')}>Change Password</button>
                                </div>
                            </div>
                            <div className='col-lg-3 col-12 offset-lg-3 offset-0'>
                                <div className='p-2'>
                                    <button className='btn btn-orange w-100' onClick={onClickSaveChange} >Save Changes</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    {data.successMsgShow ?
                        <div className='text-center text-success'>{data.successMsg}</div> : ''}
                    <div className='col-lg-8 col-12'>
                        <div className='p-3'>
                            {menuKey === 'Personal' ?
                                <div className=''>
                                    <div className='d-flex justify-content-between'>
                                        <div className='d-flex'>
                                            <div className='rounded-circle overflow-hidden' style={{ width: '100px', height: '100px' }}>
                                                <img src={data.viewSelectedImage === '' ? data.profileurl : data.viewSelectedImage} alt='' width={'100%'} height={'100%'} />
                                            </div>

                                        </div>
                                        <div className='pt-4'>
                                            <input type="file"
                                                accept=".jpg,.png,.jpeg"
                                                style={{ display: 'none' }}
                                                onChange={onSelectProfileImage}
                                                ref={(instance) => {
                                                    inputRef.current = instance; // We also need it to manipulate the elemnent
                                                }} />
                                            <div className='mt-2 border-bottom border-dark pointer' onClick={() => inputRef.current?.click()} >Change Photo</div>
                                        </div>
                                    </div>
                                    <div className='text-center'>
                                        {errorMsg !== '' ?
                                            <small className='text-danger'>{errorMsg}</small>
                                            : ''}
                                    </div>
                                    <div className='mt-3'>
                                        <div className='row'>
                                            <div className='mb-3'>
                                                <div className='col-lg-6 col-12'>
                                                    <div className="form-group">
                                                        <label htmlFor="">First name</label>
                                                        <input type="text"
                                                            className="form-control bg-transparent" name="firstName" value={data.firstName} onChange={handleInputChange} placeholder="First name" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='mb-3'>
                                                <div className='col-lg-6 col-12'>
                                                    <div className="form-group">
                                                        <label htmlFor="">Last name</label>
                                                        <input type="text"
                                                            className="form-control bg-transparent" name="lastName" value={data.lastName} onChange={handleInputChange} placeholder="Last name" />
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                    <div className=''>
                                        <div className='row'>
                                            <div className=' col-12'>
                                                <div className="form-group">
                                                    <div className='d-flex justify-content-between'>
                                                        <label htmlFor="">Email</label>
                                                        {/* <div className='mt-2 mb-1 border-bottom border-dark pointer' onClick={onClickShowComments}>Change E-mail</div> */}
                                                    </div>
                                                    <input type="text"
                                                        className="form-control  bg-gray" name="email" value={data.email} onChange={handleInputChange} disabled placeholder="sample@sample.com" />
                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                </div>
                                :
                                <div>

                                    <div className='mt-3'>
                                        <div className=' fw-bold  fs-5'>Change Password</div>
                                    </div>

                                    <div className='mt-3'>
                                        <div className='text-center'>
                                            {errorMsg !== '' ?
                                                <small className='text-danger'>{errorMsg}</small>
                                                : ''}
                                        </div>
                                        <div className=''>
                                            <div className='mb-3'>

                                                <div className='col-lg-4 col-12'>
                                                    <div className="form-group">
                                                        <label htmlFor="">Current password</label>
                                                        <input type="password"
                                                            className="form-control bg-transparent" name="currentPassword" value={data.currentPassword} onChange={handleInputChange} placeholder="Current password" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='mb-3'>

                                                <div className='col-lg-4 col-12'>
                                                    <div className="form-group">
                                                        <label htmlFor="">New password</label>
                                                        <input type="password"
                                                            className="form-control bg-transparent" name="newPassword" value={data.newPassword} onChange={handleInputChange} placeholder="New password" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='mb-3'>

                                                <div className='col-lg-4 col-12'>
                                                    <div className="form-group">
                                                        <label htmlFor="">Re-enter new password</label>
                                                        <input type="password"
                                                            className="form-control bg-transparent" name="reEnterPassword" value={data.reEnterPassword} onChange={handleInputChange} placeholder="Re-enter new password" />
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            }
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default SettingsPage
