import React, { useEffect, useState } from 'react'
import totalEarning from '../../assets/SVG ICON/earning.svg'
import onGoingEarning from '../../assets/SVG ICON/passive-income.svg'
import completedEarning from '../../assets/SVG ICON/approved-payment.svg'
import receivedEarning from '../../assets/SVG ICON/income.svg'
import apiservice from '../../apiservice/ApiService'
import moment from 'moment'
import './PaymentRequest.scss'
import DataTable from 'datatables.net-dt';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-buttons/js/dataTables.buttons.js"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import { Modal, ModalBody } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
function PaymentRequest(props) {

    const initialData = {
        transactionId: '',
        reqCancelReason: ''
    }
    const [data, setData] = useState(initialData)
    const [paymentHistoryandCount, setPaymentHistoryandCount] = useState([])
    const [paymentHistoryListArr, setPaymentHistoryListArr] = useState([])
    const [PayPopup, setPayPopup] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')
    const [successMsg, setSuccessMsg] = useState('')
    const [reqError, setReqError] = useState('')
    const [currentSelectedReq, setCurrentSelectedReq] = useState('')

    const [cancelPopup, setCancelPopup] = useState(false)


    useEffect(() => {
        getPaymentHistoryandCounts()
        setTimeout(() => {
            new DataTable('#myTable', {
                pagingType: 'full_numbers',
                pageLength: 10,
                processing: true,
                order: false,
                dom: '<"top"ifrt><"bottom"lp><"clear">',


            });
        }, 1000);
    }, [])

    const onChangeHandle = (e) => {
        const { name, value } = e.target
        setData({ ...data, [name]: value })
        setErrorMsg('')
    }

    const getPaymentHistoryandCounts = async () => {
        let uData = await apiservice.getLocalStorage('userdata')
        await apiservice.apiCall('/admin_payout_list', uData.Token).then(res => {
            if (res.unAuthenticated) {
                props.history.push('/')
                localStorage.removeItem('userdata')
            } else if (res.data) {
                console.log(res.data);
                setPaymentHistoryandCount(res.data)
                let dataArr = res.data.request_list
                setPaymentHistoryListArr(dataArr)
            } else {
                console.log(res.error);
            }
        }).catch(err => {
            console.log(err);
        })
    }

    const paymentHistoryList = () => {
        let array = paymentHistoryListArr
        let list = []
        for (let index = 0; index < array.length; index++) {
            let items = array[index]
            console.log(items);
            list.push(
                <tr >
                    <td>{items.id}</td>
                    <td>{moment(items.created_at).format('DD-MM-YYYY')}</td>
                    <td>{items.content_expert.name} {items.content_expert.lname}</td>
                    <td>{items.content_expert.paypal_email === null ? '-' : items.content_expert.paypal_email}</td>
                    <td> {Number(items.amount).toFixed(2)} {items.content_expert.Currency}</td>
                    <td>{items.transaction_id === null ? '-' : items.transaction_id}</td>
                    <td>{items.status_date === null ? '-' : items.status_date}</td>
                    <td className={items.status === 'pending' ? 'text-warning' : items.status === 'completed' ? 'text-success' : 'text-danger'}>
                        {items.status.charAt(0).toUpperCase() + items.status.slice(1)}
                    </td>
                    <td >
                        <div className='d-flex'>
                            {items.status === 'completed' || items.status === 'cancel' ? '-' : <button className='btn btn-orange btn-sm' onClick={() => onClickPay(items)} >Pay</button>}
                            {items.status === 'completed' || items.status === 'cancel' ? '-' : <button className='btn btn-outline-danger btn-sm ms-3' onClick={() => onClickCancel(items)}>Cancel</button>}
                        </div>
                    </td>
                </tr>
            )

        }
        return list

    }

    const onClickPay = (e) => {
        setCurrentSelectedReq(e)
        setPayPopup(true)
    }
    const onClickPopupClose = () => {
        setPayPopup(false)
        setData({ ...data, 'transactionId': '', })
        setSuccessMsg('')
        setReqError('')
        setErrorMsg('')
    }

    const paymentRequestSubmit = async () => {
        if (data.transactionId === '') {
            setErrorMsg('Transaction Id is required!')
        }
        else {

            let uData = await apiservice.getLocalStorage('userdata')
            let dataobject = {
                id: currentSelectedReq.id,
                transaction_id: data.transactionId
            }
            await apiservice.apiCall('/admin_payout_pay', uData.Token, dataobject, 'POST').then(res => {
                if (res.unAuthenticated) {
                    props.history.push('/')
                    localStorage.removeItem('userdata')
                } else if (res.data) {
                    setSuccessMsg(res.data)
                    getPaymentHistoryandCounts()
                } else {
                    setReqError(res.error)
                    console.log(res.error);
                }
            }).catch(err => {
                console.log(err);
            })
        }

    }

    const onClickCancel = (e) => {
        setCurrentSelectedReq(e)
        setCancelPopup(true)
    }
    const onClickCancelPopupClose = () => {
        setCancelPopup(false)
        setData({ ...data, 'reqCancelReason': '' })
        setSuccessMsg('')
        setReqError('')
        setErrorMsg('')

    }

    const cancelRequestSubmit = async () => {
        if (data.reqCancelReason === '') {
            setErrorMsg('Please write a reason!')
        }
        else {

            let uData = await apiservice.getLocalStorage('userdata')
            let dataobject = {
                id: currentSelectedReq.id,
                // reason: data.reqCancelReason
            }
            await apiservice.apiCall('/admin_payout_cancel', uData.Token, dataobject, 'POST').then(res => {
                if (res.unAuthenticated) {
                    props.history.push('/')
                    localStorage.removeItem('userdata')
                } else if (res.data) {
                    setSuccessMsg(res.data)
                    getPaymentHistoryandCounts()
                } else {
                    setReqError(res.error)
                    console.log(res.error);
                }
            }).catch(err => {
                console.log(err);
            })
        }
    }
    return (
        <>
            <Modal show={cancelPopup}>
                <ModalBody>
                    {successMsg !== '' || reqError !== '' ?
                        <div className='text-center'>
                            {successMsg !== '' ? <div className='text-success'>{successMsg}</div> : ''}
                            {reqError !== '' ? <div className='text-danger'>{reqError}</div> : ''}
                            <div className='mt-3'>
                                <button className='btn btn-orange' onClick={onClickCancelPopupClose}>OK</button>
                            </div>
                        </div>
                        :
                        <div>
                            <div className='text-center pt-1  bg-light shadow rounded-circle' style={{ width: 30, height: 30, position: 'absolute', top: -7, right: -6 }} onClick={onClickCancelPopupClose}><FontAwesomeIcon icon={faTimes} /></div>
                            <div className='fw-bold fs-5'>Payment Details</div>
                            <div className='text-danger'>{errorMsg}</div>
                            <div className='mt-3'>
                                <div class="form-group">
                                    <textarea type="text" onChange={onChangeHandle}
                                        class="form-control" name='reqCancelReason' value={data.reqCancelReason} placeholder={'Write a reason...'} />
                                </div>
                            </div>
                            <div className='mt-3'>
                                <button className='btn btn-orange' onClick={cancelRequestSubmit}>Yes</button>
                                <button className='btn btn-secondary ms-3' onClick={onClickCancelPopupClose}>No</button>
                            </div>
                        </div>}
                </ModalBody>
            </Modal>
            <Modal show={PayPopup}>
                <ModalBody>
                    {successMsg !== '' || reqError !== '' ?
                        <div className='text-center'>
                            {successMsg !== '' ? <div className='text-success'>{successMsg}</div> : ''}
                            {reqError !== '' ? <div className='text-danger'>{reqError}</div> : ''}
                            <div className='mt-3'>
                                <button className='btn btn-orange' onClick={onClickPopupClose}>OK</button>
                            </div>
                        </div>
                        :
                        <div>
                            <div className='text-center pt-1  bg-light shadow rounded-circle' style={{ width: 30, height: 30, position: 'absolute', top: -7, right: -6 }} onClick={onClickPopupClose}><FontAwesomeIcon icon={faTimes} /></div>
                            <div className='fw-bold fs-5'>Payment Details</div>
                            <div className='text-danger'>{errorMsg}</div>
                            <div className='mt-3'>
                                <div class="form-group">
                                    <input type="text" onChange={onChangeHandle}
                                        class="form-control" name='transactionId' value={data.transactionId} placeholder={'Transaction Id'} />
                                </div>
                            </div>
                            <div className='mt-3'>
                                <button className='btn btn-orange' onClick={paymentRequestSubmit}>Submit</button>
                            </div>
                        </div>}
                </ModalBody>
            </Modal>
            <div className='payment_request_container'>
                <div className='container-fluid'>
                    <div className='fs-3 fw-bold'>Payment Request</div>
                </div>

                <div className='row'>
                    <div className='mt-3 col-md-6 col-lg-3 col-12'>
                        <div className='p-3 rounded-2' style={{ backgroundColor: '#F8F7F2' }}>

                            <div className='text-end'>
                                <img src={totalEarning} alt='' />
                            </div>
                            <div className='align-bottom'>
                                <div className='fw-bold'>{paymentHistoryandCount.pending_request}</div>
                                <div className=''><div>Pending Request</div></div>
                            </div>
                        </div>
                    </div>
                    <div className='mt-3 col-md-6 col-lg-3 col-12'>
                        <div className='p-3 rounded-2' style={{ backgroundColor: '#F8F7F2' }}>

                            <div className='text-end'>
                                <img src={onGoingEarning} alt='' />
                            </div>
                            <div className='align-bottom'>
                                <div className='fw-bold'>{paymentHistoryandCount.completed_request}</div>
                                <div className=''><div>Completed Request</div></div>
                            </div>
                        </div>
                    </div>
                    <div className='mt-3 col-md-6 col-lg-3 col-12'>
                        <div className='p-3 rounded-2' style={{ backgroundColor: '#F8F7F2' }}>

                            <div className='text-end'>
                                <img src={completedEarning} alt='' />
                            </div>
                            <div className='align-bottom'>
                                <div className='fw-bold'>{paymentHistoryandCount.cancelled_request}</div>
                                <div className=''><div>Cancelled Request</div></div>
                            </div>
                        </div>
                    </div>
                    <div className='mt-3 col-md-6 col-lg-3 col-12'>
                        <div className='p-3 rounded-2' style={{ backgroundColor: '#F8F7F2' }}>

                            <div className='text-end'>
                                <img src={receivedEarning} alt='' />
                            </div>
                            <div className='align-bottom'>
                                <div className='fw-bold'>{paymentHistoryandCount.total_request}</div>
                                <div className=''><div>Total Request</div></div>
                            </div>
                        </div>
                    </div>


                </div>

                <div className='mt-3'>
                    <div>
                        {paymentHistoryListArr.length > 0 ? <div className=' overflow-auto'>

                            <table id='myTable' class="table  w-100  ">
                                <thead>
                                    <tr className=''>
                                        <th>Req. ID</th>
                                        <th>Req. Date</th>
                                        <th>Content Expert</th>
                                        <th>Paypal ID</th>
                                        <th>Amount</th>
                                        <th>Transaction ID</th>
                                        <th>Status Update Date</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {paymentHistoryList()}
                                </tbody>
                            </table>
                        </div>

                            :
                            <div className='mt-5 text-center text-danger'> You don't have any payout history yet!</div>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default PaymentRequest
