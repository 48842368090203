import React from 'react'
import { useState } from 'react';
import './TotalBooking.scss';
import StarRatings from 'react-star-ratings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Modal, ModalBody } from 'react-bootstrap';
import { useEffect } from 'react';
import apiservice from '../../apiservice/ApiService';
import moment from 'moment';
import Loader from '../../components/common-components/Loader';
function TotalBooking(props) {

    const initialData = {
        startDate: '',
        endDate: '',
        completedText: '',
        isCompleted: 'Yes',
        openCompletedFormPopup: false
    }

    const [data, setData] = useState(initialData)
    let [services, setServices] = useState([])
    const [menuKey, setMenuKey] = useState('On-Going');
    let [isLoading, setLoading] = useState(false)


    async function getReqData() {
        let uData = await apiservice.getLocalStorage('userdata')
        console.log(uData);
        try {
            setLoading(true)
            let learnerServices = await apiservice.apiCall('/get_all_bookings', uData.Token)
            if (learnerServices.unAuthenticated) {
                props.history.push('/')
                localStorage.removeItem('userdata')
            } else if (learnerServices.data) {
                console.log(learnerServices.data);
                setServices(learnerServices.data)
                setLoading(false)

            }
            else {
                console.log(learnerServices.error);
                setLoading(false)

            }
        } catch (error) {
            console.log(error);
            setLoading(false)
        }
    }
    useEffect(() => {
        let menukey = localStorage.getItem('currentMenu')
        setMenuKey(menukey)
        getReqData()

    }, [])

    const inputHandleChange = (e) => {
        console.log(e.target.name, e.target.value);
        const { name, value } = e.target
        setData({ ...data, [name]: value })
    }

    const onClickTopMenu = (e) => {
        localStorage.setItem('currentMenu', e)
        let menukey = localStorage.getItem('currentMenu')
        setMenuKey(menukey)
        console.log(menukey);
    }
    const openPopUp = () => {
        setData({ ...data, 'openCompletedFormPopup': true })
    }
    const closePopUp = () => {
        setData(initialData)

    }
    const onClickSubmit = () => {
        console.log(data);
    }
    function loadCompletedServices() {
        let list = [];
        let fetchitems;
        if (menuKey === 'Completed') {
            fetchitems = services.filter((value, index) => value.Status === 'Completed')
            console.log(fetchitems);
        }
        if (fetchitems.length > 0) {
            for (let i = 0; i < fetchitems.length; i++) {
                let item = fetchitems[i]
                console.log(item);

                list.push(
                    <div key={i} >
                        <div className='mt-3'>
                            <div className='p-3 border rounded-2'>
                                <div className='row'>
                                    <div className='col-md-3 col-12'>
                                        <img src={item.service.ThumbnailImage === null ? '' : `${apiservice.apiImageShow()}/${item.service.ThumbnailImage}`} className='w-100 ' height={'150px'} alt='...' />
                                    </div>
                                    <div className='col-lg-6 col-md-5 col-12  h-100'>
                                        <div className='mt-lg-0 mt-3'>
                                            <div className='fw-bold'>{item.service.Service_Name}</div>
                                            <small>{item.content_expert.name} {item.content_expert.lname}  <span className='badge text-dark ms-2' style={{ backgroundColor: '#ABEFC6' }}>{item.service.MentorshipType}</span></small>
                                            <div>
                                                <StarRatings
                                                    rating={item.service.averageRating}
                                                    starRatedColor="#FDB022"
                                                    numberOfStars={5}
                                                /> <span className='ps-2'>{Number(item.service.averageRating).toFixed(1)}</span>
                                            </div>
                                            <div className='d-flex justify-content-between align-items-end h-100 mt-2'>
                                                <div>
                                                    <div><b>Total time:</b> {item.service.Hour}h {item.service.Minutes}m  </div>
                                                </div>
                                            </div>
                                            {item.service.MentorshipType === 'Webinar Session' ?
                                                item.service.started_at === null ? '' :
                                                    <div className='mt-2'><b>Date:</b> {moment(item.service.started_at).format('DD-MM-YYYY')}, <b>Time:</b> {moment(item.service.started_at).format('hh:mm A')}  ({item.service.timezone})</div> : ''}
                                            <div className='fw-bold mt-2'>{data.CurrencySymbol} {Number(item.service.Price).toFixed(2)}</div>
                                        </div>
                                    </div>

                                    <div className='col-lg-3 col-md-4 text-md-end col-12' style={{ fontSize: '14px' }}>
                                        <div><span className='fw-bold mt-2 me-1'>Start Date :</span>{moment(item.BookingDate).format('DD-MM-YYYY')} </div>
                                        <div><span className='fw-bold mt-2 me-1'>End Date :</span>{moment(item.EndDate).format('DD-MM-YYYY')} </div>
                                        {item.service.MentorshipType === 'Webinar Session' ?
                                            <div className='mt-3 text-lg-end'>
                                                <button className='btn w-auto rounded-2'>{item.service.NumberOfParticipant} - Participants </button>
                                            </div> : ''}

                                        <div className='mt-3 text-lg-end'>
                                            <button className='btn btn-orange  rounded-2' onClick={() => props.history.push(`/home/preview/${item.ServiceId}`)} >View Details </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                )
            }
        } else {
            list.push(<div className='text-center my-5 fw-bold'>Data not available</div>)
        }

        return list
    }
    function loadUpcomingservices() {
        let list = [];
        let fetchitems;
        if (menuKey === 'Upcoming') {
            fetchitems = services.filter((value, index) => value.Status === 'Upcoming')
            console.log(fetchitems);
        }
        if (fetchitems.length > 0) {
            for (let i = 0; i < fetchitems.length; i++) {
                let item = fetchitems[i]
                console.log(item);
                list.push(
                    <div key={i} >

                        <div className='mt-3'>
                            <div className='p-3 border rounded-2'>
                                <div className='row'>
                                    <div className='col-md-3 col-12'>
                                        <img src={item.service.ThumbnailImage === null ? '' : `${apiservice.apiImageShow()}/${item.service.ThumbnailImage}`} className='w-100' height={'150px'} alt='...' />
                                    </div>
                                    <div className='col-lg-6 col-md-5 col-12  h-100'>
                                        <div className='mt-lg-0 mt-3'>
                                            <div className='fw-bold'>{item.service.Service_Name}</div>
                                            <small>{item.content_expert.name} {item.content_expert.lname}  <span className='badge text-dark ms-2' style={{ backgroundColor: '#ABEFC6' }}>{item.service.MentorshipType}</span></small>
                                            <div>
                                                <StarRatings
                                                    rating={item.service.averageRating}
                                                    starRatedColor="#FDB022"
                                                    numberOfStars={5}
                                                /> <span className='ps-2'>{Number(item.service.averageRating).toFixed(1)}</span>
                                            </div>
                                            <div className='d-flex justify-content-between align-items-end h-100 mt-2'>
                                                <div>
                                                    <div><b>Total time:</b> {item.service.Hour}h {item.service.Minutes}m  </div>
                                                </div>
                                            </div>
                                            {item.service.MentorshipType === 'Webinar Session' ?
                                                item.service.started_at === null ? '' :
                                                    <div className='mt-2'><b>Date:</b> {moment(item.service.started_at).format('DD-MM-YYYY')}, <b>Time:</b> {moment(item.service.started_at).format('hh:mm A')}  ({item.service.timezone})</div> : ''}
                                            <div className='fw-bold mt-2'>{data.CurrencySymbol} {Number(item.service.Price).toFixed(2)}</div>
                                        </div>
                                    </div>
                                    <div className='col-lg-3 col-md-4 text-md-end ' style={{ fontSize: '14px' }}>
                                        <div><span className='fw-bold mt-2'>Start Date :</span> {moment(item.service.created_at).format('DD-MM-YYYY')}</div>
                                        {item.service.MentorshipType === 'Webinar Session' ?
                                            <div className='mt-3 text-lg-end'>
                                                <button className='btn w-100 rounded-2'>{item.service.NumberOfParticipant} - Participants </button>
                                            </div> : ''}
                                        <div className='mt-3 text-lg-end'>
                                            <button className='btn btn-orange w-auto rounded-2' onClick={() => props.history.push(`/home/preview/${item.service.Service_id}`)}>View Details  </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }

        } else {
            list.push(<div className='text-center my-5 fw-bold'>Data not available</div>)
        }
        return list

    }
    function loadBookedOnGoingServices() {
        let list = [];
        let fetchitems;
        if (menuKey === 'On-Going') {
            fetchitems = services.filter((value, index) => value.Status === 'Ongoing')
            console.log(fetchitems);
        }
        if (fetchitems.length > 0) {

            for (let i = 0; i < fetchitems.length; i++) {
                let item = fetchitems[i]
                console.log(item);
                list.push(
                    <div key={i} >

                        <div className='mt-3'>
                            <div className='p-3 border rounded-2'>
                                <div className='row'>
                                    <div className='col-md-3 col-12'>
                                        <img src={item.service.ThumbnailImage === null ? '' : `${apiservice.apiImageShow()}/${item.service.ThumbnailImage}`} className='w-100' height={'150px'} alt='...' />
                                    </div>
                                    <div className='col-lg-6 col-md-5  col-12  h-100'>
                                        <div className='mt-lg-0'>
                                            <div className='fw-bold'>{item.service.Service_Name}</div>
                                            <small>{item.content_expert.name} {item.content_expert.lname}  <span className='badge text-dark ms-2' style={{ backgroundColor: '#ABEFC6' }}>{item.service.MentorshipType}</span></small>
                                            <div>
                                                <StarRatings
                                                    rating={item.service.averageRating}
                                                    starRatedColor="#FDB022"
                                                    numberOfStars={5}
                                                /> <span className='ps-2'>{Number(item.service.averageRating).toFixed(1)}</span>
                                            </div>
                                            <div className='d-flex justify-content-between align-items-end h-100 mt-2'>
                                                <div>
                                                    <div><b>Total time:</b> {item.service.Hour}h {item.service.Minutes}m  </div>
                                                </div>
                                            </div>
                                            {item.service.MentorshipType === 'Webinar Session' ?
                                                item.service.started_at === null ? '' :
                                                    <div className='mt-2'><b>Date:</b> {moment(item.service.started_at).format('DD-MM-YYYY')}, <b>Time:</b> {moment(item.service.started_at).format('hh:mm A')}  ({item.service.timezone})</div> : ''}
                                            <div className='fw-bold mt-2'>{data.CurrencySymbol} {Number(item.service.Price).toFixed(2)}</div>
                                        </div>
                                    </div>
                                    <div className='col-lg-3 col-md-4 text-md-end' style={{ fontSize: '14px' }}>
                                        <div><span className='fw-bold mt-2'>Start Date :</span> {moment(item.service.created_at).format('DD-MM-YYYY ')}</div>
                                        {item.service.MentorshipType === 'Webinar Session' ?
                                            <div className='mt-3 text-lg-end'>
                                                <button className='btn w-100 rounded-2'>{item.service.NumberOfParticipant} - Participants </button>
                                            </div> : ''}
                                        <div className='mt-3 text-lg-end'>
                                            <button className='btn btn-orange  w-auto rounded-2' onClick={() => props.history.push(`/home/preview/${item.service.Service_id}`)}>View Details </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        } else {
            list.push(<div className='text-center my-5 fw-bold'>Data not available</div>)
        }
        return list;
    }


    return (
        <>
            <Modal show={data.openCompletedFormPopup} onHide={closePopUp} style={{ marginTop: '200px' }}>
                <ModalBody>
                    <div className='p-3'>
                        <div className='row'>
                            <div className='col-lg-6 col-12'>
                                <div className='mb-3'>
                                    <div className="form-group">
                                        <label htmlFor="">Start date</label>
                                        <input type="date"
                                            className="form-control" name="startDate" value={data.startDate} onChange={inputHandleChange} placeholder="" />
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-12'>
                                <div className='mb-3'>
                                    <div className="form-group">
                                        <label htmlFor="">End date</label>
                                        <input type="date"
                                            className="form-control" name="endDate" value={data.endDate} onChange={inputHandleChange} placeholder="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='mb-3'>
                            <div className="form-group">
                                <label htmlFor="">How is it Completed ?</label>
                                <textarea className="form-control" name="completedText" value={data.completedText} rows="5" onChange={inputHandleChange} placeholder='Text here'></textarea>
                            </div>
                        </div>
                        <div className='mb-3'>
                            <div className='text-center fw-bold'>Is it Completed?</div>
                        </div>
                        <div className='mb-3 d-flex justify-content-center'>
                            <div className="form-check">
                                <label className="form-check-label">
                                    <input type="radio" className="form-check-input" name="isCompleted" value='Yes' onChange={inputHandleChange} checked={data.isCompleted === 'Yes' ? true : false} />
                                    Yes
                                </label>
                            </div>
                            <div className="form-check ms-3">
                                <label className="form-check-label">
                                    <input type="radio" className="form-check-input" name="isCompleted" value='No' onChange={inputHandleChange} checked={data.isCompleted === 'No' ? true : false} />
                                    No
                                </label>
                            </div>
                        </div>
                        <div className='mb-3 d-flex justify-content-center'>
                            <button className='btn border w-50 btn-grey' onClick={closePopUp}> Cancel</button>
                            <button className='btn btn-orange ms-3 w-50' onClick={onClickSubmit}> Submit</button>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
            {isLoading ? <Loader /> :
                <div className='p-3'>
                    <div className=''>
                        <div className='container-fluid'>
                            <div className='fs-3 fw-bold'>All Bookings</div>
                        </div>
                        <div className='mt-3'>
                            <div className='row'>
                                <div className='col-lg-3 col-12 mt-lg-0 mt-2'>
                                    <div className=''>
                                        <button className={menuKey === 'On-Going' ? 'btn btn-light w-100 menu active-menu' : 'btn btn-light w-100 menu'} onClick={() => onClickTopMenu('On-Going')}>On-Going</button>
                                    </div>
                                </div>
                                <div className='col-lg-3 col-6 mt-lg-0 mt-2'>
                                    <div className=''>
                                        <button className={menuKey === 'Upcoming' ? 'btn btn-light w-100 menu active-menu' : 'btn btn-light w-100 menu'} onClick={() => onClickTopMenu('Upcoming')}>Upcoming</button>
                                    </div>
                                </div>
                                <div className='col-lg-3 col-6 mt-lg-0 mt-2'>
                                    <div className=''>
                                        <button className={menuKey === 'Completed' ? 'btn btn-light w-100 menu active-menu' : 'btn btn-light w-100 menu'} onClick={() => onClickTopMenu('Completed')}>Completed</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className='mt-3'>

                        {menuKey === 'On-Going' ?

                            isLoading === true ? <h1 className=' text-center' >Loading...</h1> :
                                <div>
                                    {loadBookedOnGoingServices()}
                                </div>
                            :
                            menuKey === 'Upcoming' ?
                                <div>
                                    {isLoading === true ? <h1 className=' text-center' >Loading...</h1> :
                                        <div>
                                            {loadUpcomingservices()}
                                        </div>
                                    }
                                </div>
                                :
                                <div>
                                    {
                                        isLoading ? <h1 className=' text-center' >Loading ...</h1> :
                                            <div> {loadCompletedServices()} </div>
                                    }
                                </div>
                        }

                    </div>
                </div>}
        </>
    )
}

export default TotalBooking
