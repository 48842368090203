import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import apiservice from '../apiservice/ApiService';


export const getSettingData = createAsyncThunk("setting/getSettingData", async (dataObject) => {
    const response = await apiservice.apiCall('/Admin_Profile', dataObject)
    // console.log(response);
    return response
});
export const updateSetting = createAsyncThunk("setting/updateSetting", async (dataObject) => {
    const { auth, data } = dataObject
    const response = await apiservice.apiCallformData('/Admin_ProfileDeatilsStore', auth, data, 'POST')
    // console.log(response);
    return response
});
export const changePassword = createAsyncThunk("setting/changePassword", async (dataObject) => {
    const { auth, data } = dataObject
    const response = await apiservice.apiCall('/Admin_changepassword', auth, data, 'POST')
    // console.log(response);
    return response
});



const settingSlice = createSlice({
    name: 'setting',
    initialState: {
        isLoading: false,
        data: [],
        isError: false
    },

    extraReducers: (builder) => {
        builder.addCase(getSettingData.pending, (state) => {
            state.isLoading = true;
            state.data = null;
            state.isError = false
        });

        builder.addCase(getSettingData.fulfilled, (state, action) => {
            state.isLoading = false
            state.data = action.payload
            state.isError = null
        });

        builder.addCase(getSettingData.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true;
            state.data = null;
        });


        builder.addCase(updateSetting.pending, (state) => {
            state.isLoading = true;
            state.data = null;
            state.isError = false
        });

        builder.addCase(updateSetting.fulfilled, (state, action) => {
            state.isLoading = false
            state.data = action.payload
            state.isError = null
        });

        builder.addCase(updateSetting.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true;
            state.data = null;
        });


        builder.addCase(changePassword.pending, (state) => {
            state.isLoading = true;
            state.data = null;
            state.isError = false
        });

        builder.addCase(changePassword.fulfilled, (state, action) => {
            state.isLoading = false
            state.data = action.payload
            state.isError = null
        });

        builder.addCase(changePassword.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true;
            state.data = null;
        });


    },
    reducers: {

    },

});

export default settingSlice.reducer;