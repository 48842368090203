import React from 'react'
import { useState } from 'react';

import { Pagination } from 'react-bootstrap';
import './TotalServices.scss'
import StarRatings from 'react-star-ratings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useEffect } from 'react';
import apiservice from '../../apiservice/ApiService';

import left from '../../assets/ArrowLeftShape.png'
import right from '../../assets/ArrowRightShape.png'
import leftLight from '../../assets/ArrowLightLeftShape.png'
import rightLight from '../../assets/ArrowLightRightShape.png'
import moment from 'moment';
import Loader from '../../components/common-components/Loader';
function TotalServices(props) {

    let [services, setServices] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        getReqData()
    }, [])
    async function getReqData() {
        setIsLoading(true)
        let uData = await apiservice.getLocalStorage('userdata')
        console.log(uData);
        try {
            let learnerServices = await apiservice.apiCall('/get_all_services', uData.Token)
            if (learnerServices.unAuthenticated) {
                props.history.push('/')
                localStorage.removeItem('userdata')
            } else if (learnerServices.data) {
                setIsLoading(false)
                console.log(learnerServices.data);
                setServices(learnerServices.data)
            }
            else {
                setIsLoading(false)
                console.log(learnerServices.error);

            }
        } catch (error) {
            setIsLoading(false)
            console.log(error);
        }
    }

    const itemsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1);

    const totalPages = Math.ceil(services.length / itemsPerPage);

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const itemsToDisplay = services.slice(startIndex, endIndex);

    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const pageRangeStart = Math.max(1, currentPage - 2);
    const pageRangeEnd = Math.min(totalPages, pageRangeStart + 2);


    function loadTotalServices() {
        let list = [];
        console.log(itemsToDisplay);


        for (let i = 0; i < itemsToDisplay.length; i++) {
            let item = itemsToDisplay[i]

            list.push(
                <div key={i} >

                    <div className='mt-3'>
                        <div className='p-3 border rounded-2'>
                            <div className='row'>
                                <div className='col-md-4 col-lg-3 col-12'>
                                    <img src={item.ThumbnailImage === null ? '' : `${apiservice.apiImageShow()}/${item.ThumbnailImage}`} width={'100%'} height={'200px'} alt='...' />
                                </div>
                                <div className='col-md-4 col-lg-6 col-12'>
                                    <div className='mt-3'>
                                        <div className='fw-bold'>{item.Service_Name}</div>
                                        <div className='mt-2'>{item.content_expert.name} {item.content_expert.lname} <span className='badge text-dark ms-2' style={{ backgroundColor: '#ABEFC6' }}>{item.MentorshipType}</span></div>
                                        <div className='mt-2'>
                                            <StarRatings
                                                rating={Number(item.averageRating)}
                                                starRatedColor="#FDB022"
                                                numberOfStars={5}
                                            /> <span className='ps-2'>{item.averageRating}</span>
                                        </div>
                                        <div className='d-flex justify-content-between align-items-end h-100'>
                                            <div className=''>
                                                <div className='mt-2'><b>Total time:</b> {item.Hour}h {item.Minutes}m  </div>
                                            </div>
                                        </div>
                                        {item.MentorshipType === 'Webinar Session' ?
                                            item.started_at === null ? '' :
                                                <div className='mt-2'><b>Date:</b> {moment(item.started_at).format('DD-MM-YYYY')}, <b>Time:</b> {moment(item.started_at).format('hh:mm A')}  ({item.timezone})</div> : ''}
                                        <div className='fw-bold mt-2'>{item.CurrencySymbol} {Number(item.Price).toFixed(2)}</div>
                                    </div>
                                </div>
                                <div className='col-md-4 col-lg-3 col-12 ' style={{ fontSize: '14px' }}>
                                    <div className='text-lg-end mt-2' ><span className='fw-bold'>Start Date:</span> {moment(item.created_at).format('DD-MM-YYYY hh:mm A')}</div>
                                    {item.MentorshipType === 'Webinar Session' ?
                                        <div className='mt-3 text-lg-end'>
                                            <button className='btn w-auto rounded-2'>{item.NumberOfParticipant} - Participant's </button>
                                        </div> : ''}
                                    {item.MentorshipType === '1:1 Multi-Session' ?
                                        <div className='mt-3 text-lg-end'>
                                            <button className='btn w-auto rounded-2'>{item.NumberOfSession} - Session's </button>
                                        </div> : ''}
                                    <div className='mt-3 text-lg-end'>
                                        <button className='btn btn-orange w-auto rounded-2' onClick={() => props.history.push(`/home/preview/${item.Service_id}`)}>View Details  </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        return list;
    }


    return (
        <>
            {isLoading ? <Loader /> :
                <div className='p-3'>
                    <div className='container-fluid'>
                        <div className='fs-3 fw-bold'>Total Services</div>
                        <hr />
                    </div>
                    <div className='mt-3'>
                        <div>
                            {loadTotalServices()}
                        </div>
                        <div className="pagination-container mt-3" style={{ display: 'flex', justifyContent: 'center' }}>
                            <Pagination>
                                <Pagination.Prev
                                    className={currentPage === 1 ? "disbaled_Btn" : "prevBtn"}
                                    onClick={handlePreviousPage}
                                    disabled={currentPage === 1 ? true : false}
                                >
                                    <img src={currentPage === 1 ? leftLight : left} alt='...' />
                                </Pagination.Prev>
                                {[...Array(pageRangeEnd - pageRangeStart + 1).keys()].map((offset) => (
                                    <Pagination.Item
                                        key={pageRangeStart + offset}
                                        active={pageRangeStart + offset === currentPage}
                                        onClick={() => handlePageChange(pageRangeStart + offset)}
                                    >
                                        {pageRangeStart + offset}
                                    </Pagination.Item>
                                ))}
                                {pageRangeEnd < totalPages && (
                                    <Pagination.Ellipsis disabled />
                                )}
                                <Pagination.Next
                                    className={currentPage === totalPages ? "disbaled_Btn" : "nextBtn"}
                                    onClick={handleNextPage}
                                    disabled={currentPage === totalPages ? true : false}
                                >
                                    <img src={currentPage === totalPages ? rightLight : right} alt='...' />
                                </Pagination.Next>
                            </Pagination>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default TotalServices
