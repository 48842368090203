import React from 'react'
import logo from '../../../assets/logo.png'
import dashboard from '../../../assets/dashboard.png'
import contentexpert from '../../../assets/content_expert.png'
import notification from '../../../assets/notification.png'
import testimonial from '../../../assets/testimonial.png'
import setting from '../../../assets/setting.png'
import logout from '../../../assets/logout.png'
import reporticon from '../../../assets/report-icon.png'
import handmoney from '../../../assets/Hand Money.png'
import { useState } from 'react'
import { useEffect } from 'react'
import './SidebarPage.scss'
import LogoutModal from '../LogoutModal'
import apiservice from '../../../apiservice/ApiService'

function SidebarPage(props) {
    const [activeMenu, setActiveMenu] = useState('/home')

    const [dashboardCount, setDashboardCount] = useState('')
    useEffect(() => {
        let curUrl = window.location.pathname
        setActiveMenu(curUrl)
        getNotificationCount()
    }, [])
    const onClickMenu = (e) => {
        getNotificationCount()

        if (e === '/') {
            // alert('data')
            localStorage.setItem('isDashboardSearch', false)
        }
        props.history.push(e)
        setActiveMenu(e)
    }

    const getNotificationCount = async () => {
        let userData = await apiservice.getLocalStorage('userdata')
        await apiservice.apiCall('/AdminDashboardCount', userData.Token)
            .then(res => {
                if (res.unAuthenticated) {
                    props.history.push('/')
                    localStorage.removeItem('userdata')
                } else if (res.data) {
                    console.log(res.data);
                    setDashboardCount(res.data)
                } else {
                    console.log(res);
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    const [isLogout, setIsLogout] = useState(false)
    const onClickLogout = () => {
        setIsLogout(true)
    }
    const cancelLogout = () => {
        setIsLogout(false)
    }


    return (
        <>
            <LogoutModal show={isLogout} history={props.history} cancel={cancelLogout} />
            <div className='sidebar-page'>

                <div className='text-start py-5 px-3 px-xl-3'>
                    <img className='pointer' src={logo} alt='' onClick={() => props.history.push('/')} style={{width: '150px'}} />
                </div>
                <div className='mt-3'>
                    <div className={activeMenu === '/home' ? 'd-flex px-4 py-2 mb-4 active-menu pointer' : ' pointer d-flex px-4 py-2 mb-4'} onClick={() => onClickMenu('/')}>
                        <div className='me-2'>
                            <img src={dashboard} alt='...' />
                        </div>
                        <div className=''>Dashboard </div>
                    </div>
                    <div className={activeMenu === '/home/contentexpertrequest' ? 'd-flex px-4 py-2 mb-4 active-menu pointer' : ' pointer d-flex px-4 py-2 mb-4'} onClick={() => onClickMenu('/home/contentexpertrequest')}>
                        <div className='me-2'>
                            <img src={contentexpert} alt='...' />
                        </div>
                        <div className=''>Content Expert Request </div>
                    </div>
                    <div className={activeMenu === '/home/reports' ? 'd-flex px-4 py-2 mb-4 active-menu pointer' : ' pointer d-flex px-4 py-2 mb-4'} onClick={() => onClickMenu('/home/reports')}>
                        <div className='me-2'>
                            <img src={reporticon} alt='...' />
                        </div>
                        <div className=''>Report </div>
                    </div>
                    <div className={activeMenu === '/home/notification' ? 'd-flex px-4 py-2 mb-4 active-menu pointer justify-content-between' : ' pointer d-flex  justify-content-between px-4 py-2 mb-4'} onClick={() => onClickMenu('/home/notification')}>
                        <div className='me-2 d-flex'>
                            <div>
                                <img src={notification} alt='...' />
                            </div>
                            <div className='ms-2'>Notification  </div>
                        </div>
                        {dashboardCount.AdminNotifications !== 0 ?
                            <div className=''><div className='badge bg-secondary ms-2 ms-lg-0'>{dashboardCount.AdminNotifications}</div>  </div> : ''}

                    </div>
                    <div className={activeMenu === '/home/testimonial' ? 'd-flex px-4 py-2 mb-4 active-menu pointer' : ' pointer d-flex px-4 py-2 mb-4'} onClick={() => onClickMenu('/home/testimonial')}>
                        <div className='me-2'>
                            <img src={testimonial} alt='...' />
                        </div>
                        <div className=''>Testimonial </div>
                    </div>
                    <div className={activeMenu === '/home/paymentrequest' ? 'd-flex px-4 py-2 mb-4 active-menu pointer' : ' pointer d-flex px-4 py-2 mb-4'} onClick={() => onClickMenu('/home/paymentrequest')}>
                        <div className='me-2'>
                            <img src={handmoney} alt='...' />
                        </div>
                        <div className=''>Payment Request </div>
                    </div>
                    <div className={activeMenu === '/home/setting' ? 'd-flex px-4 py-2 mb-4 active-menu pointer' : ' pointer d-flex px-4 py-2 mb-4'} onClick={() => onClickMenu('/home/setting')}>
                        <div className='me-2'>
                            <img src={setting} alt='...' />
                        </div>
                        <div className=''>Setting </div>
                    </div>
                    <div className={activeMenu === '/' ? 'd-flex px-4 py-2 mb-4 active-menu pointer' : ' pointer d-flex px-4 py-2 mb-4'} onClick={onClickLogout}>
                        <div className='me-2'>
                            <img src={logout} alt='...' />
                        </div>
                        <div className=''>Log out </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SidebarPage
