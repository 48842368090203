import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useState } from 'react'

import noimage from '../../assets/no-image.png'
import MentorCard from '../../components/common-components/MentorCard/MentorCard'
import StarRatings from 'react-star-ratings'
import moment from 'moment'
import apiservice from '../../apiservice/ApiService'
function SearchResults(props) {
    let searchArr = JSON.parse(props.data)
    const [tabs, setTabs] = useState('Content Expert')
    const onClickTabs = (e) => {
        setTabs(e)
    }

    const [viewShowContentExpertCount, setViewShowContentExpertCount] = useState(6)
    const [viewShowLearnersCount, setViewShowLearnersCount] = useState(6)
    const [viewShowServicesCount, setViewShowServicesCount] = useState(6)

    const listOfContentExpert = () => {
        let list = []
        let dataArr = searchArr && props.type === 'All' ? searchArr.content_expert : searchArr
        if (dataArr.length > 0) {

            for (let index = 0; index < dataArr.length; index++) {
                const data = dataArr[index];
                if (viewShowContentExpertCount >= index + 1) {
                    list.push(
                        <div key={index} className='p-3 border rounded-3 mb-3'>
                            <div className='row'>
                                <div className='col-lg-10 col-12'>
                                    <div className='d-flex'>
                                        <div className=''>
                                            <div className='rounded-circle overflow-hidden' style={{ width: '60px', height: '60px' }}>
                                                <img src={data.ProfileImage === null ? data.SocialImage === null ? data.profile_photo_url : data.SocialImage : `${apiservice.apiImageShow()}/${data.ProfileImage}`} alt='...' className='w-100 h-100' />
                                                {/* <img src={data.ProfileImage === null ? data.SocialImage === '' ? data.profile_photo_url : data.SocialImage : `${apiservice.apiImageShow()}/${data.ProfileImage}`} alt='...' className='w-100 h-100' /> */}
                                            </div>
                                        </div>

                                        <div className='ms-3 '>
                                            <div className='fw-bold'>{data.name} {data.lname}</div>
                                            <div className='text-grey'>{data.lastDesignation}</div>
                                            <div className='d-lg-flex'><div>{data.services === null ? 0 : data.services} Services, </div>  <div className='ms-lg-2'> Total Experience - {data.overallExp === null ? 0 : data.overallExp} Years</div></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-2 col-12'>
                                    <div className='row'>

                                        <div className=' col-12 text-lg-end text-lg-end mt-lg-0 mt-3'>
                                            <button className='btn btn-orange' onClick={() => onClickViewProfile(data)}>View Profile <FontAwesomeIcon className='ms-3' icon={faArrowRight} /></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            }
        } else {
            list.push(
                <div className='text-center mt-5 text-danger'>We don't have any content experts on board as of now. Please promote the platform to acquire our first content expert.</div>
            )
        }
        return list
    }

    const onClickShowMoreContentExpertCount = () => {
        let count = viewShowContentExpertCount
        count = count + 6
        // alert(count)
        setViewShowContentExpertCount(count)
    }
    const onClickViewProfile = async (data) => {

        props.history.push(`/home/contentexpertdetails/${data.content_expert_id}`)
    }

    const listOfLearners = () => {
        let list = []
        let dataArr = searchArr && props.type === 'All' ? searchArr.learners : searchArr
        let count = 0
        if (dataArr.length > 0) {

            for (let index = 0; index < dataArr.length; index++) {
                const data = dataArr[index];
                if (viewShowLearnersCount >= index + 1) {
                    list.push(
                        <div className='col-lg-4 '
                            key={index}
                            onClick={() => onClickLearnerCard(data.id)}>
                            <MentorCard
                                mentorsName={`${data.name === null ? 'Name not available' : data.name} ${data.lname === null ? '' : data.lname}`}
                                mentorsDescription={data.AboutSelf === null ? 'Not available!' : 
                                <div dangerouslySetInnerHTML={{__html:data.AboutSelf}}></div>
                                }
                                mentorsImage={data.ProfileImage === null ? data.SocialImage === null ? data.profile_photo_url : data.SocialImage : `${apiservice.apiImageShow()}/${data.ProfileImage}`}
                                sideBgColor={`cardBg${count}`}
                            />
                        </div>
                    )
                }
                if (count === 5) {
                    count = 0
                } else {
                    count++
                }

            }
        } else {
            list.push(
                <div className='text-center mt-5 text-danger'>We don't have any learners on board as of now. Please promote the platform to acquire our first learner.</div>
            )
        }
        return list
    }

    const onClickShowMoreLearners = () => {
        let count = viewShowLearnersCount
        count = count + 6
        // alert(count)
        setViewShowLearnersCount(count)
    }

    const onClickLearnerCard = (id) => {
        props.history.push(`/home/learnersdetails/${id}`)
        console.log('this is ');
    }


    const listOfServices = () => {
        let list = []
        let dataArr = searchArr && props.type === 'All' ? searchArr.services : searchArr

        if (dataArr.length > 0) {

            for (let index = 0; index < dataArr.length; index++) {
                const data = dataArr[index];
                if (viewShowServicesCount >= index + 1) {
                    list.push(
                        <div key={index} >

                            <div className='mt-3'>
                                <div className='p-3 border rounded-2'>
                                    <div className='row'>
                                        <div className='col-lg-3 col-12'>
                                            <img src={`${apiservice.apiImageShow()}/${data.ThumbnailImage}`} width={'100%'} height={'180px'} alt='...' />
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <div className='mt-lg-0 mt-3'>
                                                <div className='fw-bold'>{data.Service_Name}</div>
                                                <small>{data.content_expert.name} {data.content_expert.lname} <span className='badge text-dark ms-2' style={{ backgroundColor: '#ABEFC6' }}>{data.MentorshipType}</span></small>
                                                <div className='mt-2'>
                                                    <StarRatings
                                                        rating={data.AvgRating}
                                                        starRatedColor="#FDB022"
                                                        numberOfStars={5}
                                                    /> <span className='ps-2'>{Number(data.AvgRating).toFixed(1)}</span>
                                                </div>
                                                <div className='d-flex justify-content-between align-items-end h-100 mt-2'>
                                                    <div>
                                                        <div><b>Total time:</b> {data.Hour}h {data.Minutes}m  </div>
                                                    </div>
                                                </div>
                                                {data.MentorshipType === 'Webinar Session' ?
                                                    data.started_at === null ? '' :
                                                        <div className='mt-2'><b>Date:</b> {moment(data.started_at).format('DD-MM-YYYY')}, <b>Time:</b> {moment(data.started_at).format('hh:mm A')}  ({data.timezone})</div> : ''}
                                                <div className='fw-bold mt-2'>{data.CurrencySymbol} {Number(data.Price).toFixed(2)}</div>

                                            </div>
                                        </div>
                                        <div className='col-lg-3 col-12 text-lg-end' style={{ fontSize: '14px' }}>
                                            <div><span className='fw-bold'>Start Date:</span> {moment(data.created_at).format('DD-MM-YYYY hh:mm A')}</div>
                                            {data.MentorshipType === 'Webinar Session' ?
                                                <div className='mt-3'>
                                                    <button className='btn w-auto rounded-2'>{data.NumberOfParticipant} - Participants </button>
                                                </div> : ''}
                                            <div className='mt-3'>
                                                <button className='btn btn-orange w-auto rounded-2' onClick={() => props.history.push(`/home/preview/${data.Service_id}`)}>View Details <FontAwesomeIcon className='ms-3' icon={faArrowRight} /> </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            }
        } else {
            list.push(
                <div className='text-center mt-5 text-danger'>We don't have any services listed on the platform. First, onboard the content expert and encourage them to add the first service to the platform.</div>
            )
        }
        return list
    }

    const onClickShowMoreServices = () => {
        let count = viewShowServicesCount
        count = count + 6
        // alert(count)
        setViewShowServicesCount(count)
    }

    return (
        <>
            {
                props.type === 'All' ?
                    <div className='mt-5'>
                        <div className='d-flex'>
                            <div className={tabs === "Content Expert" ? 'col text-center fw-bold border border-dark p-2 mx-2 rounded' : 'col text-center border p-2 mx-2 rounded'} onClick={() => onClickTabs('Content Expert')}>Content Expert</div>
                            <div className={tabs === "Learners" ? 'col text-center fw-bold border border-dark p-2 mx-2 rounded' : 'col text-center border p-2 mx-2 rounded'} onClick={() => onClickTabs('Learners')}>Learners</div>
                            <div className={tabs === "Services" ? 'col text-center fw-bold border border-dark p-2 mx-2 rounded' : 'col text-center border p-2 mx-2 rounded'} onClick={() => onClickTabs('Services')}>Services</div>
                        </div>
                        <div className='mt-3'>
                            {tabs === 'Content Expert' ?
                                <div>
                                    {listOfContentExpert()}


                                    <div className='text-center mt-5'>
                                        {viewShowContentExpertCount >= searchArr.content_expert.length ? '' :
                                            <button className='btn btn-orange' onClick={onClickShowMoreContentExpertCount}>Show more</button>}
                                    </div>
                                </div>
                                :
                                tabs === 'Learners' ?
                                    <div className='row m-0'>
                                        {listOfLearners()}


                                        <div className='text-center mt-5'>
                                            {viewShowLearnersCount >= searchArr.learners.length ? '' :
                                                <button className='btn btn-orange' onClick={onClickShowMoreLearners}>Show more</button>}
                                        </div>
                                    </div>
                                    :
                                    <div>
                                        {listOfServices()}

                                        <div className='text-center mt-5'>
                                            {viewShowServicesCount >= searchArr.services.length ? '' :
                                                <button className='btn btn-orange' onClick={onClickShowMoreServices}>Show more</button>}
                                        </div>
                                    </div>}
                        </div>
                    </div>
                    :
                    props.type === 'Content Expert' ?
                        <div>
                            {listOfContentExpert()}
                            <div className='text-center mt-5'>
                                {viewShowContentExpertCount >= searchArr.length ? '' :
                                    <button className='btn btn-orange' onClick={onClickShowMoreContentExpertCount}>Show more</button>}
                            </div>
                        </div>
                        :
                        props.type === 'Learners' ?
                            <div className='row'>
                                {listOfLearners()}
                                <div className='text-center mt-5'>
                                    {viewShowLearnersCount >= searchArr.length ? '' :
                                        <button className='btn btn-orange' onClick={onClickShowMoreLearners}>Show more</button>}
                                </div>
                            </div>
                            :
                            <div>
                                {listOfServices()}

                                <div className='text-center mt-5'>
                                    {viewShowServicesCount >= searchArr.length ? '' :
                                        <button className='btn btn-orange' onClick={onClickShowMoreServices}>Show more</button>}
                                </div>
                            </div>
            }
        </>
    )
}

export default SearchResults
