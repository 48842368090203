import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect } from 'react'
import "./TotalLearners.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { useState } from 'react';
// import '../../components/MentorsComponents/MentorCard/MentorCard.scss';
import noimage from '../../assets/no-image.png'
import left from '../../assets/ArrowLeftShape.png'
import right from '../../assets/ArrowRightShape.png'
import leftLight from '../../assets/ArrowLightLeftShape.png'
import rightLight from '../../assets/ArrowLightRightShape.png'
import { Pagination } from 'react-bootstrap';
import MentorCard from '../../components/common-components/MentorCard/MentorCard';
import apiservice from '../../apiservice/ApiService';
import Loader from '../../components/common-components/Loader';

function TotalLearners(props) {

    const [learnersArr, setLearnersArr] = useState([])
    const [searchValue, setSearchValue] = useState('')
    const [searchResult, setSearchResult] = useState([])
    const [isSearch, setIsSearch] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        getLearnesData()
    }, [])

    const getLearnesData = async () => {
        setIsLoading(true)
        let udata = await apiservice.getLocalStorage('userdata')
        console.log(udata);
        await apiservice.apiCall('/get_learners', udata.Token,).then(res => {
            console.log(res);
            if (res.unAuthenticated) {
                props.history.push('/')
                localStorage.removeItem('userdata')
            } else if (res.data) {
                setIsLoading(false)
                setLearnersArr(res.data)
            } else {
                setIsLoading(false)
                console.log(res);
            }
        }).catch(err => {
            setIsLoading(false)
            console.log(err);
        })
    }



    const onClickLearnerCard = (id) => {
        props.history.push(`/home/learnersdetails/${id}`)
        console.log('this is ');
    }

    let dataArray = searchResult.length > 0 ? searchResult : learnersArr

    const itemsPerPage = 9;
    const [currentPage, setCurrentPage] = useState(1);

    const totalPages = Math.ceil(dataArray.length / itemsPerPage);

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const itemsToDisplay = dataArray.slice(startIndex, endIndex);

    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const pageRangeStart = Math.max(1, currentPage - 2);
    const pageRangeEnd = Math.min(totalPages, pageRangeStart + 2);

    const learnerList = () => {
        let array = itemsToDisplay
        let list = []
        let count = 0
        for (let index = 0; index < array.length; index++) {
            let data = array[index]
            list.push(
                <div className='col-lg-4 '
                    key={index}
                    onClick={() => onClickLearnerCard(data.id)}>
                    <MentorCard
                        mentorsName={`${data.name === null ? 'Name not available' : data.name} ${data.lname === null ? '' : data.lname}`}
                        mentorsDescription={data.AboutSelf === null ? 'Not available!' :
                            <div dangerouslySetInnerHTML={{ __html: data.AboutSelf }}></div>
                        }
                        mentorsImage={data.ProfileImage === null ? data.SocialImage === null ? data.profile_photo_url : data.SocialImage : `${apiservice.apiImageShow()}/${data.ProfileImage}`}
                        sideBgColor={`cardBg${count}`}
                    />
                </div>
            )
            if (count === 5) {
                count = 0
            } else {
                count++
            }

        }
        return list
    }

    const onClickSearch = async () => {
        setIsLoading(true)
        let udata = await apiservice.getLocalStorage('userdata')
        let dataObject = {
            keywords: searchValue
        }
        console.log(dataObject);
        await apiservice.apiCall('/search_learners', udata.Token, dataObject, 'POST').then(res => {
            console.log(res.data);
            if (res.unAuthenticated) {
                props.history.push('/')
                localStorage.removeItem('userdata')
            } else if (res.data) {
                setIsLoading(false)
                console.log(res.data);
                setIsSearch(true)
                setSearchResult(res.data)
            } else {
                setIsLoading(false)
                console.log(res);
            }
        }).catch(err => {
            setIsLoading(false)
            console.log(err);
        })
    }
    const onChangeSearchValue = (e) => {
        let value = e.target.value
        setIsSearch(false)
        if (e.target.value.length > 0) {
            setSearchResult([])
        }
        setSearchValue(value)
    }
    return (
        <>{
            isLoading ? <Loader /> :

                <div>
                    <div className='p-3'>
                        <div className='fs-3 fw-bold'>Learners</div>
                        <div className='mt-3'>
                            <div className="col-lg-8 col-12">
                                <div className="input-group mb-3 border rounded-3 border-dark ">
                                    <input type="text" onChange={onChangeSearchValue}
                                        className="form-control border-0 bg-transparent" value={searchValue} placeholder='Search Learner' />
                                    <span className="input-group-text border-0 bg-transparent">
                                        <button className='btn btn-search-btn-blue' onClick={onClickSearch}>
                                            <FontAwesomeIcon icon={faSearch} className='me-2' /> Search
                                        </button>
                                    </span>
                                </div>
                            </div>
                        </div>


                        <div>

                            <div className='mt-3'>
                                {isSearch ?
                                    <div>
                                        {searchResult.length > 0 ?
                                            <div className='row flex-wrap'>
                                                {learnerList()}
                                            </div>
                                            :
                                            <div className='mt-5 fw-bold text-center'>
                                                Data not found!
                                            </div>
                                        }
                                    </div>
                                    :
                                    <div>
                                        <div className=' row flex-wrap'>
                                            {learnerList()}
                                        </div>
                                        <div className="pagination-container" style={{ display: 'flex', justifyContent: 'center' }}>
                                            <Pagination>
                                                <Pagination.Prev
                                                    className={currentPage === 1 ? "disbaled_Btn" : "prevBtn"}
                                                    onClick={handlePreviousPage}
                                                    disabled={currentPage === 1 ? true : false}
                                                >
                                                    <img src={currentPage === 1 ? leftLight : left} alt='...' />
                                                </Pagination.Prev>
                                                {[...Array(pageRangeEnd - pageRangeStart + 1).keys()].map((offset) => (
                                                    <Pagination.Item
                                                        key={pageRangeStart + offset}
                                                        active={pageRangeStart + offset === currentPage}
                                                        onClick={() => handlePageChange(pageRangeStart + offset)}
                                                    >
                                                        {pageRangeStart + offset}
                                                    </Pagination.Item>
                                                ))}
                                                {pageRangeEnd < totalPages && (
                                                    <Pagination.Ellipsis disabled />
                                                )}
                                                <Pagination.Next
                                                    className={currentPage === totalPages ? "disbaled_Btn" : "nextBtn"}
                                                    onClick={handleNextPage}
                                                    disabled={currentPage === totalPages ? true : false}
                                                >
                                                    <img src={currentPage === totalPages ? rightLight : right} alt='...' />
                                                </Pagination.Next>
                                            </Pagination>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
        }
        </>
    )
}

export default TotalLearners
