import React from 'react'

function Error404Page() {
  return (
    <>
      <div className='p-3 border border-danger rounded-5 position-absolute top-0 bottom-0 start-0 end-0 m-auto' style={{height:'150px', width:'250px'}}>
        <div className='text-center text-danger'>
            <div className='fs-1 fw-bold'>404</div>
            <div className='fw-bold'>Page not Found!</div>
        </div>
      </div>
    </>
  )
}

export default Error404Page
