import React, { useEffect } from "react";
import { useState } from "react";
import "./PreviewPage.scss";
import StarRatings from "react-star-ratings";
import nextBtn from '../../assets/nextBtnCircle.png'
import prevBtn from '../../assets/prevBtnIcon.png'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleChevronRight, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { HiArrowLongRight } from "react-icons/hi2";
import ratingStar from "../../assets/ratingStarSmall.png";
import mentorAtServiceDetail from "../../assets/mentorAtServiceDetail.png";
import mentorImg from "../../assets/mentorImg.png"
import noImg from '../../assets/no-image.png'
import apiservice from "../../apiservice/ApiService";
import ReactPlayer from "react-player";
import noimage from '../../assets/no-image.png'
import moment from "moment";
import Loader from "../../components/common-components/Loader";


function PreviewPage(props) {
  const initialDataState = {
    resourcesFile: "",
    ratings: 0,
    comments: ''
  };
  const [data, setData] = useState(initialDataState);
  let [mentorData, setMentorsData] = useState({})
  let [serviceData, setServiceData] = useState({})
  let [services, setService] = useState([])
  let [userData, setUserdata] = useState('')
  const [currentStep, setCurrentStep] = useState("step1");
  const [questionNumber, setQuestionNumber] = useState(null);
  const avgValue = "25%";
  const [viewShowCount, setViewShowCount] = useState(3)
  const [isLoading, setIsLoading] = useState(false)

  async function getMentorsData() {
    setIsLoading(true)
    let id = props.match.params.id
    await apiservice.apiCall(`/ServiceDetails/${id}`).then(res => {
      console.log(res.data);
      if (res.unAuthenticated) {
        props.history.push('/')
        localStorage.removeItem('userdata')
      } else if (res.data) {
        setIsLoading(false)
        setServiceData(res.data)
        getContentExpertData(res.data.Content_expertid)
      } else {
        setIsLoading(false)
        console.log(res);
      }
    }).catch(err => {
      setIsLoading(false)
      console.log(err);
    })

  }

  async function getContentExpertData(id) {
    setIsLoading(true)
    await apiservice.apiCall(`/ContentExpertDetails/${id}`).then(res => {
      console.log(res.data);
      if (res.unAuthenticated) {
        props.history.push('/')
        localStorage.removeItem('userdata')
      } else if (res.data) {
        setIsLoading(false)
        setMentorsData(res.data[0])
      } else {
        setIsLoading(false)
        console.log(res);
      }
    }).catch(err => {
      setIsLoading(false)
      console.log(err);
    })

  }

  useEffect(() => {
    getMentorsData()

  }, [])

  function loadQA() {
    let list = [];
    let QAs = serviceData.QueAns === null ? [] : JSON.parse(serviceData.QueAns)
    console.log(QAs);
    for (let i = 0; i < QAs.length; i++) {
      let item = QAs[i]
      console.log(item);

      list.push(
        <div key={i} className="accordion-item bg-transparent">
          <h2 className="accordion-header bg-transparent" id={"headingOne" + i}>
            <button onClick={() => onClickQuetions(i)} className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#collapseOne" + i} aria-expanded="true" aria-controls={"collapseOne" + i}>
              <div className='p-lg-2 col-1'>
                <div className={questionNumber === i ? 'rounded-circle  text-center active-circle  px-2 pt-2 mt-2' : 'quetionbtn rounded-circle px-2 pt-2 mt-2 text-center '} style={{ width: '35px', height: '35px' }}>
                  {i + 1}
                </div>
              </div>
              <div className='p-2 col-10 '>
                <div className='mx-3 pt-2'>{item.question}</div>

              </div>

            </button>
          </h2>
          <div id={"collapseOne" + i} className="accordion-collapse collapse bg-transparent " aria-labelledby={"headingOne" + i} data-bs-parent="#accordionExample">
            <div className="accordion-body bg-transparent desc" dangerouslySetInnerHTML={{__html:item.answer}}/>
          </div>
        </div>
      )
      // list.push(
      //   <div key={i} className="mt-3">
      //     <div
      //       className="accordion bg-transparent"
      //       id={`accordionExample${i + 1}`}
      //     >
      //       <div className="accordion-item bg-transparent">
      //         <h2
      //           className="accordion-header bg-transparent"
      //           id={`headingOne${i + 1}`}
      //         >
      //           <button
      //             onClick={() => onClickQuetions(i + 1)}
      //             className="accordion-button border-0 collapsed"
      //             type="button"
      //             data-bs-toggle="collapse"
      //             data-bs-target={`#collapse${i + 1}`}
      //             aria-expanded="true"
      //             aria-controls={`collapse${i + 1}`}
      //           >
      //             <div className="d-flex">
      //               <div
      //                 className={
      //                   questionNumber === { i } + 1
      //                     ? "rounded-circle pt-1 text-center active-circle"
      //                     : "quetionbtn rounded-circle pt-1 text-center "
      //                 }
      //                 style={{
      //                   width: "30px",
      //                   height: "30px",
      //                 }}
      //               >
      //                 {i + 1}
      //               </div>
      //               <div className="ms-3 pt-2">
      //                 {item.question}

      //               </div>
      //             </div>
      //           </button>
      //         </h2>
      //         <div
      //           id={`collapse${i + 1}`}
      //           className="accordion-collapse collapse bg-transparent"
      //           aria-labelledby={`headingOne${i + 1}`}
      //           data-bs-parent={`#accordionExample${i + 1}`}
      //         >
      //           <div className="accordion-body bg-transparent">
      //             {item.answer}
      //           </div>
      //         </div>
      //       </div>

      //     </div>
      //   </div>
      // )
    }
    return list;
  }





  const formSteps = (e) => {
    setCurrentStep(e);
  };

  const courseCurriculumFn = () => {
    let list = [];
    let chapters = JSON.parse(serviceData.Chapter);
    for (let index = 0; index < chapters.length; index++) {
      let item = chapters[index]
      console.log(item);
      list.push(
        <div key={index} className="p-2 mt-2 bg-light rounded-1">
          <div className="d-flex justify-content-between">
            <div className="fw-bold">{item.chapterTitle}</div>
            <div>{item.sessionDuration} {item.sessionDuration === '1' ? 'hr' : 'min'} </div>
          </div>
        </div>
      );
    }
    return list;
  };

  const onClickQuetions = (e) => {
    setQuestionNumber(e);
  };


  const onClickKnowMore = (data) => {
    console.log(data.Content_expertid);
    let id = data.content_expert_id
    props.history.push(`/home/contentexpertdetails/${id}`)
  }

  const listOfReviews = () => {
    let list = []
    let dataArr = serviceData.ReviewsList && serviceData.ReviewsList
    console.log(dataArr.length);
    if (dataArr.length > 0) {
      for (let index = 0; index < dataArr.length; index++) {
        const data = dataArr[index];

        if (viewShowCount >= index + 1) {
          list.push(
            <div key={index} className="border-bottom py-3">
              <div className="d-flex align-items-center py-2 mb-3">
                <div className="rounded-circle overflow-hidden" style={{ width: '70px', height: '70px' }}>

                  <img src={data.learner.ProfileImage === null ? data.learner.SocialImage === null ? noimage : data.learner.SocialImage : `${apiservice.apiImageShow()}/${data.learner.ProfileImage}`} width={'100%'} height={'100%'} alt="" />
                </div>
                <div className="px-3">
                  <span className="reviewerName">{data.learner.name}</span>
                  <br />
                  <StarRatings
                    rating={+data.Ratting}
                    starDimension='20px'
                    starRatedColor="#FDB022"
                    numberOfStars={5}
                  />
                </div>
              </div>
              <p>
                {data.comment}
              </p>
            </div>
          )
        }
      }
    } else {
      list.push(
        <div className='fw-bold mt-3'>no review found yet!</div>
      )
    }

    return list

  }

  const onClickShowMore = () => {
    let count = viewShowCount
    count = count + 3
    // alert(count)
    setViewShowCount(count)

  }
  return (
    <>
      {isLoading ? <Loader /> :
        <section style={{ backgroundColor: "#F1EFE7" }}>
          <article className="container">
            <div className="py-3">
              <div className="mt-3">
                <div className="row">
                  <div className="col-lg-8 col-12">
                    <div className="">
                      <div className="d-flex">
                        <div className="fs-5 fw-bold">
                          {serviceData.Service_Name}
                        </div>

                      </div>
                      <div className="mt-2">
                        {serviceData.Service_Description}
                      </div>
                    </div>
                    <div className="fw-bold mt-2">{mentorData.name} {mentorData.lname}</div>
                    <div className="mt-2">
                      <StarRatings
                        rating={serviceData.AvgRating}
                        starRatedColor="#FDB022"
                        numberOfStars={5}
                      /> <span className='ps-2'>{serviceData.AvgRating === 0 ? 0 : Number(serviceData.AvgRating).toFixed(1)}</span>
                    </div>
                    <div className="d-lg-flex justify-content-between align-items-center w-100">
                      <div className="d-lg-flex d-block align-items-center">


                        <div className='d-flex mt-3'>
                          <div className='px-1' style={{ backgroundColor: '#ABEFC6' }} > {serviceData.MentorshipType} </div>
                          <div className='mx-2'>•</div>
                          <div><b>Total Time:</b> {serviceData.Hour}h {serviceData.Minutes}m  </div>

                        </div>
                      </div>
                      {serviceData.MentorshipType === 'Webinar Session' ?
                        serviceData.started_at === null ? '' :
                          <div className='mt-3'><b>Date:</b> {moment(serviceData.started_at).format('DD-MM-YYYY')}, <b>Time:</b> {moment(serviceData.started_at).format('hh:mm A')}  ({serviceData.timezone})</div> : ''}


                    </div>


                    <hr />
                    {/* ......................................................................................................................... */}

                    <div className="p-lg-3">
                      <div className="container-fluid">
                      </div>
                      <div className="mt-3">
                        <div className="row">
                          <div className="col-lg-3 col-6 p-1">
                            {" "}
                            <div type='button'
                              className={
                                currentStep === "step1"
                                  ? "p-lg-3 p-2 fw-bold active-form-step1 text-center rounded "
                                  : "p-lg-3 p-2 bg-light fw-bold text-center rounded"
                              }
                              onClick={() => formSteps("step1")}
                            >
                              Overview
                            </div>
                          </div>
                          <div className="col-lg-3 col-6 p-1">
                            {" "}
                            <div type='button'
                              className={
                                currentStep === "step2"
                                  ? "p-lg-3 p-2 fw-bold active-form-step2 text-center rounded"
                                  : "p-lg-3 p-2 bg-light fw-bold text-center rounded"
                              }
                              onClick={() => formSteps("step2")}
                            >
                              Content
                            </div>
                          </div>
                          <div className="col-lg-3 col-6 p-1">
                            {" "}
                            <div type='button'
                              className={
                                currentStep === "step3"
                                  ? "p-lg-3 p-2 fw-bold active-form-step3 text-center rounded"
                                  : "p-lg-3 p-2 bg-light fw-bold text-center rounded"
                              }
                              onClick={() => formSteps("step3")}
                            >
                              Content Expert
                            </div>
                          </div>
                          <div className="col-lg-3 col-6 p-1">
                            {" "}
                            <div type='button'
                              className={
                                currentStep === "step4"
                                  ? "p-lg-3 p-2 fw-bold active-form-step4 text-center rounded"
                                  : "p-lg-3 p-2 bg-light fw-bold text-center rounded"
                              }
                              onClick={() => formSteps("step4")}
                            >
                              Q&A
                            </div>
                          </div>
                          <div className="col-lg-3 col-6 p-1">
                            {" "}
                            <div type='button'
                              className={
                                currentStep === "step5"
                                  ? "p-lg-3 p-2 fw-bold active-form-step5 text-center rounded"
                                  : "p-lg-3 p-2 bg-light fw-bold text-center rounded"
                              }
                              onClick={() => formSteps("step5")}
                            >
                              Reviews
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mt-5">
                        {/* Form Step 1 */}
                        {currentStep === "step1" ? (
                          <div className="mt-3">
                            <div className="mb-5">
                              <div dangerouslySetInnerHTML={{ __html: serviceData.Overview }} />
                            </div>

                          </div>
                        ) : /* Form Step 2 */
                          currentStep === "step2" ? (
                            <div className="mb-5">
                              <div className="fw-bold">Service Content</div>
                              <div dangerouslySetInnerHTML={{ __html: serviceData.Content_Description }} />
                              <div className="">
                                {courseCurriculumFn()}
                                {/* {serviceData.Content_Description} */}
                              </div>
                            </div>
                          ) : /* Form Step 4 */
                            currentStep === "step4" ? (
                              <div className="mb-5">
                                <div className="fw-bold">Questions and Answers</div>
                                <div className="accordion bg-transparent" id="accordionExample">
                                  {loadQA()}
                                </div>
                              </div>
                            ) : /* Form Step 3 */
                              currentStep === "step3" ? (
                                <div className="mb-5">
                                  <div className=" col-12">
                                    <div className=" d-flex align-items-center ">
                                      <img width={70} height={70} src={mentorData.ProfileImage === null ? mentorData.SocialImage === null ? noimage : mentorData.SocialImage : `${apiservice.apiImageShow()}/${mentorData.ProfileImage}`} className=" rounded-circle" alt="" />
                                      <div>
                                        <div className="ms-3">
                                          <h5>{mentorData.name} {mentorData.lname}</h5>

                                          <div className="w-100 ">
                                            <button
                                              type="button"
                                              onClick={() => onClickKnowMore(mentorData)}
                                              className="btn btn-primary text-center border-0"
                                              style={{ backgroundColor: "#E57A4D" }}
                                            >
                                              Know More
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                  </div>
                                </div>
                              ) : (
                                /* Form Step 5 */
                                <div className="mb-5">
                                  <div className="row m-0">
                                    <div className="col-xl-5 col-12  p-0" style={{ height: "inherite" }}>
                                      <div className="card bg-transparent justify-content-center me-lg-2 mb-2">
                                        <div className="card-body text-center align-items-center">
                                          <h1
                                            className="text-center fw-semibold"
                                            style={{ fontSize: "4.375rem" }}
                                          >
                                            {serviceData.AvgRating}
                                          </h1>
                                          <StarRatings
                                            rating={+serviceData.AvgRating}
                                            starDimension={'20px'}
                                            starRatedColor="#FDB022"
                                            numberOfStars={5}
                                          />
                                          <p className="py-2">{serviceData.AvgRatingCount} Ratings</p>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-xl-7 col-12 card bg-transparent mt-lg-0 mt-3 mb-2">
                                      <div className=" d-flex flex-column justify-content-evenly align-content-between p-3 px-lg-2">
                                        <div className="row align-items-center">
                                          <div className="col-md-2 col-3 ">
                                            <div className="d-flex">
                                              <span className="px-2">5</span>
                                              <img
                                                src={ratingStar}
                                                className=""
                                                width={20}
                                                height={20}
                                                alt=""

                                              />
                                            </div>
                                          </div>
                                          <div className="progress p-0 col-md-8 col-7 bg-white">
                                            <div
                                              className="progress-bar"
                                              role="progressbar"
                                              style={{
                                                width: `${serviceData.SeparateReviewsList.fivePercent}%`,
                                                backgroundColor: "pink",
                                              }}
                                              aria-valuenow="30"
                                              aria-valuemin="0"
                                              aria-valuemax="100"
                                            ></div>
                                          </div>
                                          <div className="col-2">{serviceData.SeparateReviewsList.five}</div>
                                        </div>

                                        <div className="row align-items-center">
                                          <div className="col-md-2 col-3 ">
                                            <div className="d-flex">
                                              <span className="px-2">4</span>
                                              <img
                                                src={ratingStar}
                                                className=""
                                                width={20}
                                                height={20}
                                                alt=""
                                              /></div>
                                          </div>
                                          <div className="progress p-0 col-md-8 col-7 bg-white">
                                            <div
                                              className="progress-bar "
                                              role="progressbar"
                                              style={{
                                                width: `${serviceData.SeparateReviewsList.fourPercent}%`,
                                                backgroundColor: "#ADD6DA",
                                              }}
                                              aria-valuenow="30"
                                              aria-valuemin="0"
                                              aria-valuemax="100"
                                            ></div>
                                          </div>
                                          <div className="col-2">{serviceData.SeparateReviewsList.four}</div>
                                        </div>

                                        <div className="row align-items-center">
                                          <div className="col-md-2 col-3">
                                            <div className="d-flex">
                                              <span className="px-2">3</span>
                                              <img
                                                src={ratingStar}
                                                className=""
                                                width={20}
                                                height={20}
                                                alt=""
                                              /></div>
                                          </div>
                                          <div className="progress p-0 col-md-8 col-7 bg-white">
                                            <div
                                              className="progress-bar "
                                              role="progressbar"
                                              style={{
                                                width: `${serviceData.SeparateReviewsList.threePercent}%`,
                                                backgroundColor: "#0d98ba",
                                              }}
                                              aria-valuenow="30"
                                              aria-valuemin="0"
                                              aria-valuemax="100"
                                            ></div>
                                          </div>
                                          <div className="col-2">{serviceData.SeparateReviewsList.three}</div>
                                        </div>

                                        <div className="row align-items-center">
                                          <div className="col-md-2 col-3">
                                            <div className="d-flex">
                                              <span className="px-2">2</span>
                                              <img
                                                src={ratingStar}
                                                className=""
                                                width={20}
                                                height={20}
                                                alt=""
                                              /></div>
                                          </div>
                                          <div className="progress p-0 col-md-8 col-7 bg-white">
                                            <div
                                              className="progress-bar "
                                              role="progressbar"
                                              style={{
                                                width: `${serviceData.SeparateReviewsList.twoPercent}%`,
                                                backgroundColor: "#d9d6fe",
                                              }}
                                              aria-valuenow="30"
                                              aria-valuemin="0"
                                              aria-valuemax="100"
                                            ></div>
                                          </div>
                                          <div className="col-2">{serviceData.SeparateReviewsList.two}</div>
                                        </div>

                                        <div className="row align-items-center">
                                          <div className="col-md-2 col-3">
                                            <div className="d-flex">
                                              <span className="px-2">1</span>
                                              <img
                                                src={ratingStar}
                                                className="ms-1 "
                                                width={20}
                                                height={20}
                                                alt=""
                                              /></div>
                                          </div>
                                          <div className="progress p-0 col-md-8 col-7 bg-white">
                                            <div
                                              className="progress-bar "
                                              role="progressbar"
                                              style={{
                                                width: `${serviceData.SeparateReviewsList.onePercent}%`,
                                                backgroundColor: "#0d98ba",
                                              }}
                                              aria-valuenow="30"
                                              aria-valuemin="0"
                                              aria-valuemax="100"
                                            ></div>
                                          </div>
                                          <div className="col-2">{serviceData.SeparateReviewsList.one}</div>
                                        </div>

                                      </div>
                                    </div>
                                  </div>
                                  <h1 className="fw-semibold mt-4">Services Reviews</h1>

                                  {listOfReviews()}




                                  {viewShowCount >= serviceData.ReviewsList.length ? '' :
                                    <div className="w-100 text-center py-3 my-2">
                                      <button
                                        type="button"
                                        className="btn btn-primary border-0 text-center"
                                        style={{ backgroundColor: "#E57A4D" }}
                                        onClick={onClickShowMore}
                                      >
                                        Show More <HiArrowLongRight />
                                      </button>
                                    </div>
                                  }
                                </div>
                              )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-12">
                    <div>
                      <div className="card p-2 bg-transparent">
                        <div>
                          {serviceData.UploadVideo === null ?
                            <div>
                              <img src={`${apiservice.apiImageShow()}/${serviceData.ThumbnailImage}`} alt="..." className="w-100" />
                            </div> :
                            <ReactPlayer
                              controls
                              width='100%'
                              height='100%'
                              playing
                              light={<img width='100%' height='100%' src={`${apiservice.apiImageShow()}/${serviceData.ThumbnailImage}`} alt='Thumbnail' />}
                              url={`${apiservice.apiImageShow()}/${serviceData.UploadVideo}`} />

                          }     </div>
                        <div className="card-body">
                          <small className="fw-bold">
                            {serviceData.Service_Name}
                          </small>
                          <br />
                          <small className="">
                            "Enroll in our courses led by our esteemed Content Expert to hone your skills and achieve your goals. Let's embark on a journey of learning and growth together with <b>{mentorData.name} {mentorData.lname}</b>."
                          </small>
                          <div className="fw-bold">{serviceData.CurrencySymbol} {Number(serviceData.Price).toFixed(2)}</div>

                        </div>
                      </div>
                    </div>
                    <div className="mt-3">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </section>
      }
    </>
  );
}

export default PreviewPage;
