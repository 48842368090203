import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom/cjs/react-router-dom'
import LoginPage from './pages/LoginPage/LoginPage'
import OTPPage from './pages/OTPPage/OTPPage'
import Error404Page from './components/common-components/Error404Page/Error404Page'
import ResetPasswordPage from './pages/ResetPasswordPage/ResetPasswordPage'
import ForgotPasswordPage from './pages/ForgotPassword/ForgotPasswordPage'
import HomePage from './pages/HomePage/HomePage'
function App() {


  return (
    <>
      <Router>
        <Switch>
          <Route path="/resetpassword" component={ResetPasswordPage} />
          <Route path="/forgotpassword" component={ForgotPasswordPage} />
          <Route path="/otpverification" component={OTPPage} />
          <Route path="/home" component={HomePage} />
          <Route path="/" component={LoginPage} />
          <Route component={Error404Page} />
        </Switch>
      </Router>

    </>
  )
}

export default App
