import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import apiservice from '../apiservice/ApiService';


export const getAllTestimonial = createAsyncThunk("testimonial/getAllTestimonial", async (dataObject) => {
    const response = await apiservice.apiCall('/Testimonal', dataObject)
    return response
});
export const createTestimonial = createAsyncThunk("testimonial/createTestimonial", async (dataObject) => {
    const { auth, data } = dataObject
    const response = await apiservice.apiCallformData('/Testimonalstore', auth, data, 'POST')
    return response
});
export const editTestimonialData = createAsyncThunk("testimonial/editTestimonialData", async (dataObject) => {
    return dataObject
});
export const updateTestimonial = createAsyncThunk("testimonial/updateTestimonial", async (dataObject) => {
    console.log(dataObject);
    const { auth, data, id } = dataObject
    const response = await apiservice.apiCallformData(`/Testimonalupdate/${id}`, auth, data, 'POST')
    return response
});
export const deleteTestimonial = createAsyncThunk("testimonial/deleteTestimonial", async (dataObject) => {
    console.log(dataObject);
    const { auth, data, id } = dataObject
    const response = await apiservice.apiCall(`/Testimonaldelete/${id}`, auth, data, 'POST')
    return response
});


const testimonialSlice = createSlice({
    name: 'testimonial',
    initialState: {
        isLoading: false,
        data: [],
        isError: false
    },

    extraReducers: (builder) => {
        builder.addCase(getAllTestimonial.pending, (state) => {
            state.isLoading = true;
            state.data = null;
            state.isError = false
        });

        builder.addCase(getAllTestimonial.fulfilled, (state, action) => {
            state.isLoading = false
            state.data = action.payload
            state.isError = null
        });

        builder.addCase(getAllTestimonial.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true;
            state.data = null;
        });


        builder.addCase(createTestimonial.pending, (state) => {
            state.isLoading = true;
            state.data = null;
            state.isError = false
        });

        builder.addCase(createTestimonial.fulfilled, (state, action) => {
            state.isLoading = false
            state.data = action.payload
            state.isError = null
        });

        builder.addCase(createTestimonial.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true;
            state.data = null;
        });

        builder.addCase(editTestimonialData.pending, (state) => {
            state.isLoading = true;
            state.data = null;
            state.isError = false
        });

        builder.addCase(editTestimonialData.fulfilled, (state, action) => {
            state.isLoading = false
            state.data = action.payload
            state.isError = null
        });

        builder.addCase(editTestimonialData.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true;
            state.data = null;
        });

        builder.addCase(updateTestimonial.pending, (state) => {
            state.isLoading = true;
            state.data = null;
            state.isError = false
        });

        builder.addCase(updateTestimonial.fulfilled, (state, action) => {
            state.isLoading = false
            state.data = action.payload
            state.isError = null
        });

        builder.addCase(updateTestimonial.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true;
            state.data = null;
        });

        builder.addCase(deleteTestimonial.pending, (state) => {
            state.isLoading = true;
            state.data = null;
            state.isError = false
        });

        builder.addCase(deleteTestimonial.fulfilled, (state, action) => {
            state.isLoading = false
            state.data = action.payload
            state.isError = null
        });

        builder.addCase(deleteTestimonial.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true;
            state.data = null;
        });

    },
    reducers: {

    },

});

export default testimonialSlice.reducer;