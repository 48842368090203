import React from 'react'
import userIcon from '../../assets/profileIcon.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons'
import './DashboardPage.scss'
import card_1_icon from '../../assets/card_1_icon.png'
import card_2_icon from '../../assets/card_2_icon.png'
import card_3_icon from '../../assets/card_3_icon.png'
import card_4_icon from '../../assets/card_4_icon.png'
import card_5_icon from '../../assets/card_5_icon.png'
import card_6_icon from '../../assets/card_6_icon.png'
import approved from '../../assets/approved_icon.png'
import rejected from '../../assets/rejected_icon.png'
import { Modal, ModalBody } from 'react-bootstrap'
import StarRatings from 'react-star-ratings'
import { useState } from 'react'
import { useEffect } from 'react'
import apiservice from '../../apiservice/ApiService'
import noimage from '../../assets/no-image.png'
import moment from 'moment/moment'
import SearchResults from './SearchResults'
import Loader from '../../components/common-components/Loader'
function DashboardPage(props) {
    const [showCommentPopup, setShowCommentPopup] = useState(false)
    const [curerntPopupValue, setCurerntPopupValue] = useState('')
    const [userData, setUserData] = useState('')
    const [dashboardCount, setDashboardCount] = useState('')
    const [reviewsData, setReviewsData] = useState([])
    const [reviewsDataForLearner, setReviewsDataForLearner] = useState([])
    const [notificationArr, setNotificationArr] = useState([])
    const [contentExpertArr, setContentExpertArr] = useState([])

    const [isApprove, setIsApprove] = useState(false)
    const [isDelete, setIsDelete] = useState(false)
    const [curServiceReview, setCurServiceReview] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    const [showCommentLearnesPopup, setShowCommentLearnesPopup] = useState(false)
    const [curerntPopupLearnersValue, setCurerntPopupLearnersValue] = useState('')

    useEffect(() => {
        getUserData()
        getAdminDashboardCount()
        GetAllReviews()
        GetAllReviewsForLearners()
        getNotificationData()
        getAllContentExpertData()
        localStorage.setItem('isDashboardSearch', false)
        let searchValue = localStorage.getItem('isDashboardSearch')
        console.log(searchValue);
        if (searchValue === 'true') {
            setIsSearch(true)
        } else {
            setIsSearch(false)
        }
    }, [])
    const getUserData = async () => {
        let userData = await apiservice.getLocalStorage('userdata')
        console.log(userData);
        setUserData(userData)

    }

    const getAdminDashboardCount = async () => {
        setIsLoading(true)
        let userData = await apiservice.getLocalStorage('userdata')
        await apiservice.apiCall('/AdminDashboardCount', userData.Token)
            .then(res => {
                if (res.unAuthenticated) {
                    props.history.push('/')
                    localStorage.removeItem('userdata')
                } else if (res.data) {
                    setIsLoading(false)
                    console.log(res.data);
                    setDashboardCount(res.data)
                } else {
                    setIsLoading(false)
                    console.log(res);
                }
            })
            .catch(err => {
                setIsLoading(false)
                console.log(err);
            })
    }

    const GetAllReviews = async () => {
        setIsLoading(true)
        let udata = await apiservice.getLocalStorage('userdata')
        await apiservice.apiCall('/ServiceReviewAdmin', udata.Token)
            .then(res => {
                if (res.unAuthenticated) {
                    props.history.push('/')
                    localStorage.removeItem('userdata')
                } else if (res.data) {
                    setIsLoading(false)
                    console.log(res.data);
                    setReviewsData(res.data)
                } else {
                    setIsLoading(false)
                    console.log(res);
                }
            })
            .catch(err => {
                setIsLoading(false)
                console.log(err);
            })
    }

    const GetAllReviewsForLearners = async () => {
        setIsLoading(true)
        let udata = await apiservice.getLocalStorage('userdata')
        await apiservice.apiCall('/LeanerReviewAdmin', udata.Token)
            .then(res => {
                if (res.unAuthenticated) {
                    props.history.push('/')
                    localStorage.removeItem('userdata')
                } else if (res.data) {
                    setIsLoading(false)
                    console.log(res.data);
                    setReviewsDataForLearner(res.data)
                } else {
                    setIsLoading(false)
                    console.log(res);
                }
            })
            .catch(err => {
                setIsLoading(false)
                console.log(err);
            })
    }

    const getNotificationData = async () => {
        setIsLoading(true)
        let udata = await apiservice.getLocalStorage('userdata')
        console.log(udata);
        await apiservice.apiCall('/admin_get_recent_notifications', udata.Token).then(res => {
            console.log(res.data);
            if (res.unAuthenticated) {
                props.history.push('/')
                localStorage.removeItem('userdata')
            } else if (res.data) {
                setIsLoading(false)
                setNotificationArr(res.data)
            } else {
                setIsLoading(false)
                console.log(res);
            }
        }).catch(err => {
            setIsLoading(false)
            console.log(err);
        })
    }

    const listOfNotification = () => {
        let list = []
        let dataArr = notificationArr
        for (let index = 0; index < dataArr.length; index++) {
            const data = dataArr[index];
            if (index < 5) {

                list.push(
                    <div>
                        <div className='row m-0'>
                            <div className='col-lg-9 col-12 long_text_wrap' style={{}}>{data.Message} </div>
                            <div className='col-lg-3 col-12 text-lg-end text-start'>{moment(new Date(data.created_at)).startOf('minite').fromNow()}</div>
                        </div>
                        <hr />
                    </div>
                )
            }
        }

        return list
    }

    const getAllContentExpertData = async () => {

        setIsLoading(true)
        let udata = await apiservice.getLocalStorage('userdata')
        await apiservice.apiCall('/ContentExpertData', udata.Token).then(res => {
            console.log(res.data);
            if (res.unAuthenticated) {
                props.history.push('/')
                localStorage.removeItem('userdata')
            } else if (res.data) {
                setIsLoading(false)
                console.log(res.data);
                setContentExpertArr(res.data)
            } else {
                setIsLoading(false)
                console.log(res.err);
            }
        }).catch(err => {
            setIsLoading(false)
            console.log(err);
        })
    }


    const feedbackList = () => {
        let list = []
        let dataArr = reviewsData

        for (let index = 0; index < dataArr.length; index++) {
            let data = dataArr[index]
            console.log(data);
            if (index < 10) {
                list.push(
                    <tr key={index}>
                        <td className="pointer" onClick={() => onClickShowComments(data)}>{index + 1}.</td>
                        {/* <td className="pointer" onClick={() => onClickShowComments(data)}>
                            <div className='rounded-circle overflow-hidden table_data_image' ><img className='w-100 h-100' src={data.learnerData.learnerProfileImage === null ? data.learnerData.SocialImage === null ? noimage : data.learnerData.SocialImage : `${apiservice.apiImageShow()}/${data.learnerData.learnerProfileImage}`} alt='' /></div>
                        </td> */}
                        <td className="pointer" onClick={() => onClickShowComments(data)}>
                            <div className=''>{data.learnerData.learnerName}</div>

                        </td>
                        <td className="pointer" onClick={() => onClickShowComments(data)}>
                            <div className=''>{data.ContentExpert.ContentExpertName}</div>

                        </td>
                        <td className="pointer" onClick={() => onClickShowComments(data)}>{data.learnerData.LearnerEmail}</td>
                        <td className="pointer" onClick={() => onClickShowComments(data)}>{new Date(data.created_at).toLocaleDateString()}</td>
                        <td className="pointer" onClick={() => onClickShowComments(data)}>
                            <div className='text_wrap'>
                                {data.comment}
                            </div>
                        </td>
                        <td>
                            <div className='d-flex justify-content-end'>
                                {data.Status === 0 ?
                                    <div className='pointer' onClick={() => onClickApprove(data)}>
                                        <img src={approved} alt='' />
                                    </div> : ''}
                                <div className='mx-3 pointer' onClick={() => onClickDetele(data)}>
                                    <img src={rejected} alt='' />
                                </div>
                            </div>
                        </td>
                    </tr>
                )
            }

        }

        return list
    }
    const feedbackListForLearners = () => {
        let list = []
        let dataArr = reviewsDataForLearner

        for (let index = 0; index < dataArr.length; index++) {
            let data = dataArr[index]
            console.log(data);
            if (index < 10) {
                list.push(
                    <tr key={index}>
                        <td className="pointer" onClick={() => onClickShowLearnersComments(data)}>{index + 1}.</td>
                        {/* <td className="pointer" onClick={() => onClickShowLearnersComments(data)}>
                            <div className='rounded-circle overflow-hidden table_data_image' ><img className='w-100 h-100' src={data.learnerData.learnerProfileImage === null ? data.learnerData.SocialImage === null ? noimage : data.learnerData.SocialImage : `${apiservice.apiImageShow()}/${data.learnerData.learnerProfileImage}`} alt='' /></div>
                        </td> */}
                        <td className="pointer" onClick={() => onClickShowLearnersComments(data)}>
                            <div className=''>{data.content_expert.name} {data.content_expert.lname}</div>

                        </td>
                        <td className="pointer" onClick={() => onClickShowLearnersComments(data)}>
                            <div className=''>{data.learner.name} {data.learner.lname}</div>

                        </td>
                        <td className="pointer" onClick={() => onClickShowLearnersComments(data)}>{data.content_expert.email}</td>
                        <td className="pointer" onClick={() => onClickShowLearnersComments(data)}>{new Date(data.created_at).toLocaleDateString()}</td>
                        <td className="pointer" onClick={() => onClickShowLearnersComments(data)}>
                            <div className='text_wrap'>
                                {data.comment}
                            </div>
                        </td>
                        <td>
                            <div className='d-flex justify-content-end'>
                                {data.Status === 0 ?
                                    <div className='pointer' onClick={() => onClickLearnersApprove(data)}>
                                        <img src={approved} alt='' />
                                    </div> : ''}
                                <div className='mx-3 pointer' onClick={() => onClickLearnersDetele(data)}>
                                    <img src={rejected} alt='' />
                                </div>
                            </div>
                        </td>
                    </tr>
                )
            }

        }

        return list
    }



    const onClickApprove = async (e) => {
        setCurServiceReview(e)
        setIsApprove(true)

    }

    const onClickDetele = async (e) => {
        setCurServiceReview(e)
        setIsDelete(true)
    }

    const [curServiceLearnersReview, setCurServiceLearnersReview] = useState('')
    const [isApproveLearner, setIsApproveLearner] = useState('')
    const [isDeleteLearner, setIsDeleteLearner] = useState('')

    const onClickLearnersApprove = async (e) => {
        setCurServiceLearnersReview(e)
        setIsApproveLearner(true)

    }

    const onClickLearnersDetele = async (e) => {
        console.log(e);
        setCurServiceLearnersReview(e)
        setIsDeleteLearner(true)
    }


    const topContentExport = () => {
        let list = []
        let dataArr = contentExpertArr.length > 0 ? contentExpertArr.filter((data) => data.is_admin_verified === 1) : []
        for (let index = 0; index < dataArr.length; index++) {
            let data = dataArr[index]
            if (index < 5) {

                list.push(
                    <tr key={index}>
                        {/* <td >
                            <div className='rounded-circle overflow-hidden table_data_image' >
                                <img className='w-100 h-100' src={data.ProfileImage === null ? (data.SocialImage === null ? data.profile_photo_url : data.SocialImage) : `${apiservice.apiImageShow()}/${data.ProfileImage}`} alt='' />
                            </div>
                        </td> */}
                        <td>
                            <div className=''>
                                <div className='mt-0'>{data.name} {data.lname}</div>
                            </div>
                        </td>
                        <td>{data.lastDesignation === null ? 'Not available' : data.lastDesignation}</td>
                        <td>{data.overallExp} Yr.</td>
                        <td>{data.services === null ? 0 : data.services} Serv.</td>
                    </tr>
                )
            }

        }

        return list
    }

    const onClickShowComments = (e) => {
        setCurerntPopupValue(e)
        setShowCommentPopup(true)
    }
    const onClickCloseComments = () => {
        setShowCommentPopup(false)
    }

    const onClickShowLearnersComments = (e) => {
        setCurerntPopupLearnersValue(e)
        setShowCommentLearnesPopup(true)
    }
    const onClickCloseLearnersComments = () => {
        setShowCommentLearnesPopup(false)
    }


    const onClickCard = (e) => {
        if (e === 'On-Going Services') {
            props.history.push('/home/totalbooking')
            localStorage.setItem('currentMenu', 'On-Going')
        }
        else if (e === 'Completed Services') {
            props.history.push('/home/totalbooking')
            localStorage.setItem('currentMenu', 'Completed')
        }
        else if (e === 'Total Services') {
            props.history.push('/home/totalservices')
            localStorage.setItem('currentMenu', 'Total Services')
        }
        else if (e === 'Total Learners') {
            props.history.push('/home/totallearners')
        }
        else if (e === 'Total Content Expert') {
            props.history.push('/home/contentexpertrequest')
        }
        else {
            // props.history.push('https://dashboard.razorpay.com/?screen=sign_in')
            window.open('https://www.paypal.com/signin')
        }
    }

    const [searchType, setSearchType] = useState('All')
    const [searchValue, setSearchValue] = useState('')
    const [searchResultArr, setSearchResultArr] = useState([])
    const [isSearch, setIsSearch] = useState(false)

    const onClickSearch = async () => {
        setIsLoading(true)
        let udata = await apiservice.getLocalStorage('userdata')
        let dataObject = {
            type: searchType,
            value: searchValue
        }
        console.log(dataObject);
        await apiservice.apiCall('/Search', udata.Token, dataObject, 'POST').then(res => {
            console.log(res.data);
            if (res.unAuthenticated) {
                props.history.push('/')
                localStorage.removeItem('userdata')
            } else if (res.data) {
                setIsLoading(false)
                setSearchResultArr(res.data)
                localStorage.setItem('isDashboardSearch', true)
                let searchValue = localStorage.getItem('isDashboardSearch')
                console.log(searchValue);
                if (searchValue === 'true') {
                    setIsSearch(true)
                } else {
                    setIsSearch(false)
                }
            } else {
                setIsLoading(false)
                console.log(res);
            }
        }).catch(err => {
            setIsLoading(false)
            console.log(err);
        })

    }

    const onChangeSearchInput = (e) => {
        const { name, value } = e.target
        setIsSearch(false)
        if (name === 'searchType') {
            setSearchType(value)
        }
        if (name === 'searchValue') {
            setSearchValue(value)
        }
    }


    const onClickApproveYes = async () => {
        setIsLoading(true)
        let udata = await apiservice.getLocalStorage('userdata')
        // console.log(e.Status);
        let dataObject = {
            status: '1'
        }
        await apiservice.apiCall(`/StatusServiceReviewAdmin/${curServiceReview.SReview_id}`, udata.Token, dataObject, "POST").then(res => {
            console.log(res)
            if (res.unAuthenticated) {
                props.history.push('/')
                localStorage.removeItem('userdata')
            } else if (res.data) {
                setIsLoading(false)
                console.log(res.data);
                setIsApprove(false)
                setShowCommentPopup(false)
                GetAllReviews()
            } else {
                setIsLoading(false)
                console.log(res);
            }
        }).catch(err => {
            setIsLoading(false)
            console.log(err);
        })
    }
    const onClickApproveNo = () => {
        setIsApprove(false)
    }
    
    const onClickApproveLearnersYes = async () => {
        setIsLoading(true)
        let udata = await apiservice.getLocalStorage('userdata')
        // console.log(e.Status);
        let dataObject = {
            status: '1'
        }
        await apiservice.apiCall(`/StatusLeanerReviewAdmin/${curServiceLearnersReview.Review_id}`, udata.Token, dataObject, "POST").then(res => {
            console.log(res)
            if (res.unAuthenticated) {
                props.history.push('/')
                localStorage.removeItem('userdata')
            } else if (res.data) {
                setIsLoading(false)
                console.log(res.data);
                setIsApproveLearner(false)
                setShowCommentLearnesPopup(false)
                GetAllReviewsForLearners()
            } else {
                setIsLoading(false)
                console.log(res);
            }
        }).catch(err => {
            setIsLoading(false)
            console.log(err);
        })
    }
    const onClickApproveLearnersNo = () => {
        setIsApproveLearner(false)

    }


    const onClickDeleteYes = async () => {
        setIsLoading(true)
        let udata = await apiservice.getLocalStorage('userdata')
        await apiservice.apiCall(`/delete_review/${curServiceReview.SReview_id}`, udata.Token, null, 'POST').then(res => {
            if (res.unAuthenticated) {
                props.history.push('/')
                localStorage.removeItem('userdata')
            } else if (res.data) {
                setIsLoading(false)
                console.log(res.data);
                setIsDelete(false)
                setShowCommentPopup(false)
                GetAllReviews()
            } else {
                setIsLoading(false)
                console.log(res);
            }
        }).catch(err => {
            setIsLoading(false)
            console.log(err);
        })
    }
    const onClickDeleteNo = () => {
        setIsDelete(false)
    }
    const onClickDeleteLearnersYes = async () => {
        setIsLoading(true)
        let udata = await apiservice.getLocalStorage('userdata')
        await apiservice.apiCall(`/delete_learner_review/${curServiceLearnersReview.Review_id}`, udata.Token, null, 'POST').then(res => {
            if (res.unAuthenticated) {
                props.history.push('/')
                localStorage.removeItem('userdata')
            } else if (res.data) {
                setIsLoading(false)
                console.log(res.data);
                setIsDeleteLearner(false)
                setShowCommentLearnesPopup(false)
                GetAllReviewsForLearners()
            } else {
                setIsLoading(false)
                console.log(res);
            }
        }).catch(err => {
            setIsLoading(false)
            console.log(err);
        })
    }
    const onClickDeleteLearnersNo = () => {
        setIsDelete(false)
    }

    return (
        <>


            <Modal show={showCommentPopup} className='mt-5 pt-5'>
                <ModalBody className='position-relative'>
                    <small className='rounded-circle text-center px-2 py-1 shadow bg-light pointer' style={{ position: 'absolute', top: -15, right: -10 }} onClick={onClickCloseComments}><FontAwesomeIcon icon={faTimes} /></small>
                    <div>
                        <StarRatings
                            rating={+curerntPopupValue.Ratting}
                            starRatedColor="#FDB022"
                            numberOfStars={5}
                        />
                    </div>
                    <div className='mt-3'>
                        <div>Comment</div>
                        <div className='mt-2 p-3 border rounded-2'>
                            {curerntPopupValue.comment}
                        </div>
                        <div className='text-center fw-bold mt-3'>Publish this feedback on the website!</div>
                        <div className='d-flex justify-content-center mt-3'>
                            {curerntPopupValue.Status === 0 ?
                                <div className='pointer' onClick={() => onClickApprove(curerntPopupValue)}>
                                    <img src={approved} alt='' />
                                </div> : ''}
                            <div className='ms-3 pointer' onClick={() => onClickDetele(curerntPopupValue)}>
                                <img src={rejected} alt='' />
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
            <Modal show={showCommentLearnesPopup} className='mt-5 pt-5'>
                <ModalBody className='position-relative'>
                    <small className='rounded-circle text-center px-2 py-1 shadow bg-light pointer' style={{ position: 'absolute', top: -15, right: -10 }} onClick={onClickCloseLearnersComments}><FontAwesomeIcon icon={faTimes} /></small>
                    <div>
                        <StarRatings
                            rating={+curerntPopupLearnersValue.Ratting}
                            starRatedColor="#FDB022"
                            numberOfStars={5}
                        />
                    </div>
                    <div className='mt-3'>
                        <div>Comment </div>
                        <div className='mt-2 p-3 border rounded-2'>
                            {curerntPopupLearnersValue.comment}
                        </div>
                        <div className='text-center fw-bold mt-3'>Publish this feedback on the website!</div>
                        <div className='d-flex justify-content-center mt-3'>
                            {curerntPopupLearnersValue.Status === 0 ?
                                <div className='pointer' onClick={() => onClickLearnersApprove(curerntPopupLearnersValue)}>
                                    <img src={approved} alt='' />
                                </div> : ''}
                            <div className='ms-3 pointer' onClick={() => onClickLearnersDetele(curerntPopupLearnersValue)}>
                                <img src={rejected} alt='' />
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>


            <Modal show={isApprove}>
                <ModalBody>
                    <div className='text-center p-3'>
                        <div>Would you like to make this review public?</div>
                        <div className='d-flex justify-content-center mt-3'>
                            <div className='p-2'>
                                <button className='btn btn-orange w-100' onClick={onClickApproveYes}>Yes</button>
                            </div>
                            <div className='p-2'>
                                <button className='btn btn-outline-secondary w-100' onClick={onClickApproveNo}>No</button>
                            </div>
                        </div>
                    </div>

                </ModalBody>
            </Modal>
            <Modal show={isApproveLearner}>
                <ModalBody>
                    <div className='text-center p-3'>
                        <div>Would you like to make this review public?</div>
                        <div className='d-flex justify-content-center mt-3'>
                            <div className='p-2'>
                                <button className='btn btn-orange w-100' onClick={onClickApproveLearnersYes}>Yes</button>
                            </div>
                            <div className='p-2'>
                                <button className='btn btn-outline-secondary w-100' onClick={onClickApproveLearnersNo}>No</button>
                            </div>
                        </div>
                    </div>

                </ModalBody>
            </Modal>

            <Modal show={isDelete}>
                <ModalBody>
                    <div className='text-center p-3'>
                        <div>Do you wish to delete this review?</div>
                        <div className='d-flex justify-content-center mt-3'>
                            <div className='p-2'>
                                <button className='btn btn-orange w-100' onClick={onClickDeleteYes}>Yes</button>
                            </div>
                            <div className='p-2'>
                                <button className='btn btn-outline-secondary w-100' onClick={onClickDeleteNo}>No</button>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>

            <Modal show={isDeleteLearner}>
                <ModalBody>
                    <div className='text-center p-3'>
                        <div>Do you wish to delete this review?</div>
                        <div className='d-flex justify-content-center mt-3'>
                            <div className='p-2'>
                                <button className='btn btn-orange w-100' onClick={onClickDeleteLearnersYes}>Yes</button>
                            </div>
                            <div className='p-2'>
                                <button className='btn btn-outline-secondary w-100' onClick={onClickDeleteLearnersNo}>No</button>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
            {isLoading ?
                <Loader /> :
                <div className=''>
                    <div className='mt-3 admin-dashboard-container'>
                        <div className='row m-0'>
                            <div className='col-lg-2 col-12  fw-bold mb-lg-0 mb-3 Title' >
                                <div>Welcome</div>
                            </div>
                            <div className='col-lg-8 col-12'>
                                <div className="input-group mb-3 border rounded-3 border-dark mx-lg-2">
                                    <span className="input-group-text border-0  bg-transparent">
                                        <div className="form-group">
                                            <select className="form-select bg-transparent border-0 border-end border-dark rounded-0 text-center"
                                                onChange={onChangeSearchInput} name='searchType' value={searchType} style={{ width: '155px', fontSize: '14px' }}>
                                                <option className='text-start' value="All">All</option>
                                                <option className='text-start' value="Content Expert">Content Expert</option>
                                                <option className='text-start' value="Learners">Learners</option>
                                                <option className='text-start' value="Services">Services</option>
                                            </select>
                                        </div>
                                    </span>
                                    <input type="text" onChange={onChangeSearchInput}
                                        className="form-control border-0 bg-transparent" name="searchValue" value={searchValue} placeholder='Search' />
                                    <span className="input-group-text border-0 bg-transparent d-lg-block d-none" onClick={onClickSearch}>
                                        <button className='btn btn-search-btn-blue '><FontAwesomeIcon icon={faSearch} className='me-2' /> Search</button>
                                    </span>
                                    <span className="input-group-text border-0 bg-transparent   d-lg-none d-block" onClick={onClickSearch}>
                                        <button className='btn btn-search-btn-blue'><FontAwesomeIcon icon={faSearch} className='' /> </button>
                                    </span>
                                </div>
                            </div>
                            <div className='col-lg-2 col-12'>
                                <div className=' rounded-circle overflow-hidden text-center  d-lg-block d-none position-relative pointer' style={{ width: '50px', height: '50px' }} onClick={() => props.history.push('/home/setting')}>
                                    <img src={userData.ProfileImage === null ? userData.SocialImage === null ? userData.profile_photo_url : userData.SocialImage : `${apiservice.apiImageShow()}/${userData.ProfileImage}`} alt='...' width={'100%'} height={'100%'} className='' />
                                </div>
                            </div>
                        </div>

                    </div>

                    {isSearch ?
                        <SearchResults history={props.history} type={searchType} data={JSON.stringify(searchResultArr)} />
                        :
                        <div>
                            <div className='mt-3'>
                                <div className='row m-0'>
                                    <div className='col-lg-4 col-md-6 col-12 p-3'>
                                        <div className='p-3 card-1  pointer rounded-3' onClick={() => onClickCard('On-Going Services')}>
                                            <div className='row'>
                                                <div className='col-4 text-center p-3'>
                                                    <img src={card_1_icon} alt='...' />
                                                </div>
                                                <div className='col-8 text-center p-3'>
                                                    <div className='fs-4 fw-bold mx-lg-1'>{dashboardCount.OngoingService}</div>
                                                    <div className='fw-bold' style={{ fontSize: 12 }}>On-Going Services</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-6 col-12 p-3'>
                                        <div className='p-3 card-2  pointer rounded-3' onClick={() => onClickCard('Completed Services')}>
                                            <div className='row'>
                                                <div className='col-4 text-center p-3'>
                                                    <img src={card_2_icon} alt='...' />
                                                </div>
                                                <div className='col-8 text-center p-3'>
                                                    <div className='fs-4 fw-bold mx-lg-1'>{dashboardCount.CompltedService}</div>
                                                    <div className='fw-bold' style={{ fontSize: 12 }}>Completed Services</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-6 col-12 p-3'>
                                        <div className='p-3 card-3  pointer rounded-3' onClick={() => onClickCard('Total Services')}>
                                            <div className='row'>
                                                <div className='col-4 text-center p-3'>
                                                    <img src={card_3_icon} alt='...' />
                                                </div>
                                                <div className='col-8 text-center p-3'>
                                                    <div className='fs-4 fw-bold mx-lg-1'>{dashboardCount.TotalService}</div>
                                                    <div className='fw-bold' style={{ fontSize: 12 }}>Total Services </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-6 col-12 p-3'>
                                        <div className='p-3 card-4  pointer rounded-3' onClick={() => onClickCard('Total Learners')}>
                                            <div className='row'>
                                                <div className='col-4 text-center p-3'>
                                                    <img src={card_4_icon} alt='...' />
                                                </div>
                                                <div className='col-8 text-center p-3'>
                                                    <div className='fs-4 fw-bold mx-lg-1'>{dashboardCount.TotalLearner}</div>
                                                    <div className='fw-bold' style={{ fontSize: 12 }}>Total Learners</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-6 col-12 p-3'>
                                        <div className='p-3 card-5  pointer rounded-3' onClick={() => onClickCard('Total Content Expert')}>
                                            <div className='row'>
                                                <div className='col-4 text-center p-3'>
                                                    <img src={card_5_icon} alt='...' />
                                                </div>
                                                <div className='col-8 text-center p-3'>
                                                    <div className='fs-4 fw-bold mx-lg-1'>{dashboardCount.totalContent_expert}</div>
                                                    <div className='fw-bold' style={{ fontSize: 12 }}>Total Content Expert</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-6 col-12 p-3'>
                                        <div className='p-3 card-6  pointer rounded-3' onClick={() => onClickCard('Total Earnings')}>
                                            <div className='row'>
                                                <div className='col-4 text-center p-3'>
                                                    <img src={card_6_icon} alt='...' />
                                                </div>
                                                <div className='col-8 text-center p-3'>
                                                    <div className='fs-4 fw-bold mx-lg-1'>${Number(dashboardCount.TotalEarning).toFixed(2)}</div>
                                                    <div className='fw-bold' style={{ fontSize: 12 }}>Total Earnings</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='mt-5'>
                                <div className='row m-0'>
                                    <div className='col-lg-6 col-12'>
                                        <div className=''>
                                            <div className='border rounded-2 p-3'>
                                                <div className='d-flex justify-content-between'>
                                                    <div className='fw-bold'>Notification</div>
                                                    <div className='text-primary pointer' onClick={() => props.history.push('/home/notification')}>View all <span>&#62;</span></div>
                                                </div>
                                                <hr />
                                                {listOfNotification()}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-12'>
                                        <div className=''>
                                            <div className='border rounded-2 p-3 '>
                                                <div className='d-flex justify-content-between'>
                                                    <div className='fw-bold'>Top Content Expert</div>
                                                </div>
                                                <hr />
                                                <div className='w-100 overflow-auto'>
                                                    <table className="table table-borderless table-responsive w-100 ">
                                                        <thead>
                                                            <tr>
                                                                {/* <th>Icon</th> */}
                                                                <th>Name</th>
                                                                <th>Designation</th>
                                                                <th>Experience</th>
                                                                <th>Services </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {topContentExport()}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>



                            <div className='mt-3 col-12'>
                                <div className='p-3'>
                                    <div className='border rounded-2 p-3 '>
                                        <div className='d-flex justify-content-between'>
                                            <div className='fw-bold'>Reviews for Content Expert</div>
                                            <div className='text-primary pointer' onClick={() => props.history.push('/home/reviews')}>View all <span>&#62;</span></div>
                                        </div>
                                        <hr />
                                        {reviewsData.length > 0 ?
                                            <div className='w-100 overflow-auto'>
                                                <table className="table table-borderless table-responsive ">
                                                    <thead>
                                                        <tr>
                                                            <th>No.</th>
                                                            {/* <th>Icon</th> */}
                                                            <th>From</th>
                                                            <th>To</th>
                                                            <th>Email Address</th>
                                                            <th>Date</th>
                                                            <th>Comment</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {feedbackList()}
                                                    </tbody>
                                                </table>
                                            </div> :
                                            <div className='text-center my-5 fw-bold'>Data not Found!</div>}
                                    </div>
                                </div>
                            </div>


                            <div className='mt-3 col-12'>
                                <div className='p-3'>
                                    <div className='border rounded-2 p-3 '>
                                        <div className='d-flex justify-content-between'>
                                            <div className='fw-bold'>Reviews for Learner</div>
                                            <div className='text-primary pointer' onClick={() => props.history.push('/home/learnersreviews')}>View all <span>&#62;</span></div>
                                        </div>
                                        <hr />
                                        {reviewsDataForLearner.length > 0 ?
                                            <div className='w-100 overflow-auto'>
                                                <table className="table table-borderless table-responsive ">
                                                    <thead>
                                                        <tr>
                                                            <th>No.</th>
                                                            {/* <th>Icon</th> */}
                                                            <th>From</th>
                                                            <th>To</th>
                                                            <th>Email Address</th>
                                                            <th>Date</th>
                                                            <th>Comment</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {feedbackListForLearners()}
                                                    </tbody>
                                                </table>
                                            </div> :
                                            <div className='text-center my-5 fw-bold'>Data not Found!</div>}
                                    </div>
                                </div>
                            </div>



                        </div >
                    }
                </div >
            }
        </>
    )
}

export default DashboardPage
