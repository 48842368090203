import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import apiservice from '../apiservice/ApiService';


export const getAllContentExperts = createAsyncThunk("contentExpert/getAllContentExperts", async (dataObject) => {
    const response = await apiservice.apiCall('/ContentExpertData', dataObject)
    // console.log(response);
    return response
});
export const getContentExpertSingleData = createAsyncThunk("contentExpert/getContentExpertSingleData", async (dataObject) => {
    const { id, token } = dataObject
    const response = await apiservice.apiCall(`/SingleContentExpertData/${id}`, token)
    return response.data
});
export const updateContentExpertData = createAsyncThunk("contentExpert/updateContentExpertData", async (dataObject) => {
    const { id, auth, data } = dataObject
    const response = await apiservice.apiCallformData(`/StatusChanges/${id}`, auth, data, 'POST')
    return response.data
});


const contentExpertsSlice = createSlice({
    name: 'contentExpert',
    initialState: {
        isLoading: false,
        data: [],
        isError: false
    },

    extraReducers: (builder) => {
        builder.addCase(getAllContentExperts.pending, (state) => {
            state.isLoading = true;
            state.data = null;
            state.isError = false
        });

        builder.addCase(getAllContentExperts.fulfilled, (state, action) => {
            state.isLoading = false
            state.data = action.payload
            state.isError = null
        });

        builder.addCase(getAllContentExperts.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true;
            state.data = null;
        });

        builder.addCase(getContentExpertSingleData.pending, (state) => {
            state.isLoading = true;
            state.data = null;
            state.isError = false
        });

        builder.addCase(getContentExpertSingleData.fulfilled, (state, action) => {
            state.isLoading = false
            state.data = action.payload
            state.isError = null
        });

        builder.addCase(getContentExpertSingleData.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true;
            state.data = null;
        });

        builder.addCase(updateContentExpertData.pending, (state) => {
            state.isLoading = true;
            state.data = null;
            state.isError = false
        });

        builder.addCase(updateContentExpertData.fulfilled, (state, action) => {
            state.isLoading = false
            state.data = action.payload
            state.isError = null
        });

        builder.addCase(updateContentExpertData.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true;
            state.data = null;
        });

    },
    reducers: {

    },

});

export default contentExpertsSlice.reducer;