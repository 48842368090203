
export default function validation() {
  let mobileVal = /[0-9]{3}[0-9]{3}[0-9]{4}/
  let emailVal = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  let passwordVal = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@.#$!%*?&^])[A-Za-z\d@.#$!%*?&]{8,15}$/
  let onlyStringVal = /^[a-zA-Z]*$/ 
  let onlyNumberVal = /^[0-9]*$/

  return {mobileVal, emailVal, passwordVal, onlyNumberVal, onlyStringVal}
}
