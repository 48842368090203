import React from 'react'
import { Pagination } from 'react-bootstrap';
import './Notificationpage.scss'
import { useState } from 'react';
import left from '../../assets/ArrowLeftShape.png'
import right from '../../assets/ArrowRightShape.png'
import leftLight from '../../assets/ArrowLightLeftShape.png'
import rightLight from '../../assets/ArrowLightRightShape.png'
import { useEffect } from 'react';
import apiservice from '../../apiservice/ApiService';
import moment from 'moment/moment';
import Loader from '../../components/common-components/Loader';
function NotificationPage(props) {

  const [notificationArr, setNotificationArr] = useState([])

  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    getNotificationData()
  }, [])
  const getNotificationData = async () => {
    setIsLoading(true)
    let udata = await apiservice.getLocalStorage('userdata')
    console.log(udata);
    await apiservice.apiCall('/admin_get_all_notifications', udata.Token).then(res => {
      console.log(res.data);
      if (res.unAuthenticated) {
        props.history.push('/')
        localStorage.removeItem('userdata')
      } else if (res.data) {
        setIsLoading(false)
        setNotificationArr(res.data)
      } else {
        setIsLoading(false)
        console.log(res);
      }
    }).catch(err => {
      setIsLoading(false)
      console.log(err);
    })
  }

  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(notificationArr.length / itemsPerPage);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const itemsToDisplay = notificationArr.slice(startIndex, endIndex);

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const pageRangeStart = Math.max(1, currentPage - 2);
  const pageRangeEnd = Math.min(totalPages, pageRangeStart + 2);

  const notificationList = () => {
    let array = itemsToDisplay
    let list = []
    for (let index = 0; index < array.length; index++) {
      let data = array[index]
      list.push(
        <div key={index} className='p-3 row border-bottom'>
          <div className='col-lg-10 col-12 pt-2 text-break'>
            <div className='d-flex'>
              <div className='me-3 fw-bold'> {index + 1}.</div>
              <div className='col'> {data.Message}</div>
            </div>
          </div>
          <div className='col-lg-2 mt-lg-0 mt-3 col-12 pt-2 text-end'>{moment(new Date(data.created_at)).startOf('minite').fromNow()} </div>
        </div>
      )
    }
    return list
  }

  return (
    <>{isLoading ? <Loader /> :
      <div className='p-3'>
        <div className='container-fluid'>
          <div className='fs-3 fw-bold'>Notification</div>
        </div>
        <div className='mt-3'>
          {/* <div className='p-3 bg-light'>Notifications</div> */}
          <div>
            {notificationList()}
          </div>
          <div className="pagination-container mt-3" style={{ display: 'flex', justifyContent: 'center' }}>
            <Pagination>
              <Pagination.Prev
                className={currentPage === 1 ? "disbaled_Btn" : "prevBtn"}
                onClick={handlePreviousPage}
                disabled={currentPage === 1 ? true : false}
              >
                <img src={currentPage === 1 ? leftLight : left} alt='...' />
              </Pagination.Prev>
              {[...Array(pageRangeEnd - pageRangeStart + 1).keys()].map((offset) => (
                <Pagination.Item
                  key={pageRangeStart + offset}
                  active={pageRangeStart + offset === currentPage}
                  onClick={() => handlePageChange(pageRangeStart + offset)}
                >
                  {pageRangeStart + offset}
                </Pagination.Item>
              ))}
              {pageRangeEnd < totalPages && (
                <Pagination.Ellipsis disabled />
              )}
              <Pagination.Next
                className={currentPage === totalPages ? "disbaled_Btn" : "nextBtn"}
                onClick={handleNextPage}
                disabled={currentPage === totalPages ? true : false}
              >
                <img src={currentPage === totalPages ? rightLight : right} alt='...' />
              </Pagination.Next>
            </Pagination>
          </div>
        </div>
      </div>}
    </>

  );
}

export default NotificationPage
