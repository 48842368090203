import { React, Fragment } from 'react'
import "./MentorsCard.scss";
function MentorCard({ mentorsName, mentorsDescription, mentorsImage, sideBgColor = 'cardBg0', onClick = null }) {
  return (
    <Fragment>
      <div className='col-12 mentorsCardMentors my-2 pointer'>
        <div className="card" style={{ width: '100%' }}>
          <div className={sideBgColor}>
          </div>
          <div className="card-body p-2">
            <div className='col-12'>
              <div className='d-flex'>
                <div className=''>
                  <div className='overflow-hidden rounded-circle' style={{ width: '70px', height: '70px' }}>
                    <img src={mentorsImage} className=' ' alt="" width={'100%'} height={'100%'} />
                  </div>

                </div>
                <div className=''>
                  <div className='card-text ms-4 m-2'>
                    <div className=' fw-bold'>{mentorsName} </div>
                    <div className="  text-wrap pe-3" style={{ fontSize: '14px' }}>{mentorsDescription}</div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default MentorCard
