import React from 'react'
import './ForgotPasswordPage.scss'
import logo from '../../assets/logo.png'
import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import validation from '../../components/common-components/validation'
import { forgotPassword } from '../../redux/forgotPasswordReducer'
import apiservice from '../../apiservice/ApiService'
import Loader from '../../components/common-components/Loader'


function ForgotPasswordPage(props) {
    const initialdata = {
        email: '',
        successMsg: ''
    }
    const [data, setData] = useState(initialdata)
    const [errorMsg, setErrorMsg] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const onChangeHandle = (e) => {
        const { name, value } = e.target
        setData({ ...data, [name]: value, successMsg: '' })
        setErrorMsg('')

    }

    const onClickSendOtp = async () => {
        if (data.email === '') {
            setErrorMsg('Email Id is required')
        } else if (!data.email.match(validation().emailVal)) {
            setErrorMsg('Invalid email ID! ')
        }
        else {
            setIsLoading(true)
            let dataObject = {
                email: data.email
            }
            await apiservice.apiCall('/password/email', '', dataObject, 'POST').then(res => {
                if (res.unAuthenticated) {
                    props.history.push('/')
                    localStorage.removeItem('userdata')
                } else if (res.data) {
                    setIsLoading(false)
                    setData({ ...data, 'successMsg': res.data, 'email': '' })
                } else {
                    setIsLoading(false)
                    console.log(res);
                    setErrorMsg(res.error)
                }
            }).catch(err => {
                setIsLoading(false)
                console.log(err);
            })



        }
    }

    return (
        <>
            {isLoading ? <Loader /> :
                <div className='login-main-container'>
                    <div className='p-5'>
                        <img src={logo} alt='...' />
                    </div>
                    <div className='container'>
                        <div className='col-lg-6 col-12 offset-lg-3 offset-0 mt-5 border border-light border-4 rounded-4 p-5 sub-container'>
                            <div className='fs-3 fw-bold'>Forgot your password?</div>
                            <div className='mt-3'>Please enter the Email address associated with your account.</div>
                            <div className='mt-3'>
                                <div className='text-center'>
                                    {errorMsg !== '' ?
                                        <small className='text-danger'>{errorMsg}</small>
                                        : ''}
                                    <div className='mb-3 text-center'>
                                        {data.successMsg !== '' ?
                                            <small className='text-success py-3'>{data.successMsg}</small>
                                            : ''}
                                    </div>
                                </div>
                                <div className='mb-3'>
                                    <div className="form-group">
                                        <label htmlFor="">Email</label>
                                        <input type="text"
                                            className="form-control" name="email" value={data.email} onChange={onChangeHandle} placeholder="Email" />

                                    </div>

                                </div>
                                <div className='mb-3'>
                                    <button className='btn border border-dark px-4 py-2' onClick={onClickSendOtp} >Submit <FontAwesomeIcon icon={faArrowRight} className='ms-2' /></button>
                                </div>

                                <div className='mb-3'>
                                    <span className=' pointer fw-bold' style={{ color: '#297ADA' }} onClick={() => props.history.push('/')} >Back</span>
                                </div>



                            </div>
                        </div>
                    </div>
                </div>}
        </>
    )
}

export default ForgotPasswordPage
