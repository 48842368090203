import React, { useEffect, useState } from 'react'
import StarRatings from 'react-star-ratings'
import './TestimonialPage.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import apiservice from '../../apiservice/ApiService'
import noiamge from '../../assets/no-image.png'
import Loader from '../../components/common-components/Loader'

function TestimonialPage(props) {


  const [testimonialData, setTestimonialData] = useState([])

  const [viewShowCount, setViewShowCount] = useState(6)
  const [isLoading, setIsLoading] = useState(false)
  const getTestimonialData = async () => {
    setIsLoading(true)
    let userdata = null
    userdata = await apiservice.getLocalStorage('userdata')
    await apiservice.apiCall('/Testimonal', userdata.Token).then(res => {
      if (res.unAuthenticated) {
        props.history.push('/')
        localStorage.removeItem('userdata')
      } else if (res.data) {
        setIsLoading(false)
        console.log(res.data);
        setTestimonialData(res.data)
      } else {
        setIsLoading(false)
        console.log(res);
      }
    }).catch(err => {
      setIsLoading(false)
      console.log(err);
    })

  }

  useEffect(() => {
    getTestimonialData()
  }, [])

  const onClickCreate = () => {
    localStorage.setItem('isEdit', false)
    props.history.push('/home/createtestimonial')
  }

  const onClickEdit = async (data) => {
    localStorage.setItem('isEdit', true)

    await apiservice.setLocalStorage('isEditTestimonialData', data)

    props.history.push('/home/createtestimonial')

  }

  const onClickDelete = async (data) => {
    setIsLoading(true)
    let userdata = await apiservice.getLocalStorage('userdata')
    let dataObject = {
      id: data.test_id,
      auth: userdata.Token,
      data: null
    }
    await apiservice.apiCall(`/Testimonaldelete/${data.test_id}`, userdata.Token, null, 'POST').then(res => {
      if (res.unAuthenticated) {
        props.history.push('/')
        localStorage.removeItem('userdata')
      } else if (res.data) {
        setIsLoading(false)
        getTestimonialData()
      } else {
        setIsLoading(false)
        console.log(res);
      }
    }).catch(err => {
      setIsLoading(false)
      console.log(err);
    })

  }

  const listOfTestimonial = () => {
    let list = []
    let dataArr = testimonialData
    let count = 0

    for (let index = 0; index < dataArr.length; index++) {
      let data = dataArr[index]
      if (viewShowCount >= index + 1) {

        list.push(
          <div key={index} className='col-md-6 col-lg-4 col-12 p-3'>
            <div className='testimonialcard overflow-hidden h-100'>
              <div className='p-3 cardHoverChild h-100 py-3'>
                <div className='position-relative top-0 bottom-0 start-0 end-0 m-auto h-50 w-50 text-center pt-5 mt-3'>
                  <button className='btn btn-light' onClick={() => onClickEdit(data)}>Edit</button>
                  <button className='btn btn-orange ms-3' onClick={() => onClickDelete(data)}>Delete</button>
                </div>
              </div>
              <div className={`p-3 testimonialcard${count}  rounded-2 h-100`}>
                <div className='p-3 bg-light rounded-2 h-100'>
                  <div className='d-flex flex-column h-100'>
                    <div className='me-3 d-flex'>
                      <div className='rounded-circle overflow-hidden' style={{ width: '70px', height: '70px' }}>
                        <img src={data.ProfileImage === null ? noiamge : `${apiservice.apiImageShow()}/${data.ProfileImage}`} alt='' className='w-100 h-100' />
                      </div>
                      <div className='ms-3 mt-3'>

                        <div className='fw-bold'>{data.Name}</div>
                        <div>
                          <StarRatings
                            rating={data.Ratting === null ? 0 : data.Ratting}
                            starRatedColor="#FDB022"
                            numberOfStars={5}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='flex-grow-1 d-flex flex-column'>

                      <div className='flex-grow-1'>
                        {data.Description}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }
      if (count === 2) {
        count = 0
      } else {
        count++
      }
    }
    return list
  }

  const onClickShowMore = () => {
    let count = viewShowCount
    count = count + 6
    // alert(count)
    setViewShowCount(count)

  }
  return (
    <>
      {isLoading ? <Loader /> :
        <div>
          <div className='d-flex justify-content-between'>
            <div className='fs-3 fw-bold'>Testimonial</div>
            <div>
              <button className='btn btn-light-blue px-4 fw-semibold' onClick={onClickCreate}>Create New</button>
            </div>
          </div>
          <hr />

          <div className='mt-3'>
            {testimonialData.length > 0 ?
              <div className='row m-0'>
                {listOfTestimonial()}
              </div> :
              <div>
                <div className='my-5 text-center'>User Dose not have data! </div>
              </div>}
          </div>

          <div className='text-center mt-3'>
            {viewShowCount >= testimonialData.length ? '' :
              <button className='btn btn-orange px-5' onClick={onClickShowMore}>Show More <FontAwesomeIcon className='ms-3' icon={faArrowRight} /></button>
            }
          </div>
        </div>
      }
    </>
  )
}

export default TestimonialPage
