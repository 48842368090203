import React, { useEffect } from 'react'
import DataTable from 'datatables.net-dt';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-buttons/js/dataTables.buttons.js"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import './ReviewsForLearner.scss'
import apiservice from '../../apiservice/ApiService';
import { useState } from 'react';
import { Modal, ModalBody } from 'react-bootstrap';
import StarRatings from 'react-star-ratings';

import noimage from '../../assets/no-image.png'
import userIcon from '../../assets/profileIcon.png'
import approved from '../../assets/approved_icon.png'
import rejected from '../../assets/rejected_icon.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Loader from '../../components/common-components/Loader';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { Calendar, DateRange } from 'react-date-range';
import moment from 'moment';

function ReviewsForLearner(props) {
    const [reviewsData, setReviewsData] = useState([])
    const [showCommentPopup, setShowCommentPopup] = useState(false)
    const [curerntPopupValue, setCurerntPopupValue] = useState('')
    const [isApprove, setIsApprove] = useState(false)
    const [isDelete, setIsDelete] = useState(false)
    const [curServiceReview, setCurServiceReview] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    useEffect(() => {
        getAllreviewsData()
        setTimeout(() => {
            new DataTable('#myTable', {
                pagingType: 'full_numbers',
                pageLength: 10,
                processing: true,
                dom: '<"top"ifrt><"bottom"lp><"clear">',


            });
        }, 1000);
    }, [])

    const getAllreviewsData = async () => {
        setIsLoading(true)
        let userData = await apiservice.getLocalStorage('userdata')
        try {
            await apiservice.apiCall('/LeanerReviewAdmin', userData.Token).then(async res => {

                if (res.unAuthenticated) {
                    props.history.push('/')
                    localStorage.removeItem('userdata')
                } else if (res.data) {
                    setIsLoading(false)
                    console.log(res.data);
                    setReviewsData(res.data)


                } else {
                    setIsLoading(false)
                    console.log(res);
                }
            })
        } catch (error) {
            setIsLoading(false)
            console.log(error);
        }
    }




    const feedbackList = () => {
        let list = []
        let dataArr = reviewsData

        for (let index = 0; index < dataArr.length; index++) {
            let data = dataArr[index]
            console.log(data);

            list.push(
                <tr key={index}>
                    <td className='pointer' onClick={() => onClickShowComments(data)}>0{index + 1}.</td>
                    {/* <td className='pointer' onClick={() => onClickShowComments(data)}>
                        <div className='rounded-circle overflow-hidden table_data_image' ><img className='w-100' src={data.learnerData.learnerProfileImage === null ? data.learnerData.SocialImage === null ? noimage : data.learnerData.SocialImage : `${apiservice.apiImageShow()}/${data.learnerData.learnerProfileImage}`} alt='' /></div>
                    </td> */}
                    <td className='pointer' onClick={() => onClickShowComments(data)}>
                        <div className=''>{data.content_expert.name} {data.content_expert.lname}</div>

                    </td>
                    <td className="pointer" onClick={() => onClickShowComments(data)}>
                            <div className=''>{data.learner.name} {data.learner.lname}</div>

                        </td>
                    <td className='pointer' onClick={() => onClickShowComments(data)}>{data.content_expert.email}</td>
                    <td className='pointer' onClick={() => onClickShowComments(data)}> {moment(data.created_at).format('DD-MM-YYYY')}</td>
                    <td className='pointer' onClick={() => onClickShowComments(data)}>
                        <div className='text_wrap'>
                            {data.comment}
                        </div>
                    </td>
                    <td>
                        <div className='d-flex justify-content-end'>
                            {data.Status === 0 ?
                                <div className='pointer' onClick={() => onClickApprove(data)}>
                                    <img src={approved} alt='' />
                                </div> : ''}
                            <div className='mx-3 pointer' onClick={() => onClickDetele(data)}>
                                <img src={rejected} alt='' />
                            </div>
                        </div>
                    </td>
                </tr>
            )

        }

        return list
    }

    const onClickApprove = async (e) => {
        setCurServiceReview(e)
        setIsApprove(true)

    }

    const onClickDetele = async (e) => {
        setCurServiceReview(e)
        setIsDelete(true)

    }

    const onClickShowComments = (e) => {
        setCurerntPopupValue(e)
        setShowCommentPopup(true)
    }
    const onClickCloseComments = () => {
        setShowCommentPopup(false)
    }


    const onClickApproveYes = async () => {
        setIsLoading(true)
        let udata = await apiservice.getLocalStorage('userdata')
        // console.log(e.Status);
        let dataObject = {
            status: '1'
        }
        await apiservice.apiCall(`/StatusLeanerReviewAdmin/${curServiceReview.Review_id}`, udata.Token, dataObject, "POST").then(res => {
            console.log(res)
            if (res.unAuthenticated) {
                props.history.push('/')
                localStorage.removeItem('userdata')
            } else if (res.data) {
                setIsLoading(false)
                console.log(res.data);
                setIsApprove(false)
                setShowCommentPopup(false)
                getAllreviewsData()
            } else {
                setIsLoading(false)
                console.log(res);
            }
        }).catch(err => {
            setIsLoading(false)
            console.log(err);
        })
    }
    const onClickApproveNo = () => {
        setIsApprove(false)

    }
    const onClickDeleteYes = async () => {
        setIsLoading(true)
        let udata = await apiservice.getLocalStorage('userdata')
        await apiservice.apiCall(`/delete_learner_review/${curServiceReview.Review_id}`, udata.Token, null, 'POST').then(res => {
            if (res.unAuthenticated) {
                props.history.push('/')
                localStorage.removeItem('userdata')
            } else if (res.data) {
                setIsLoading(false)
                console.log(res.data);
                setIsDelete(false)
                setShowCommentPopup(false)
                getAllreviewsData()
            } else {
                setIsLoading(false)
                console.log(res);
            }
        }).catch(err => {
            setIsLoading(false)
            console.log(err);
        })
    }
    const onClickDeleteNo = () => {
        setIsDelete(false)
    }
    const [dateRange, setDateRange] = React.useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
        },
    ])
    return (
        <>
            <Modal show={showCommentPopup} className='mt-5 pt-5'>
                <ModalBody className='position-relative'>
                    <small className='rounded-circle text-center px-2 py-1 shadow bg-light' style={{ position: 'absolute', top: -15, right: -10 }} onClick={onClickCloseComments}><FontAwesomeIcon icon={faTimes} /></small>
                    <div>
                        <StarRatings
                            rating={+curerntPopupValue.Ratting}
                            starRatedColor="#FDB022"
                            numberOfStars={5}
                        />
                    </div>
                    <div className='mt-3'>
                        <div>Comment</div>
                        <div className='mt-2 p-3 border rounded-2'>
                            {curerntPopupValue.comment}
                        </div>
                        <div className='text-center fw-bold mt-3'>Publish this feedback on the website!</div>
                        <div className='d-flex justify-content-center mt-3'>
                            {curerntPopupValue.Status === 0 ?
                                <div className='pointer' onClick={() => onClickApprove(curerntPopupValue)}>
                                    <img src={approved} alt='' />
                                </div> : ''}
                            <div className='ms-3 pointer' onClick={() => onClickDetele(curerntPopupValue)}>
                                <img src={rejected} alt='' />
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>

            <Modal show={isApprove}>
                <ModalBody>
                    <div className='text-center p-3'>
                        <div>Would you like to make this review public?</div>
                        <div className='d-flex justify-content-center mt-3'>
                            <div className='p-2'>
                                <button className='btn btn-orange w-100' onClick={onClickApproveYes}>Yes</button>
                            </div>
                            <div className='p-2'>
                                <button className='btn btn-outline-secondary w-100' onClick={onClickApproveNo}>No</button>
                            </div>
                        </div>
                    </div>

                </ModalBody>
            </Modal>

            <Modal show={isDelete}>
                <ModalBody>
                    <div className='text-center p-3'>
                        <div>Do you wish to delete this review?</div>
                        <div className='d-flex justify-content-center mt-3'>
                            <div className='p-2'>
                                <button className='btn btn-orange w-100' onClick={onClickDeleteYes}>Yes</button>
                            </div>
                            <div className='p-2'>
                                <button className='btn btn-outline-secondary w-100' onClick={onClickDeleteNo}>No</button>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
            {isLoading ? <Loader /> :
                <div className='review-container'>
                    <div className='d-flex justify-content-between'>
                        <div className='fs-3 fw-bold'>Reviews for Learners</div>
                       
                    </div>
                    <div className='py-3 w-100 overflow-auto'>

                        <table id='myTable' className="table table-striped w-100  ">
                            <thead>
                                <tr>
                                    <th>No.</th>
                                    {/* <th>Icon</th> */}
                                    <th>From</th>
                                    <th>To</th>
                                    <th>Email Address</th>
                                    <th>Date</th>
                                    <th>Comment</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {feedbackList()}
                            </tbody>
                        </table>
                    </div>
                </div>}
        </>
    )
}

export default ReviewsForLearner
