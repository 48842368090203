import React, { useEffect } from 'react'
import usergroup from '../../assets/users-group.png'
import learnonline from '../../assets/online-learning.png'
import degree from '../../assets/degreeIcon.png'
import apiservice from '../../apiservice/ApiService'
import { useState } from 'react'
import noimage from '../../assets/no-image.png'
import { isEditable } from '@testing-library/user-event/dist/utils'
import StarRatings from 'react-star-ratings'

import noiamge from '../../assets/no-image.png'
import { HiArrowLongRight } from 'react-icons/hi2'
import Loader from '../../components/common-components/Loader'
import { Modal, ModalBody } from 'react-bootstrap'

function ContentExpertDetailsPage(props) {
    // const rdata = useSelector(state => state.contentExpertReducer.data[0])
    // const editData = rdata === undefined ? '' : rdata
    // console.log(editData);
    const [approvedMsg, setApprovedMsg] = useState('')
    const [rejectedMsg, setRejectedMsg] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [editData, setEditData] = useState('')
    const [reviewList, setReviewList] = useState([])
    const [viewShowCount, setViewShowCount] = useState(3)
    const [allEducationArr, setAllEducationArr] = useState()
    const [isApprove, setIsApprove] = useState(false)

    useEffect(() => {
        getLocalData()
    }, [])
    const getLocalData = async () => {
        setIsLoading(true)
        let id = props.match.params.id
        let udata = await apiservice.getLocalStorage('userdata')
        await apiservice.apiCall(`/SingleContentExpertData/${id}`, udata.Token).then(res => {
            console.log(res);
            if (res.unAuthenticated) {
                props.history.push('/')
                localStorage.removeItem('userdata')
            } else if (res.data) {
                setIsLoading(false)
                let datas = res.data[0]
                setAllEducationArr(datas.all_education)
                setEditData(datas);
                setReviewList(datas.review_list)

            } else {
                setIsLoading(false)
                console.log(res);
            }
        }).catch(err => {
            console.log(err);
            setIsLoading(false)
        })
    }
    console.log(allEducationArr);

    const onClickApprove = async () => {
        setIsLoading(true)
        let userdata = await apiservice.getLocalStorage('userdata')
        // let formData = new FormData()
        // formData.append('is_admin_verified', 1)
        let id = editData.content_expert_id
        let auth = userdata.Token
        let data = { is_admin_verified: '1' }
        console.log(id, auth, data);

        let res = await apiservice.apiCall(`/StatusChanges/${id}`, auth, data, 'POST')
        console.log(res);
        if (res.unAuthenticated) {
            props.history.push('/')
            localStorage.removeItem('userdata')
        } else if (res.data) {
            console.log(res.data);
            setIsLoading(false)
            setApprovedMsg(`Successfully approved!${res.data.name} ${res.data.lname}`)

            setTimeout(function () {
                setApprovedMsg('')
                props.history.push('/home/contentexpertrequest')
            }, 3000);
        } else {
            console.log(res.error);
        }

    }
    const onClickReject = async () => {
        setIsLoading(true)
        let userdata = await apiservice.getLocalStorage('userdata')
        // let formData = new FormData()
        // formData.append('is_admin_verified', 1)
        let id = editData.content_expert_id
        let auth = userdata.Token
        let data = { is_admin_verified: '2' }
        console.log(id, auth, data);

        let res = await apiservice.apiCall(`/StatusChanges/${id}`, auth, data, 'POST')
        console.log(res);
        if (res.unAuthenticated) {
            props.history.push('/')
            localStorage.removeItem('userdata')
        } else if (res.data) {
            setIsLoading(false)
            setRejectedMsg(`Successfully Rejected! ${res.data.name} ${res.data.lname}`)
            setTimeout(function () {
                setRejectedMsg('')
                props.history.push('/home/contentexpertrequest')
            }, 3000);
        } else {
            console.log(res.error);
        }
    }
    
    const onClickAlertDelete = async () => {
        setIsApprove(true)
    }

    const onClickApproveNo = () => {
        setIsApprove(false)
    }

    const onClickDelete = async () => {
        setIsApprove(false)
        setIsLoading(true)
        let userdata = await apiservice.getLocalStorage('userdata')
        // let formData = new FormData()
        // formData.append('is_admin_verified', 1)
        let id = editData.content_expert_id
        let auth = userdata.Token
        let data = { is_admin_verified: '2' }
        console.log(id, auth, data);

        let res = await apiservice.apiCall(`/DeleteUser/${id}`, auth, data, 'POST')
        console.log(res);
        if (res.unAuthenticated) {
            props.history.push('/')
            localStorage.removeItem('userdata')
        } else if (res.data) {
            setIsLoading(false)
            setRejectedMsg(`Successfully Deleted!`)
            setTimeout(function () {
                setRejectedMsg('')
                props.history.push('/home/contentexpertrequest')
            }, 3000);
        } else {
            console.log(res.error);
        }
    }

    const allEducation = () => {
        let list = [];
        console.log(allEducationArr);
        let dataArr = editData && editData.all_education; // Check if editData is available
        if (dataArr !== null) {
            if (dataArr) {
                for (let index = 0; index < dataArr.length; index++) {
                    const data = dataArr[index];
                    list.push(
                        <div key={index} className='col-lg-6 col-12 p-3'>
                            <div className='border mt-3 rounded-3 '>
                                {/* <div className='col-12 px-3 pt-3 fw-bold'>{index + 1}.</div> */}
                                <div className='  col-12 p-3'><span className='fw-bold me-3'>Course name :</span> {data.Name}</div>
                                <div className='  col-12 p-3'><span className='fw-bold me-3'>College Name :</span> {data.CollegeName}</div>
                                <div className='  col-12 p-3'><span className='fw-bold me-3'>University Name :</span> {data.UniversityName}</div>
                                <div className='  col-12 p-3'><span className='fw-bold me-3'>Start Year :</span> {data.StartingYear}</div>
                                <div className='  col-12 p-3'><span className='fw-bold me-3'>End Year :</span> {data.EndingYear}</div>
                            </div>
                        </div>
                    );
                }
            }
        } else {
            list.push(
                <div className='pt-3'>Data not available!</div>
            )

        }


        return list;
    }
    const allExperience = () => {
        let list = [];
        let dataArr = editData && editData.all_experience; // Check if editData is available
        if (dataArr !== null) {
            if (dataArr) {
                for (let index = 0; index < dataArr.length; index++) {
                    const data = dataArr[index];
                    list.push(
                        <div key={index} className='col-lg-6 col-12 p-3' >
                            <div className='border mt-3 rounded-3 h-100'>
                                <div className=' col-12 p-3'><span className='fw-bold me-3'>Company Name :</span> {data.CompanyName}</div>
                                <div className=' col-12 p-3'><span className='fw-bold me-3'>Designation :</span> {data.designation}</div>
                                <div className=' col-12 p-3'><span className='fw-bold me-3'>Start Year :</span> {data.start_date}</div>
                                <div className=' col-12 p-3'><span className='fw-bold me-3'>End Year :</span> {data.end_date}</div>
                                <div className=' col-12 p-3'><span className='fw-bold me-3'>Role and Responsibility :</span> {data.role_and_responsibility}</div>
                            </div>
                        </div>
                    );
                }
            }
        } else {
            list.push(
                <div className='pt-3'>Data not available!</div>
            )

        }


        return list;
    }
    const allCertifications = () => {
        let list = [];
        console.log(allEducationArr);
        let dataArr = editData && editData.certificates; // Check if editData is available
        console.log(dataArr);
        if (dataArr.length > 0) {
            if (dataArr) {
                for (let index = 0; index < dataArr.length; index++) {
                    const data = dataArr[index];
                    list.push(
                        <div key={index} className='col-lg-6 col-12 p-3' >
                            <div className='border mt-3 rounded-3 '>
                                <div className=' col-12 p-3'><span className='fw-bold me-3'>Certification name : </span> {data.Name}</div>
                                <div className=' col-12 p-3'><span className='fw-bold me-3'>Certification authority :</span> {data.Authority}</div>
                                <div className=' col-12 p-3'><span className='fw-bold me-3'>Certification ID :</span> {data.Cerid}</div>
                                <div className=' col-12 p-3 text-break'><span className='fw-bold me-3 '>Certification URL :</span > {data.URL}</div>
                            </div>
                        </div>
                    );
                }
            }
        } else {
            list.push(
                <div className='pt-3'>Data not available!</div>
            )

        }

        return list;
    }

    const listOfReviews = () => {
        let list = []
        let dataArr = editData && editData.review_list
        if (dataArr.length > 0) {
            for (let index = 0; index < dataArr.length; index++) {
                const data = dataArr[index];
                if (viewShowCount >= index + 1) {
                    list.push(
                        <div className="border-bottom py-3">
                            <div className="d-flex align-items-center py-2 mb-3">
                                <div className="rounded-circle overflow-hidden" style={{ width: '70px', height: '70px' }}>

                                    <img src={data.learner.ProfileImage === null ? data.learner.SocialImage === null ? noimage : data.learner.SocialImage : `${apiservice.apiImageShow()}/${data.learner.ProfileImage}`} width={'100%'} height={'100%'} alt="" />
                                </div>
                                <div className="px-3">
                                    <span className="reviewerName">{data.learner.name}</span>
                                    <br />
                                    <StarRatings
                                        rating={+data.Ratting}
                                        starDimension='20px'
                                        starRatedColor="#FDB022"
                                        numberOfStars={5}
                                    />
                                </div>
                            </div>
                            <p>
                                {data.comment}
                            </p>
                        </div>
                    )
                }
            }
        } else {
            list.push(
                <div className='py-3'>Data not available!</div>
            )

        }
        return list

    }

    const onClickShowMore = () => {
        let count = viewShowCount
        count = count + 3
        setViewShowCount(count)
    }

    return (
        <>
        <Modal show={isApprove}>
            <ModalBody>
                <div className='text-center p-3'>
                    <div>Would you like to delete this content expert user?</div>
                    <div className='d-flex justify-content-center mt-3'>
                        <div className='p-2'>
                            <button className='btn btn-orange w-100' onClick={onClickDelete}>Yes</button>
                        </div>
                        <div className='p-2'>
                            <button className='btn btn-outline-secondary w-100' onClick={onClickApproveNo}>No</button>
                        </div>
                    </div>
                </div>
            </ModalBody>
        </Modal>

        {isLoading ?
            <Loader />
            :
            <div>
                <div className='p-3 border rounded-3 mb-3'>
                    {approvedMsg !== '' || rejectedMsg !== '' ?
                        <div >
                            {approvedMsg !== '' ?
                                <div className='text-center text-success'> {approvedMsg}</div> : ''}
                            {rejectedMsg !== '' ?
                                <div className='text-center text-danger'> {rejectedMsg}</div> : ''}
                        </div>
                        :
                        <div className=''>

                            <div className='py-3'>
                                <div className='row'>
                                    <div className='col-md-6 col-12'>
                                        <div className='d-lg-flex d-block'>
                                            <div className=''>
                                                <div className='rounded-circle overflow-hidden' style={{ width: '100px', height: '100px' }}>
                                                    <img src={editData.ProfileImage === null ? editData.SocialImage === null ? editData.profile_photo_url : editData.SocialImage : `${apiservice.apiImageShow()}/${editData.ProfileImage}`} alt='...' className='w-100 h-100' />
                                                </div>
                                            </div>

                                            <div className='ms-lg-5 ms-0 pt-lg-3 pt-3'>
                                                <div className='fw-bold fs-4'>{editData.name} {editData.lname}</div>
                                                <div className='text-grey mt-2 fs-5'>{editData.lastDesignation === null ? 'Designation not available' : editData.lastDesignation}</div>
                                                <div className='mt-2'>
                                                    <div className='d-flex'>
                                                        <div className='me-4'>
                                                            <div className='d-flex'>
                                                                <div ><img src={usergroup} alt='' /></div>
                                                                <div className='ms-2 ms-xl-0 d-flex' >
                                                                    <div className='ms-lg-2 ms-1'>{editData.total_learner === null ? 0 : editData.total_learner} </div>
                                                                    <div className='ms-lg-2 ms-2'> Learners</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='me-4'>
                                                            <div className='d-flex'>
                                                                <div ><img src={learnonline} alt='' /></div>
                                                                <div className='ms-2 ms-xl-0 d-flex' >
                                                                    <div className='ms-lg-2 ms-1'>
                                                                        {editData.total_services === null ? 0 : editData.total_services}
                                                                    </div>
                                                                    <div className='ms-lg-2 ms-2'>
                                                                        Services
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-6 col-12'>

                                        {editData.is_admin_verified === 1 ?
                                            <div className='text-end mt-3'>
                                                <div className='badge text-light fs-6 bg-success rounded-2 fw-bold'>Approved</div>
                                            </div>
                                            : ''}
                                        {editData.is_admin_verified === 2 ?
                                            <div className='text-end mt-3'>
                                                <div className='badge text-light fs-6 bg-danger rounded-2 fw-bold'>Rejected</div>
                                            </div>
                                            : ''}
                                        <div className='d-md-none d-block mt-3'>
                                            <div className='row '>
                                                {editData.is_admin_verified !== 1 ?
                                                    <div className=' col-12 text-end'>
                                                        <button className='btn btn-outline-success w-100'>Approve</button>
                                                    </div> :
                                                    ''}
                                                {editData.is_admin_verified !== 2 ?
                                                    <div className=' col-12 text-end'>
                                                        <button className='btn btn-outline-danger mt-3 w-100'>Reject</button>
                                                    </div> : ''}
                                                {/* {editData.is_admin_verified !== 3 ?
                                                    <div className=' col-12 text-end'>
                                                        <button className='btn btn-outline-danger mt-3 w-100'>Delete</button>
                                                    </div> : ''} */}
                                            </div>

                                        </div>
                                        <div className='d-md-block d-none mt-3'>
                                            <div className='row '>

                                            <div className='d-flex justify-content-end'>
                                                {editData.is_admin_verified !== 1 ?
                                                    <div className='me-3'>
                                                        <button className='btn btn-outline-success mt-3' onClick={onClickApprove}>Approve</button>
                                                    </div>
                                                    : ''}

                                                    {editData.is_admin_verified !== 2 ?
                                                        <div className=' me-3'>
                                                            <button className='btn btn-outline-warning mt-3' onClick={onClickReject}>Reject</button>
                                                        </div> : ''}

                                                    <div className=''>
                                                            <button className='btn btn-outline-danger mt-3' onClick={onClickAlertDelete}>Delete</button>
                                                        </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />

                                <div className='mt-3'>
                                    <div className='fs-5 fw-bold'>About me</div>
                                    <div className='mt-3 border rounded-2 p-3'>{editData.AboutSelf === null ? 'Not available' :
                                        <div dangerouslySetInnerHTML={{ __html: editData.AboutSelf }}></div>
                                    }</div>
                                </div>
                                <div className='mt-3'>
                                    <div className='fs-5 fw-bold'>Education</div>
                                    <div className='mt-2 row'>
                                        {allEducation()}
                                    </div>
                                </div>
                                <div className='mt-3'>
                                    <div className='fs-5 fw-bold'>Work Experience</div>
                                    <div className='mt-2 row' style={{ height: 'max-content' }}>
                                        {allExperience()}
                                    </div>
                                </div>
                                <div className='mt-3'>
                                    <div className='fs-5 fw-bold'>Certifications</div>
                                    <div className='mt-2 row'>
                                        {allCertifications()}
                                    </div>
                                </div>
                            </div>

                            <div className='mt-3'>
                                <div className=''>
                                    <div className='fs-3 fw-bold'>Reviews</div>
                                </div>
                                {listOfReviews()}

                                {viewShowCount >= reviewList.length ? '' :
                                    <div className="w-100 text-center py-3 my-2">
                                        <button
                                            type="button"
                                            className="btn btn-primary border-0 text-center"
                                            style={{ backgroundColor: "#E57A4D" }}
                                            onClick={onClickShowMore}
                                        >
                                            Show More <HiArrowLongRight />
                                        </button>
                                    </div>
                                }

                            </div>
                        </div>
                    }
                </div>
            </div>
        }
        </>
    )
}

export default ContentExpertDetailsPage
