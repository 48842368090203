import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import apiservice from '../apiservice/ApiService';


export const forgotPassword = createAsyncThunk("forgotpassword", async (dataObject) => {
    const response = await apiservice.apiCall('/password/email','', dataObject,'POST')
    // console.log(response);
    return response
});


const forgotPasswordSlice = createSlice({
    name: 'forgotpassword',
    initialState: {
        isLoading: false,
        data: [],
        isError: false
    },

    extraReducers: (builder) => {
        builder.addCase(forgotPassword.pending, (state) => {
            state.isLoading = true;
            state.data = null;
            state.isError = false
        });

        builder.addCase(forgotPassword.fulfilled, (state, action) => {
            state.isLoading = false
            state.data = action.payload
            state.isError = null
        });

        builder.addCase(forgotPassword.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true;
            state.data = null;
        });

    },
    reducers: {

    },

});

export default forgotPasswordSlice.reducer;