import React, { useState } from 'react'
import { Modal, ModalBody } from 'react-bootstrap'
import apiservice from '../../apiservice/ApiService'

function LogoutModal(props) {
    const [checkboxValue, setCheckboxValue] = useState(0)
    const onClickLogout = async () => {
        let udata = await apiservice.getLocalStorage('userdata')
        console.log(udata);
        await apiservice.apiCall('/admin-logout', udata.Token, { is_all_device: checkboxValue }, 'POST').then(res => {
            if (res.unAuthenticated) {
                props.history.push('/')
                localStorage.removeItem('userdata')
            } else if (res.data) {
                localStorage.removeItem('userdata')
                console.log(res.data);
                props.history.push('/')
            } else {
                console.log(res);
            }
        }).catch(err => {
            console.log(err);
        })

    }
    const onClickCancel = () => {
        props.cancel()
    }

    const onChangeCheckBox = (e) => {
        let { checked } = e.target
        console.log(checked);
        if (checked === true) {
            setCheckboxValue(1)
        } else {
            setCheckboxValue(0)
        }
    }

    return (
        <>
            <Modal show={props.show} className='mt-5 pt-5'>
                <ModalBody>
                    <div className='p-2'>
                        <div className='mb-3 fs-4 fw-bold text-center ' style={{ color: '#E57A4D' }}>Logout</div>
                        <hr />
                        <div className='text-center  '>
                            Are you sure you want to log out ?

                            <div className='my-3'>
                                <div className="form-check">
                                    <label className="form-check-label">
                                        <input type="checkbox" onChange={onChangeCheckBox}
                                            className="form-check-input" checked={checkboxValue === 0 ? false : true} />
                                        Logout from all devices!
                                    </label>
                                </div>
                            </div>
                            <div className='mt-5'>
                                <div className='row'>
                                    <div className='col-6 '>
                                        <button className='w-100 btn btn-outline-dark' onClick={onClickCancel}>Cancel</button>
                                    </div>
                                    <div className='col-6 '>
                                        <button className='w-100 btn btn-orange' onClick={onClickLogout}>Logout</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </>
    )
}

export default LogoutModal
