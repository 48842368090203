import { combineReducers, configureStore } from '@reduxjs/toolkit';
import loginReducer from '../loginReducer';
import contentExpertReducer from '../contentExpertReducer';
import forgotPasswordReducer from '../forgotPasswordReducer';
import testimonialReducer from '../testimonialReducer';
import settingReducer from '../settingReducer';

const rootReducer = combineReducers({
  loginReducer,
  contentExpertReducer,
  forgotPasswordReducer,
  testimonialReducer,
  settingReducer
})

const store = configureStore(
  {
    reducer: rootReducer,
  }
)

export default store;
