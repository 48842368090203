import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import apiservice from '../apiservice/ApiService';


export const login = createAsyncThunk("loginUser", async (dataObject) => {
    const response = await apiservice.apiCall('/signin', '', dataObject, 'POST')
    console.log(response);
    return response
});


const loginSlice = createSlice({
    name: 'loginUser',
    initialState: {
        isLoading: false,
        data: [],
        isError: false
    },

    extraReducers: (builder) => {
        builder.addCase(login.pending, (state) => {
            state.isLoading = true;
            state.data = null;
            state.isError = false
        });

        builder.addCase(login.fulfilled, (state, action) => {
            state.isLoading = false
            state.data = action.payload
            state.isError = null
        });

        builder.addCase(login.rejected, (state, action) => {
            console.log('error', action.payload);
            state.isLoading = false
            state.isError = true;
            state.data = null;
        });

    },
    reducers: {

    },

});

export default loginSlice.reducer;