import React from 'react'
import { Pagination } from 'react-bootstrap';
import { useState } from 'react';
import left from '../../assets/ArrowLeftShape.png'
import right from '../../assets/ArrowRightShape.png'
import leftLight from '../../assets/ArrowLightLeftShape.png'
import rightLight from '../../assets/ArrowLightRightShape.png'
import noimage from '../../assets/no-image.png'
import apiservice from '../../apiservice/ApiService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faRefresh } from '@fortawesome/free-solid-svg-icons';
import noiamge from '../../assets/no-image.png'
import './ContentExpertRequestPage.scss'
function TopContentExpert(props) {
    // console.log(props.data.length);
    let dataArr = props.data.length > 0 ? props.data.filter((data) => data.is_admin_verified === 1) : [];
    // console.log(dataArr);


    const itemsPerPage = 5;
    const [currentPage, setCurrentPage] = useState(1);

    const totalPages = Math.ceil(dataArr.length / itemsPerPage);

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const itemsToDisplay = dataArr.slice(startIndex, endIndex);

    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const pageRangeStart = Math.max(1, currentPage - 2);
    const pageRangeEnd = Math.min(totalPages, pageRangeStart + 2);

    const onClickViewProfile = async (data) => {
        props.history.push(`/home/contentexpertdetails/${data.content_expert_id}`)


    }

    const [isSwitchOn, setIsSwitchOn] = useState(false)
    const onChangeSwitch = async (e, data) => {
        console.log(e);
        console.log(data);
        let userdata = await apiservice.getLocalStorage('userdata')
        apiservice.apiCall(`/content_expert_favorite/${data.content_expert_id}`, userdata.Token).then(res => {
            if (res.unAuthenticated) {
                props.history.push('/')
                localStorage.removeItem('userdata')
            } else if (res.data) {
                console.log(res.data);
                props.refreshdata()

            } else {
                console.log(res.error);
            }
        }).catch(err => {
            console.log(err);
        })
    }

    const contentExpertPendingList = () => {
        let array = itemsToDisplay
        let list = []


        for (let index = 0; index < array.length; index++) {
            let data = array[index]


            list.push(
                <div key={index} className='p-3 border rounded-3 mb-3'>
                    <div className='row'>
                        <div className='col-lg-10 col-12' onClick={() => onClickViewProfile(data)}>
                            <div className='d-flex'>
                                <div className=''>
                                    <div className='rounded-circle overflow-hidden userIcon' >
                                        <img src={data.ProfileImage === null ? data.SocialImage === null ? data.profile_photo_url : data.SocialImage : `${apiservice.apiImageShow()}/${data.ProfileImage}`} alt='...' className='w-100 h-100' />
                                    </div>
                                </div>

                                <div className=' ms-3 pt-0'>
                                    <div className='fw-bold'>{data.name} {data.lname}</div>
                                    <div className='text-grey'>{data.lastDesignation === null ? 'Designation not avilable' : data.lastDesignation}</div>
                                    <div className='d-lg-flex'><div>{data.services === null ? 0 : data.services} Services, </div>  <div className='ms-lg-2'> Total Experience - {data.overallExp === null ? 0 : data.overallExp} Years</div></div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-2 col-12'>
                            {/* <div className='row pt-4'>
                                <div className='col-lg-6 col-12 mb-lg-0 mb-3 '>
                                    <button className='btn btn-outline-success w-100'>Approve</button>
                                </div>
                                <div className='col-lg-6 col-12'>
                                    <button className='btn btn-outline-danger w-100'>Reject</button>
                                </div>
                            </div> */}
                            <div className="d-flex ">
                                <div className="form-check mx-2 form-switch">
                                    <input className="form-check-input" onChange={(e) => onChangeSwitch(e, data)} title='Favorite'
                                        type="checkbox" role="switch" id="flexSwitchCheckChecked" checked={data.is_favorite === 1 ? true : false} />
                                </div>
                                <div className=' col text-end mt-lg-0 mt-3'>
                                    <button className='btn btn-orange w-100 ' onClick={() => onClickViewProfile(data)}>View Profile <FontAwesomeIcon className='ms-3' icon={faArrowRight} /></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )

        }

        return list
    }


    return (
        <>
            <div className='mt-3'>
                {dataArr.length > 0 ?
                    <div>
                        <div>{contentExpertPendingList()}</div>
                        <div className="pagination-container mt-3" style={{ display: 'flex', justifyContent: 'center' }}>
                            <Pagination>
                                <Pagination.Prev
                                    className={currentPage === 1 ? "disbaled_Btn" : "prevBtn"}
                                    onClick={handlePreviousPage}
                                    disabled={currentPage === 1 ? true : false}
                                >
                                    <img src={currentPage === 1 ? leftLight : left} alt='...' />
                                </Pagination.Prev>
                                {[...Array(pageRangeEnd - pageRangeStart + 1).keys()].map((offset) => (
                                    <Pagination.Item
                                        key={pageRangeStart + offset}
                                        active={pageRangeStart + offset === currentPage}
                                        onClick={() => handlePageChange(pageRangeStart + offset)}
                                    >
                                        {pageRangeStart + offset}
                                    </Pagination.Item>
                                ))}
                                {pageRangeEnd < totalPages && (
                                    <Pagination.Ellipsis disabled />
                                )}
                                <Pagination.Next
                                    className={currentPage === totalPages ? "disbaled_Btn" : "nextBtn"}
                                    onClick={handleNextPage}
                                    disabled={currentPage === totalPages ? true : false}
                                >
                                    <img src={currentPage === totalPages ? rightLight : right} alt='...' />
                                </Pagination.Next>
                            </Pagination>
                        </div>
                    </div>
                    : <div>
                        <div className='text-center mt-5 text-danger'>
                            Currently, we do not have any content experts. Please promote the platform and encourage the public to serve as content experts on the platform.
                        </div>
                        <div className='mt-3 text-center'>
                            <button className='btn btn-light' onClick={props.refreshdata}>Refresh <FontAwesomeIcon className='ms-2' icon={faRefresh} /></button>
                        </div>
                    </div>}
            </div>
        </>
    )
}

export default TopContentExpert
