import React, { useEffect } from 'react'
import DataTable from 'datatables.net-dt';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-buttons/js/dataTables.buttons.js"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import './Reports.scss'
import apiservice from '../../apiservice/ApiService';
import { useState } from 'react';
import moment from 'moment';

function Reports(props) {
  const [servicesData, setServicesData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    getAllServicesData()

  }, [])

  const getAllServicesData = async () => {
    setIsLoading(true)
    let userData = await apiservice.getLocalStorage('userdata')
    try {
      await apiservice.apiCall('/showreport', userData.Token).then(async res => {
        
        if (res.unAuthenticated) {
          props.history.push('/')
          localStorage.removeItem('userdata')
        } else if (res.data) {
          setIsLoading(false)
          console.log(res.data);
          setServicesData(res.data)
          setTimeout(() => {
            new DataTable('#myTable', {
              pagingType: 'full_numbers',
              pageLength: 10,
              processing: true,
              dom: 'Bifrt',
              buttons: [
                {
                  extend: 'copy',
                  title: 'XperThrivr Services Reports',
                },
                {
                  extend: 'csv',
                  title: 'XperThrivr Services Reports',
                },
                {
                  extend: 'print',
                  title: 'XperThrivr Services Reports',
                },
              ],
            });
          }, 1000);
         
        } else {
          setIsLoading(false)
          console.log(res);
        }
      })
    } catch (error) {
      setIsLoading(false)
      console.log(error);
    }
  }

  const listOfServices = () => {
    let list = []
    let dataArr = servicesData
    for (let index = 0; index < dataArr.length; index++) {
      const data = dataArr[index];
      list.push(
        <tr key={index} className=''>
          <td>{index + 1}</td>
          <td>{moment(data.BookingDate).format('DD-MM-YYYY')}</td>
          <td>{data.content_expert_name}</td>
          <td>{data.Service_Name}</td>
          <td className='text-end'>
            <div className='row '>
              {data.Status !== null ?
                <div className=''>
                  {data.Status === 'Upcoming' ?
                    <div className='p-2  text-center text-warning border rounded-2 border-warning'>{data.Status}</div> :
                    data.Status === 'On-going' ?
                      <div className='p-2  text-center text-primary border rounded-2 border-primary'>{data.Status}</div> :
                      <div className='p-2  text-center text-success border rounded-2 border-success'>{data.Status}</div>}
                </div>
                : ''}

            </div>
          </td>
          <td>   <button className='btn btn-outline-warning py-2 w-100' onClick={() => onClickView(data.ServiceId)}>View</button></td>
        </tr>
      )
    }

    return list
  }

  const onClickView = (e) => {
    console.log(e);
    props.history.push(`/home/preview/${e}`)
  }
  return (
    <>
      <div className='report-container'>
        <div className='d-flex justify-content-between'>
          <div className='fs-3 fw-bold'>Report</div>

        </div>
        <div className='mt-3  w-100 overflow-auto '>

          <table id='myTable' className="table  table-striped table-responsive " style={{ width: '100%' }}>
            <thead>
              <tr>
                <th>No.</th>
                <th>Date</th>
                <th>Content Expert</th>
                <th>Service</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {listOfServices()}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

export default Reports
