import React from 'react'
import { useState } from 'react';
import RequestPendingPage from './RequestPendingPage';
import RequestApprovedPage from './RequestApprovedPage';
import RequestRejectedPage from './RequestRejectedPage';
import { useEffect } from 'react';
import apiservice from '../../apiservice/ApiService';
import TopContentExpert from './TopContentExpert';
import Loader from '../../components/common-components/Loader';
function ContentExpertRequestPage(props) {


    const [activeTab, setActiveTab] = useState('Top Content Expert')
    const [contenrExpertData, setContenrExpertData] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const onClickTab = (e) => {
        setActiveTab(e)
    }
    const getContentExpertsData = async () => {
        setIsLoading(true)
        let userdata = null
        userdata = await apiservice.getLocalStorage('userdata')
        await apiservice.apiCall('/ContentExpertData', userdata.Token).then(res => {
            if (res.unAuthenticated) {
                props.history.push('/')
                localStorage.removeItem('userdata')
            } else if (res.data) {
                setIsLoading(false)
                setContenrExpertData(res.data)

            } else {
                setIsLoading(false)
                console.log(res);
            }
        }).catch(err => {
            setIsLoading(false)
            console.log(err);
        })


    }
    const getUpdateContentExpertsData = async () => {
        // setIsLoading(true)
        let userdata = null
        userdata = await apiservice.getLocalStorage('userdata')
        await apiservice.apiCall('/ContentExpertData', userdata.Token).then(res => {
            if (res.unAuthenticated) {
                props.history.push('/')
                localStorage.removeItem('userdata')
            } else if (res.data) {
                setIsLoading(false)
                setContenrExpertData(res.data)

            } else {
                setIsLoading(false)
                console.log(res);
            }
        }).catch(err => {
            setIsLoading(false)
            console.log(err);
        })


    }

    useEffect(() => {
        getContentExpertsData()
    }, [])
    const refreshData = () => {
        getUpdateContentExpertsData()
    }
    return (

        <>
            {isLoading ? <Loader /> :
                <div className=''>
                    <div className='row m-0'>
                        <div className='col-lg-3 col-12 mt-lg-0 mt-2'>
                            <div className=''>
                                <button className={activeTab === 'Top Content Expert' ? 'btn btn-light-blue w-100 py-lg-3 py-1 ' : 'btn btn-light w-100 py-lg-3 py-1 '} onClick={() => onClickTab('Top Content Expert')}>Top Content Expert</button>
                            </div>
                        </div>
                        <div className='col-lg-3 col-4  mt-lg-0 mt-2'>
                            <div className=''>
                                <button className={activeTab === 'Pending' ? 'btn btn-light-blue w-100 py-lg-3 py-1 ' : 'btn btn-light w-100 py-lg-3 py-1 '} onClick={() => onClickTab('Pending')}>Pending</button>
                            </div>
                        </div>
                        <div className='col-lg-3 col-4  mt-lg-0 mt-2'>
                            <div className=''>
                                <button className={activeTab === 'Approved' ? 'btn btn-light-blue w-100 py-lg-3 py-1 ' : 'btn btn-light w-100 py-lg-3 py-1 '} onClick={() => onClickTab('Approved')}>Approved</button>
                            </div>
                        </div>
                        <div className='col-lg-3 col-4  mt-lg-0 mt-2'>
                            <div className=''>
                                <button className={activeTab === 'Rejected' ? 'btn btn-light-blue w-100 py-lg-3 py-1 ' : 'btn btn-light w-100 py-lg-3 py-1 '} onClick={() => onClickTab('Rejected')}>Rejected</button>
                            </div>
                        </div>
                    </div>

                    <div className='mt-3'>
                        {activeTab === "Pending" ?
                            <div><RequestPendingPage data={contenrExpertData} history={props.history} refreshdata={refreshData} /></div>
                            :
                            activeTab === "Approved" ?
                                <div><RequestApprovedPage data={contenrExpertData} history={props.history} refreshdata={refreshData} /></div>
                                :
                                activeTab === "Rejected" ?
                                    <div><RequestRejectedPage data={contenrExpertData} history={props.history} refreshdata={refreshData} /></div>
                                    :
                                    <div><TopContentExpert data={contenrExpertData} history={props.history} refreshdata={refreshData} /></div>
                        }

                    </div>




                </div>
            }
        </>
    )
}

export default ContentExpertRequestPage
