import React from 'react'
import './ResetPasswordPage.scss'
import logo from '../../assets/logo.png'
import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import validation from '../../components/common-components/validation'

function ResetPasswordPage() {
    const initialdata = {
        password: '',
        cpassword: '',
        showPassword: true,
        showCPassword: true
    }

    const [data, setData] = useState(initialdata)

    const [errorMsg, setErrorMsg] = useState('')
    const onChangeHandle = (e) => {
        const { name, value } = e.target
        setData({ ...data, [name]: value })
        
    setErrorMsg('')
    }
    const onClickShowPassword = () => {
        setData({ ...data, 'showPassword': data.showPassword = !data.showPassword })
    }
    const onClickShowCPassword = () => {
        setData({ ...data, 'showCPassword': data.showCPassword = !data.showCPassword })
    }


    const onClickChangePassword = () => {
        if (data.password === '') {
            setErrorMsg('New password is required')
        } else if (!data.password.match(validation().passwordVal)) {
            setErrorMsg('Password is not valid (Abc@123)')
        } else if (data.cpassword === '') {
            setErrorMsg('Confirm password is required ')
        } else if (data.password !== data.cpassword) {
            setErrorMsg('Password is mismatch')
        }
        else {
            alert('successfully send')
        }
    }
    return (
        <>
            <div className='login-main-container'>
                <div className='p-5'>
                    <img src={logo} alt='...' />
                </div>
                <div className='container'>
                    <div className='col-lg-6 col-12 offset-lg-3 offset-0 mt-5 border border-light border-4 rounded-4 p-5 sub-container'>
                        <div className='fs-3 fw-bold'>Reset Your Password </div>
                        <div className='mt-3'>
                            <div className='text-center'>
                                {errorMsg !== '' ?
                                    <small className='text-danger'>{errorMsg}</small>
                                    : ''}
                            </div>
                            <div className='mb-3'>
                                <label htmlFor="">New password</label>
                                <div className="input-group ">
                                    <input type={data.showPassword ? "password" : "text"}
                                        className="form-control border-end-0" name="password" value={data.password} onChange={onChangeHandle} placeholder="Password" />
                                    <span className="input-group-text bg-white " id="basic-addon2">
                                        {data.showPassword ?
                                            <FontAwesomeIcon icon={faEyeSlash} onClick={onClickShowPassword} /> :
                                            <FontAwesomeIcon icon={faEye} onClick={onClickShowPassword} />}
                                    </span>
                                </div>
                            </div>
                            <div className='mb-3'>
                                <label htmlFor="">Confirm password</label>
                                <div className="input-group ">
                                    <input type={data.showCPassword ? "password" : "text"}
                                        className="form-control border-end-0" name="cpassword" value={data.cpassword} onChange={onChangeHandle} placeholder="Password" />
                                    <span className="input-group-text bg-white " id="basic-addon2">
                                        {data.showCPassword ?
                                            <FontAwesomeIcon icon={faEyeSlash} onClick={onClickShowCPassword} /> :
                                            <FontAwesomeIcon icon={faEye} onClick={onClickShowCPassword} />}
                                    </span>
                                </div>
                            </div>
                            <div className='mb-3'>
                                <button className='btn border border-dark px-4 py-2' onClick={onClickChangePassword}>Change Password <FontAwesomeIcon icon={faArrowRight} className='ms-2' /></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ResetPasswordPage
