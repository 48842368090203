import React from 'react'
import './OTPPage.scss'
import logo from '../../assets/logo.png'
import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { useEffect } from 'react'
import validation from '../../components/common-components/validation'

function OTPPage() {
  const initialdata = {
    otp: '',
  }



  const [data, setData] = useState(initialdata)
  const [errorMsg, setErrorMsg] = useState('')
  const [timer, setTimer] = useState(60)
  useEffect(() => {
    setTimeout(() => {
      if (timer === 0) {
        setTimer(0)
        return false
      } else {
        let curTime = timer - 1
        setTimer(curTime)
      }
    }, 1000);
  })

  const onChangeHandle = (e) => {
    const { name, value } = e.target
    setData({ ...data, [name]: value })

    setErrorMsg('')
  }

  const resendOtp = () => {
    setTimer(60)
    setTimeout(() => {
      if (timer === 0) {
        setTimer(0)
        return false
      } else {
        let curTime = timer - 1
        setTimer(curTime)
      }
    }, 1000);
  }

  const onClickOnSubmitOtp = () => {
    if (data.otp === '') {
      setErrorMsg('Please enter OTP')
    } else if (!data.otp.match(validation().onlyNumberVal)) {
      setErrorMsg('Please enter valid input')
    } else {
      if(data.otp === '123456'){
        alert('Valid OTP')
      }else{
        setErrorMsg('Invalid OTP! Please try again')
      }
    }
  }

  return (
    <>
      <div className='login-main-container'>
        <div className='p-5'>
          <img src={logo} alt='...' />
        </div>
        <div className='container'>
          <div className='col-lg-6 col-12 offset-lg-3 offset-0 mt-5 border border-light border-4 rounded-4 p-5 sub-container'>
            <div className='fs-3 fw-bold'>OTP Verification</div>
            <div className='mt-3'>To receive an OTP, please enter the Email address associated with your account.</div>
            <div className='mt-3'>
              <div className='text-center'>
                {errorMsg !== '' ?
                  <small className='text-danger'>{errorMsg}</small>
                  : ''}
              </div>
              <div className='mb-3'>
                <div className="form-group">
                  <label htmlFor="">OTP</label>
                  <input type="text"
                    className="form-control" name="otp" value={data.otp} onChange={onChangeHandle} placeholder="OTP" />
                </div>
              </div>
              <div className='mb-3'>
                <button className='btn border border-dark px-4 py-2' onClick={onClickOnSubmitOtp} >Submit OTP <FontAwesomeIcon icon={faArrowRight} className='ms-2' /></button>
              </div>
              {timer === 0 ?
                <div>
                  <div className='mb-3'>
                    <span className=' pointer fw-bold' style={{ color: '#297ADA', borderBottom: '1px solid #297ADA' }} onClick={resendOtp}>Resend ?</span>
                  </div>
                </div>
                :
                <div>00:{timer < 10 ? 0 : ""}{timer}</div>}

            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default OTPPage
